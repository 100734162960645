import React from "react"

import { isNil } from "./any"
import { ReactComponent } from "~/src/customElements/reactComponent"

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __reactComponents: Record<string, AnyComponent<any>>
  }
}

export type AnyComponent<P extends object> = React.ComponentType<P> | React.LazyExoticComponent<React.ComponentType<P>>

/**
 * Register a React component to be usable with `react_component` helper from React-on-Rails
 * @param components
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function registerComponents(components_: Record<string, AnyComponent<any> | Promise<unknown>>) {
  window.__reactComponents ||= {}

  const components = Object.entries(components_).reduce((acc, [name, component]) => {
    if (component instanceof Promise) {
      Object.assign(acc, lazyLoad(name, component))
    } else {
      acc[name] = component
    }

    return acc
  }, {})

  Object.assign(window.__reactComponents, components)

  customElements.get("react-component") || customElements.define("react-component", ReactComponent)
}

export function getComponent<P extends object>(name: string): AnyComponent<P> {
  const component = window.__reactComponents[name]

  if (isNil(component)) throw new Error(`${name} component must be registered`)

  return component
}

//region Private
/**
 * Prepares a dynamically imported component for registration.
 *
 * @param name The name of the component.
 * @param imports A dynamic import of the component file.
 * @returns An object with the lazily loaded component.
 * @throws {TypeError} If the component is not a valid React component.
 */
function lazyLoad(name: string, imports: Promise<unknown>) {
  return {
    [name]: React.lazy(() =>
      imports.then((module) => {
        if (!isRecord(module)) {
          throw new TypeError(`Expected '${name}' to be a dynamic import`)
        }

        const component = module?.default ?? module[name]

        if (isComponent(component)) {
          return { default: component }
        } else {
          throw new TypeError(`Could not find '${name}' a dynamically imported React component`)
        }
      })
    ),
  }
}

function isComponent(value: unknown): value is React.ComponentType {
  return typeof value === "function" || value instanceof React.Component || value instanceof React.PureComponent
}

function isRecord(value: unknown): value is Record<string, unknown> {
  return typeof value === "object" && value !== null && !Array.isArray(value)
}
//endregion
