import React from "react"

import { Link, LinkProps } from "react-router-dom"
import { twMerge } from "tailwind-merge"

import { primaryStylePresetClass, secondaryStylePresetClass } from "./Tab"
import { dataFlag } from "~/src/lib/jsx"

export type TabLinkProps = {
  kind?: "primary" | "secondary"
  selected?: boolean | (() => boolean)
  className?: string
  to?: LinkProps["to"]
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

export const TabLink = React.forwardRef<HTMLAnchorElement, TabLinkProps>(function TabLink(props, ref) {
  const { className, selected = false, to = "", kind = "primary", ...restProps } = props
  const stylePresetClass = kind != "secondary" ? primaryStylePresetClass : secondaryStylePresetClass

  return (
    <Link
      className={twMerge(stylePresetClass, className)}
      to={to}
      ref={ref}
      {...dataFlag(typeof selected === "boolean" ? selected : selected(), "selected")}
      {...restProps}
    />
  )
})
