import React, { forwardRef, useMemo } from "react"

import cuid from "cuid"
import { twMerge } from "tailwind-merge"

import style from "./InputRadio.module.scss"

export type InputRadioProps = { label?: string } & Omit<JSX.IntrinsicElements["input"], "type">

export const InputRadio = forwardRef<HTMLInputElement, InputRadioProps>(function InputRadio(props, ref) {
  const { className, id: rawId, name = "", label = "Undefined", ...restProps } = props
  const id = useMemo(() => [name, rawId ?? cuid()].join("_"), [name, rawId])

  return (
    <div className={style.base}>
      <input className={twMerge([className])} id={id} name={name} ref={ref} type="radio" {...restProps} />
      <label htmlFor={id}>
        <span className={style.checkBox} /> {label}
      </label>
    </div>
  )
})
