/*
 * Builds a Rails params-compatible input field name
 *
 * @param fieldPrefix {string} Start of input field name; will not be wrapped in square brackets
 * @param path {Array<string | number>} Segments of the field name to be wrapped in square brackets
 * @returns {string} Rails params-compatible input field name
 *
 * @example
 *   getFieldName('some_field') // => 'some_field'
 *   getFieldName('some_model', 'nested_attributes', 'name') // => 'some_model[nested_attributes][name]'
 *   getFieldName('some_model', 'nested_attributes', 'names', '') // => 'some_model[nested_attributes][names][]'
 *
 */
export const getFieldName = (fieldPrefix: string, ...path: Array<string | number>) =>
  fieldPrefix + path.map((s) => `[${s}]`).join("")

/*
 * Returns an object containing all the form values with the provided fields as the keys.
 * Takes an optional "prefix" argument for the field prefix.
 *
 * @param fields {Array<string>} Array of field names to be fetched from the form
 * @param form {HTMLFormElement} The form element from which to fetch values
 * @param prefix {string} [prefix] Optional prefix for field names
 * @returns {Object} Object containing form values with provided fields as keys
 *
 * @example
 *   // Assume a form with inputs named 'field1' and 'field2'
 *   fetchFormValues({ fields: ['field1', 'field2'], form: someForm })
 *   // => { field1: 'value1', field2: 'value2' }
 *
 *   // Assume a form with inputs named 'prefix[field1]' and 'prefix[field2]'
 *   fetchFormValues({ fields: ['field1', 'field2'], form: someForm, prefix: 'prefix' })
 *   // => { field1: 'value1', field2: 'value2' }
 */
export function fetchFormValues({
  fields,
  form,
  prefix,
}: {
  fields: string[]
  form: HTMLFormElement
  prefix?: string
}) {
  const formData = Object.fromEntries(new FormData(form))

  return fields.reduce(
    (res, field_name) => ({
      ...res,
      [field_name]: formData[prefix ? `${prefix}[${field_name}]` : field_name],
    }),
    {}
  )
}

/*
 * Sets the values of form fields based on the provided fieldValues object.
 * If the field does not already exist on the form, it will be created with the specified value.
 * Takes an optional "prefix" argument for the field prefix.
 *
 * @param fieldValues {Object} Object containing field names and their corresponding values to be set in the form
 * @param form {HTMLFormElement} The form element in which to set the values
 * @param prefix {string} [prefix] Optional prefix for field names
 * @returns {void}
 *
 * @example
 *   // Assume a form with inputs named 'field1' and 'field2'
 *   setFormValues({ fieldValues: { field1: 'value1', field2: 'value2' }, form: someForm })
 *   // => The input fields 'field1' and 'field2' in the form will be set to 'value1' and 'value2' respectively
 *
 *   // Assume a form with inputs named 'prefix[field1]' and 'prefix[field2]'
 *   setFormValues({ fieldValues: { field1: 'value1', field2: 'value2' }, form: someForm, prefix: 'prefix' })
 *   // => The input fields 'prefix[field1]' and 'prefix[field2]' in the form will be set to 'value1' and 'value2' respectively
 */
export function setFormValues({
  fieldValues,
  form,
  prefix,
}: {
  fieldValues: { [key: string]: string }
  form: HTMLFormElement
  prefix?: string
}) {
  const fields = Object.keys(fieldValues)

  fields.forEach((field) => {
    let inputName = prefix ? `${prefix}[${field}]` : field
    let inputField = form.querySelector(`[name='${inputName}']`) as HTMLInputElement

    if (inputField) {
      inputField.value = fieldValues[field]
    } else {
      let newInput = document.createElement("input")
      newInput.type = "hidden"
      newInput.name = inputName
      newInput.value = fieldValues[field]
      form.appendChild(newInput)
    }
  })
}
export function setReactHookFormValues({ form, fieldValues, prefix, setValue, getFieldState }) {
  const fields = Object.keys(fieldValues)

  fields.forEach((field) => {
    let inputName = prefix ? `${prefix}[${field}]` : field

    const inputField = form.querySelector(`[name='${inputName}']`) as HTMLInputElement
    const hasFieldOutsideHookForm = inputField !== null
    const hasFieldUnderHookFormManagement = getFieldState(inputName)

    if (hasFieldUnderHookFormManagement) {
      setValue(inputName, fieldValues[field])
    } else if (hasFieldOutsideHookForm) {
      inputField.value = fieldValues[field]
    } else {
      let newInput = document.createElement("input")
      newInput.type = "hidden"
      newInput.name = inputName
      newInput.value = fieldValues[field]
      form.appendChild(newInput)
    }
  })
}
