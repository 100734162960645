import { createContext } from "react"

import { FulfillmentContextType } from "./types"

export const FulfillmentContext = createContext<FulfillmentContextType>({
  errors: {
    fulfillmentMethod: "",
    placeholderFulfillmentRequestDraft: {
      estimatedNumberOfRecipients: "",
      targetShipDate: "",
    },
  },
  autoAllocate: false,
  setAutoAllocate: () => {},
  estimatedRecipientCount: null,
  fulfillmentMethod: "",
  setFulfillmentMethod: () => {},
  isLegacy: false,
  targetShipDate: "",
})
