import React from "react"

import { FolderPlusIcon, HeartIcon, NoSymbolIcon } from "@heroicons/react/24/outline"
import { format } from "date-fns"
import { Link, useSearchParams } from "react-router-dom"
import { twMerge } from "tailwind-merge"

import { ImageSet } from "~/src/components/ImageSet"
import { Format } from "~/src/lib/format"
import { Photo } from "~/src/types/photo"

export type ProductTileProps = {
  id: number
  slug: string
  name: string
  loggedIn: boolean
  lowPrice: number
  loved?: boolean
  hidden?: boolean
  photo: Photo
  productPath: string
  lastOrderedAt?: string
  className?: string
  onLove?: (id: number, slug: string) => void
  onHide?: (id: number, slug: string) => void
  onAddToProjectRequest?: (id: number, slug: string) => void
}

export function ProductTile(props: ProductTileProps) {
  const {
    id,
    slug,
    name,
    lowPrice,
    className,
    loved = false,
    hidden = false,
    photo,
    productPath,
    lastOrderedAt,
    loggedIn,
    onLove,
    onHide,
    onAddToProjectRequest,
  } = props

  const [searchParams] = useSearchParams()

  return (
    <div
      className={twMerge(
        "relative flex h-full w-full flex-col gap-2 overflow-hidden rounded-lg border bg-white",
        className
      )}
    >
      {loggedIn && (
        <div className="absolute right-0 top-0 z-10 flex flex-col gap-1 p-2">
          <IconKnob
            className={twMerge("ring-red-600", loved ? "bg-pink-200 text-red-600" : "bg-white text-gray-500")}
            onClick={() => onLove?.(id, slug)}
          >
            <HeartIcon className={twMerge("h-4 w-4", loved && "fill-current")} />
          </IconKnob>

          <IconKnob
            className={twMerge("ring-gray-500", hidden ? "bg-gray-500 text-white" : "bg-white text-gray-500")}
            onClick={() => onHide?.(id, slug)}
          >
            <NoSymbolIcon className="h-4 w-4" />
          </IconKnob>
        </div>
      )}

      <a href={`/${productPath}`} className="m-0 p-0">
        <ImageSet className="aspect-square w-full" srcs={[photo.largeUrl, photo.xlargeUrl]} />
      </a>

      <dl className="flex grow flex-col gap-2 p-1 px-4 sm:pt-3">
        <dt className="font-medium leading-snug text-gray-900">{name}</dt>
        <dd className="text-sm text-gray-500">From {Format.currency(lowPrice)}</dd>
      </dl>

      <div className="justify-bottom">
        {loggedIn && lastOrderedAt && (
          <div className="bg-slate-100 p-2 text-center text-xs">
            Last ordered: {format(new Date(lastOrderedAt), "yyyy-MM-dd")}
          </div>
        )}

        <Link
          preventScrollReset
          to={{ pathname: productPath, search: searchParams?.toString() }}
          className={twMerge("flex justify-center border-t p-2 text-blue-600")}
          onClick={() => onAddToProjectRequest?.(id, slug)}
        >
          <span className="flex items-center gap-2 text-sm max-sm:hidden">
            <FolderPlusIcon className="h-4 w-4" />
            Add to project request
          </span>
          <span className="flex items-center gap-2 text-sm sm:hidden">
            <FolderPlusIcon className="h-4 w-4" />
            Add to project
          </span>
        </Link>
      </div>
    </div>
  )
}

type IconKnobProps = {
  className?: string
  onClick?: () => void
} & JSX.IntrinsicElements["button"]

function IconKnob(props: IconKnobProps) {
  const { className, ...restProps } = props
  return <button className={twMerge("cursor-pointer rounded-full p-1 hover:ring-1", className)} {...restProps} />
}
