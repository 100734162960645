import React, { forwardRef, ReactNode } from "react"

import { twMerge } from "tailwind-merge"

export type WorkflowContainerProps = {
  className?: string
  children: ReactNode
}

export const WorkflowContainer = forwardRef(function (props: WorkflowContainerProps, ref: React.ForwardedRef<any>) {
  const { className, children } = props

  return (
    <div ref={ref} className={twMerge("rounded-lg bg-white px-8 py-12 sm:mx-6 sm:mt-8 lg:px-20", className)}>
      {children}
    </div>
  )
})
