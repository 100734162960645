import type { FieldSetters } from "~/src/lib/typeUtilities"

export enum FulfillmentMethod {
  AutoFulfill = "auto_fulfill",
  FulfillLater = "fulfill_later",
  LegacyFulfillLater = "legacy_fulfill_later",
}

type ErrorMessage = string | undefined

export type PreferenceCollectorErrorContext = {
  fulfillmentMethod: ErrorMessage
  placeholderFulfillmentRequestDraft: {
    estimatedNumberOfRecipients: ErrorMessage
    targetShipDate: ErrorMessage
  }
}

export type FulfillmentContextFields = {
  autoAllocate: boolean
  errors: PreferenceCollectorErrorContext
  estimatedRecipientCount: number | null
  fulfillmentMethod: string
  isLegacy: boolean
  targetShipDate: string
}

export type FulfillmentContextType = FulfillmentContextFields &
  FieldSetters<Pick<FulfillmentContextFields, "fulfillmentMethod" | "autoAllocate">>
