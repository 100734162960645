import React from "react"

import { twMerge } from "tailwind-merge"

import { dataFlag } from "~/src/lib/jsx"

export type TabProps = {
  kind?: "primary" | "secondary"
  selected?: boolean | (() => boolean)
  className?: string
  value?: string
  type?: "button"
  onClick?: React.MouseEventHandler<HTMLButtonElement>
} & Omit<React.AnchorHTMLAttributes<HTMLButtonElement>, "type">

export const primaryStylePresetClass =
  "flex py-2 text-gray-500 text-nowrap transition-colors data-[selected]:text-gray-900 data-[selected]:border-b data-[selected]:border-gray-900"
export const secondaryStylePresetClass =
  "flex py-2 text-gray-900 text-nowrap transition-colors px-4 py-1 align-center data-[selected]:bg-blueslate-200 data-[selected]:rounded-full"

export const Tab = React.forwardRef<HTMLButtonElement, TabProps>(function TabLink(props, ref) {
  const { className, selected = false, kind = "primary", ...restProps } = props
  const stylePresetClass = kind != "secondary" ? primaryStylePresetClass : secondaryStylePresetClass

  return (
    <button
      type="button"
      className={twMerge(stylePresetClass, className)}
      ref={ref}
      {...dataFlag(typeof selected === "boolean" ? selected : selected(), "selected")}
      {...restProps}
    />
  )
})

Tab.displayName = "Tab"
