/**
 * Helper function to conditionally add a `data-` attribute without a value to an element.
 * @param cond
 * @param flagName suffix added to `data-` to create the attribute name
 * @returns An object with a single key-value (e.g. `data-flag-name`) pair if `cond` is true, otherwise an empty object
 * @example
 * <div {...dataFlag(true, "flag-name")} /> // <div data-flag-name></div>
 * @example
 * dataFlag(true, "flag-name") // { "data-flag-name": "" }
 * dataFlag(false, "flag-name") // {}
 */
export function dataFlag(cond: boolean | undefined | null, flagName: string): { [key: string]: string } {
  return cond ? { [`data-${flagName}`]: "" } : {}
}
