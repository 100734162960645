import React, { useMemo } from "react"

import { get } from "lodash-es"
import { useFormContext } from "react-hook-form"
import { twMerge } from "tailwind-merge"

import { Bee } from "~/src/components/BeeKit"
import { Country } from "~/src/lib/country"
import { iname, PathBuilder } from "~/src/lib/iname"

export type HookedAddressFieldsProps = {
  baseName: string | PathBuilder
  className?: string
  disabled?: boolean
  includeAddressValidation?: boolean
  includeCompanyField?: boolean
  includeNameField?: boolean
  internationalAllowed?: boolean
  supportedRegionCodes?: string[]
}

export function HookedAddressFields(props: HookedAddressFieldsProps) {
  const {
    className,
    includeNameField = true,
    includeCompanyField = true,
    internationalAllowed = false,
    baseName,
    disabled = false,
    supportedRegionCodes = [],
    includeAddressValidation = false,
  } = props

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  const names = typeof baseName === "string" ? iname(baseName) : baseName
  const watchCountry = watch(names.addressCountry._)

  const country = useMemo(() => {
    return internationalAllowed && supportedRegionCodes.length > 0 && !supportedRegionCodes.includes(watchCountry)
      ? Country.fromAlpha2(supportedRegionCodes[0])
      : Country.fromAlpha2(watchCountry)
  }, [watchCountry])

  const countryOptions = useMemo(() => {
    return Country.allPriorityFirst()
      .filter((c) => supportedRegionCodes.length == 0 || supportedRegionCodes.includes(c.alpha2))
      .map((c) => ({
        value: c.alpha2,
        label: c.name,
        disabled: Country.federalTaxIdCountries().includes(c.alpha2) || Country.disabledCountries().includes(c.alpha2),
      }))
  }, [supportedRegionCodes])

  return (
    <div className={twMerge("flex flex-col", className)}>
      <input type="hidden" name={names.includeAddressValidations._} defaultValue={includeAddressValidation ? 1 : 0} />

      {includeNameField ? (
        <Bee.Field
          label="Address name"
          errorMessages={get(errors, names.addressName.$dots())?.message as string}
          className="w-full"
        >
          <Bee.Input
            type="text"
            {...register(names.addressName._, {
              shouldUnregister: true,
              required: {
                value: true,
                message: "required",
              },
            })}
          />
        </Bee.Field>
      ) : null}

      {includeCompanyField ? (
        <Bee.Field
          label="Address Company"
          optional
          errorMessages={get(errors, names.addressCompany.$dots())?.message as string}
          className="w-full"
        >
          <Bee.Input type="text" {...register(names.addressCompany._, { shouldUnregister: true })} />
        </Bee.Field>
      ) : null}

      {/* Country */}
      <Bee.Field
        label="Country"
        errorMessages={get(errors, names.addressCountry.$dots())?.message as string}
        className="w-full"
      >
        <select
          className="w-full rounded border border-gray-300"
          disabled={disabled || !internationalAllowed}
          {...register(names.addressCountry._, {
            required: { value: !disabled, message: "required" },
          })}
        >
          {countryOptions.map((option) => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
        </select>
      </Bee.Field>

      {/* Street Address */}
      <Bee.Field
        label="Street address"
        errorMessages={get(errors, names.address1.$dots())?.message as string}
        className="w-full"
      >
        <Bee.Input
          type="text"
          {...register(names.address1._, {
            shouldUnregister: true,
            required: { value: !disabled, message: "required" },
          })}
        />
      </Bee.Field>

      {/* Address line 2 */}
      <Bee.Field
        label="Address line 2"
        optional
        errorMessages={get(errors, names.address2.$dots())?.message as string}
        className="w-full"
      >
        <Bee.Input type="text" {...register(names.address2._, { shouldUnregister: true })} />
      </Bee.Field>

      {/* City */}
      <div className="flex w-full flex-col gap-4 md:grid md:grid-cols-[2fr_1fr_1fr]">
        <div>
          <Bee.Field
            label="City"
            errorMessages={get(errors, names.addressCity.$dots())?.message as string}
            className="w-full"
          >
            <Bee.Input
              type="text"
              {...register(names.addressCity._, {
                required: { value: !disabled, message: "required" },
              })}
            />
          </Bee.Field>
        </div>

        {/* State */}
        {country instanceof Country ? (
          <div>
            <Bee.Field
              label="State"
              optional={!country.isStateRequired()}
              errorMessages={get(errors, names.address.$dots())?.message as string}
              className="w-full"
            >
              {country.states != null ? (
                <select
                  className="w-full rounded border border-gray-300"
                  disabled={disabled}
                  {...register(names.addressState._, {
                    required: { value: !disabled && country.isStateRequired(), message: "required" },
                  })}
                >
                  {country.states.map((s) => (
                    <option key={s.alpha2} value={s.alpha2}>
                      {s.name}
                    </option>
                  ))}
                </select>
              ) : (
                <Bee.Input
                  type="text"
                  {...register(names.addressState._, {
                    required: { value: !disabled && country.isStateRequired(), message: "required" },
                  })}
                />
              )}
            </Bee.Field>
          </div>
        ) : (
          <></>
        )}

        {/* Zip */}
        <div>
          <Bee.Field
            label="Zip Code"
            errorMessages={get(errors, names.addressZip.$dots())?.message as string}
            className="w-full"
          >
            <Bee.Input
              type="text"
              {...register(names.addressZip._, {
                required: { value: !disabled, message: "required" },
              })}
            />
          </Bee.Field>
        </div>

        {/* Phone Number */}
        {country instanceof Country && country.isInternational() ? (
          <div>
            <Bee.Field
              label="Phone Number"
              errorMessages={get(errors, names.addressPhone.$dots())?.message as string}
              className="w-full"
            >
              <Bee.Input
                type="text"
                {...register(names.addressPhone._, {
                  required: { value: !disabled, message: "required" },
                })}
              />
            </Bee.Field>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
