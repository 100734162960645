import { isBlank, isntNil, isPresent } from "~/src/lib/any"
import { Country } from "~/src/lib/country"
import yup from "~/src/lib/yup-extended"

const { object, string } = yup

const DEFAULT_COUNTRY_CODE = "US"
const FROM_ALPHA2_NICE_ERROR = "must use the ISO two-letter country code."

const defaultStaticRowSchema = object().shape({
  address_name: string().latin().required().max(35),
  address_company: string().latin().max(30).nullable(),
  address_1: string().latin().required().max(35),
  address_2: string().latin().max(35).nullable(),
  address_city: string().latin().required().max(30),
  address_state: string().latin().nullable().max(40),
  address_zip: string().latin().required(),
  address_country: string().latin().required(),
  address_phone: string().latin().nullable(),
  recipient_email: string().latin().email().nullable(),
  notification_email: string().latin().email().nullable(),
  slack: string().latin().nullable(),
})

const defaultRowSchema = (Country) => {
  return defaultStaticRowSchema.concat(
    object().shape({
      address_1: string().when("address_country", (country, schema) => {
        return country === "US" || isBlank(country)
          ? schema
          : schema.test({
              name: "invalidName",
              test(address1: string) {
                if (/P\.?O\.?[\s]*BOX/i.test(address1)) {
                  return this.createError({ message: "address_1 can not be a P.O. Box when country isn't US" })
                }

                return true
              },
            })
      }),
      address_state: string()
        .when("address_country", (alpha2, schema) => {
          const maybeCountry = Country.fromAlpha2(isPresent(alpha2) ? alpha2 : DEFAULT_COUNTRY_CODE)
          if (maybeCountry instanceof Error) return schema

          return maybeCountry.isStateRequired() ? schema.required() : schema
        })
        .when("address_country", (alpha2, schema) => {
          const maybeCountry = Country.fromAlpha2(isPresent(alpha2) ? alpha2 : DEFAULT_COUNTRY_CODE)
          if (maybeCountry instanceof Error) return schema

          const stateCodes = maybeCountry.stateCodes() ?? new Set()
          if (stateCodes.size > 0) {
            return schema.test("oneOf", "${path} is not a valid 2-letter state code", (x: string) => stateCodes.has(x))
          }

          return schema
        }),
      address_country: string().test({
        name: "oneOf",
        test(alpha2) {
          const maybeCountry = Country.fromAlpha2(isntNil(alpha2) ? alpha2 : DEFAULT_COUNTRY_CODE)
          if (maybeCountry instanceof Error) return this.createError({ message: "${path} " + FROM_ALPHA2_NICE_ERROR })
          return true
        },
      }),
      address_zip: string().when("address_country", (alpha2, schema) => {
        const maybeCountry = Country.fromAlpha2(isPresent(alpha2) ? alpha2 : DEFAULT_COUNTRY_CODE)
        if (maybeCountry instanceof Error) return schema

        if (maybeCountry.isZipRequired() && isntNil(maybeCountry.zipFormat)) {
          return schema
            .required()
            .matches(
              maybeCountry.zipPattern(),
              `zip formatting must be ${maybeCountry.zipFormat.replace(/\s*,\s*/g, " or ")} in ${maybeCountry.name}`
            )
        }

        return schema
      }),
      address_phone: string().when("address_country", (country, schema) => {
        return (
          country === "US" || isBlank(country) ? schema : schema.required(`phone is required when country isn't US`)
        ).test({
          name: "format",
          test(phone: string) {
            if (isBlank(phone)) return true

            if (/^\d{5,}$/.test(phone.replace(/[+-\s().x]+/gi, ""))) return true

            return this.createError({
              message: "phone must have at least 5 numbers and no special characters except for: ()+-.x",
            })
          },
        })
      }),
    })
  )
}

export const rowSchema = (columns: HeaderValue["columns"], formats: HeaderValue["formats"]) => {
  return columns.reduce((schema, { key: columnKey }) => {
    const format = formats[columnKey]
    if (format?.format == "select") {
      const legalOptionValues = format.options.map((option) => option.value)
      return schema.shape({ [columnKey]: string().required().oneOf(legalOptionValues) })
    } else if (format?.format) {
      return schema.shape({ [columnKey]: string().latin().required() })
    } else {
      return schema
    }
  }, defaultRowSchema(Country))
}

export const DATABASE = "dropshipList"
type Stores = {
  meta: string[]
  headers: string[]
  rows: string[]
}
export type TableKey = keyof Stores

export interface MetaValue {
  sourceChecksum: string
  namespace: string
}

export interface HeaderValue {
  columns: { key: string; name: string }[]
  formats: CustomFieldFormatObject
  namespace: string
}

export interface RowValue {
  [key: string]: unknown
  id: number
  namespace: string
}

export const STORES: Stores = {
  meta: ["&namespace"],
  headers: ["&namespace"],
  rows: ["&[namespace+id]", "namespace", "id"],
}

export type CustomFieldFormatObject = { [key: string]: CustomFieldTextFormat | CustomFieldSelectFormat }
export type CustomFieldTextFormat = { format: "text" }
export type CustomFieldSelectFormat = { format: "select"; options: { label: string; value: string }[] }

// TODO: Use a yup schema to enforce this format on load
export type SourceFormat = {
  sourceChecksum: string
  columns: { name: string }[]
  rows: { [key: string]: string }[]
  formats: CustomFieldFormatObject
}
