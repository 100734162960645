import React from "react"

import { CollectionItemTile } from "./CollectionItemTile"
import { useSearchQuery, useSelectedItems } from "./FormPage"
import { STORE_PRODUCT_GROUP_DEFAULT_LABEL } from "~/constants"
import { Orientation } from "~/src/types/orientation"
import type { ProductSelectionItem, Selectable, StoreProduct, StoreProductGroup } from "~/src/types/productSelection"

export type ProductGroupProps = {
  storeProductGroup: StoreProductGroup
  itemSelected: (item: ProductSelectionItem) => boolean
  renderSelectionControls: (item: Selectable) => JSX.Element
  orientation?: Orientation
}

export function StoreProductGroupTileList(props: ProductGroupProps) {
  const { storeProductGroup, renderSelectionControls, itemSelected, orientation = Orientation.Portrait } = props
  const { selectables } = storeProductGroup
  const selectedItems = useSelectedItems<ProductSelectionItem>()
  const search = useSearchQuery()

  const productFilter = (storeProduct: StoreProduct) => {
    const { name } = storeProduct

    if (!search.length) {
      return true
    }

    return name?.toLowerCase()?.includes(search?.toLowerCase() ?? "")
  }

  const filteredStoreProducts = selectables.filter(productFilter)

  if (filteredStoreProducts.length == 0) {
    return ""
  }

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-medium text-gray-900">
        {storeProductGroup.name === STORE_PRODUCT_GROUP_DEFAULT_LABEL ? "" : storeProductGroup.name}
      </h2>
      <div className="flex flex-wrap gap-4 md:min-w-[664px]">
        {filteredStoreProducts.map((selectable) => {
          const { id } = selectable

          return (
            <CollectionItemTile
              key={id}
              selectable={selectable}
              selected={itemSelected(selectedItems[id])}
              orientation={orientation}
            >
              {renderSelectionControls(selectable)}
            </CollectionItemTile>
          )
        })}
      </div>
    </div>
  )
}
