import React, { ReactChild } from "react"

import { CellTip } from "./CellTip/CellTip"
import { CornerTriangle } from "./CornerTriangle/CornerTriangle"
import { SpreadsheetColumn, SpreadsheetRow } from "./types"

import "./Spreadsheet.scss"

export interface ValuePresenterProps<R extends SpreadsheetRow> {
  column: SpreadsheetColumn<R>
  row: R
  isCellSelected: boolean
}

export function ValuePresenter<R extends SpreadsheetRow>(props: ValuePresenterProps<R>) {
  const { column, row, isCellSelected } = props

  //TODO: somehow calculate the size of the spreadsheet
  //then determine whether the cell is in the container or not?
  const inContainer = true
  const errorMessage = row.errors?.[column.key]

  const innerRender: ReactChild = (
    <>
      {errorMessage && !isCellSelected ? <CornerTriangle color="var(--bad-color)" size={10} /> : null}
      {row[column.key]}
    </>
  )

  return (
    <div className="rdg-cell-content">
      {isCellSelected && errorMessage && inContainer ? (
        <CellTip content={errorMessage}>{innerRender}</CellTip>
      ) : (
        innerRender
      )}
    </div>
  )
}
