import React, { useEffect, useRef, useState } from "react"

import { ReadyState } from "./ready"
import { HeaderValue, RowValue, SourceFormat, TableKey } from "./schema"
import { useTable } from "~/src/hooks"
import { isNil } from "~/src/lib/any"

type SourceProps = {
  sourceChecksum: string
  fetchUrl: string

  children: React.ReactNode
}

export function Source(props: SourceProps) {
  const { sourceChecksum, fetchUrl, children } = props

  const metaKey: TableKey = "meta"
  const rowKey: TableKey = "rows"
  const headerKey: TableKey = "headers"

  const [readyState, setReadyState] = useState<ReadyState>("initial")
  const [error, setError] = useState<string | null>(null)
  const { namespace, collection: metaCollection, table: metaTable } = useTable(metaKey)
  const { collection: rowsCollection, table: rowsTable } = useTable(rowKey)
  const { table: headersTable } = useTable(headerKey)
  const isMountedRef = useRef(true)

  useEffect(() => {
    const prepareSource = async () => {
      const meta = await metaCollection.first()
      if (isNil(sourceChecksum) || isNil(meta) || meta.sourceChecksum !== sourceChecksum) {
        const response = await fetch(fetchUrl)

        if (!response.ok) {
          try {
            const errorResponse = await response.json()
            if (errorResponse && errorResponse.error) {
              setError(errorResponse.error)
            } else {
              setError("An unexpected error occurred. Please try again.")
            }
          } catch (e) {
            setError(`Failed to fetch URL with error response: ${e}`)
          }
        }

        const fetchedSource: SourceFormat = await response.json()
        const { sourceChecksum: newSourceChecksum, columns, rows, formats } = fetchedSource
        const initialRows: RowValue[] = rows.map((row, id) => {
          return { namespace, id, ...row }
        })
        const initialColumns: HeaderValue["columns"] = columns.map((column) => {
          return { key: column.name, ...column }
        })

        return Promise.all([
          metaTable.put({ namespace, sourceChecksum: newSourceChecksum }),
          headersTable.put({ namespace, columns: initialColumns, formats }),
          rowsCollection.delete().then(() => rowsTable.bulkAdd(initialRows)),
        ])
      }
    }

    setReadyState("initial")
    prepareSource()
      .then(() => {
        if (isMountedRef.current) {
          setReadyState("success")
        }
      })
      .catch((e) => {
        if (isMountedRef.current) {
          setReadyState("failure")
        }
        throw e
      })

    return () => {
      isMountedRef.current = false
    }
  }, [sourceChecksum, fetchUrl])

  switch (readyState) {
    case "initial":
      return <div>Loading your spreadsheet. Please be patient.</div>
    case "failure":
      return (
        <div>
          {error
            ? `Failed to load the spreadsheet due to the following error: "${error}" Please check your CSV file for any issues.`
            : "Failed to load the spreadsheet. Please try again."}
        </div>
      )
    case "success":
      return <>{children}</>
  }
}
