import type { ToString } from "./any"

/**
 * Merges the given parameters with the search parameters in the current URL and returns the updated URL search string.
 *
 * @param params - The parameters to merge with the search parameters.
 * @param searchParams - The search parameters to merge with. Defaults to the search parameters in the current URL.
 * @returns The updated URL search string.
 * @example
 * // Given the current URL: `https://example.com/?page=2`
 * mergeURLSearch({ page: 3 }) // Returns: `?page=3`
 * mergeURLSearch({ q: "what is love" }) // Returns: `?page=2&q=what%20is%20love`
 */
export function mergeURLSearch(
  params: Record<string, ToString>,
  searchParams: URLSearchParams = new URLSearchParams(window?.location?.search ?? {})
) {
  const newParams: Record<string, string> = {}

  for (const [key, value] of Object.entries(params)) {
    newParams[key] = value.toString()
  }

  return `?${new URLSearchParams({ ...Object.fromEntries(searchParams.entries()), ...newParams })}`
}

/**
 * Matches a URL against a predicate function.
 *
 * @param predFn - The predicate function to match the URL against.
 * @param url - The URL to be matched. If not provided, the current window location will be used.
 * @returns `true` if the URL matches the predicate function, `false` otherwise.
 */
export function matchURL(predFn: (url: URL) => boolean, url: URL = new URL(window?.location?.href ?? "")) {
  return predFn(url) === true
}

export function getCurrentURL() {
  return new URL(window?.location?.href ?? "")
}
