import React, { useMemo } from "react"

import DataGrid from "react-data-grid"

import { ActionToolbar } from "./ActionToolbar"
import { BaseSpreadsheetProps, SpreadsheetColumn, SpreadsheetRow } from "./types"
import { ValuePresenter } from "./ValuePresenter"
import { isNil } from "~/src/lib/any"

export type ReadOnlySpreadsheetProps<R extends SpreadsheetRow> = BaseSpreadsheetProps<R>
export function ReadOnlySpreadsheet<R extends SpreadsheetRow>(props: ReadOnlySpreadsheetProps<R>) {
  const { rows, columns: rawColumns, onDownload, ...restProps } = props
  const rowKeyGetter = props.rowKeyGetter ?? ((r) => r.id)

  const columns = useMemo(() => {
    return rawColumns.map(({ key, name, ...restColumn }) => {
      const column: SpreadsheetColumn<R> = {
        name,
        key,
        editable: false,
        cellClass(row) {
          if (row?.errors?.[key]) return "rdg-cell-has-error"
        },
        formatter: ValuePresenter,
        ...restColumn,
      }

      return column
    })
  }, [rawColumns])

  const actions = useMemo(
    () =>
      isNil(onDownload)
        ? []
        : [
            {
              icon: "Download",
              tooltip: "Download working spreadsheet",
              onUse: onDownload,
            },
          ],
    [onDownload]
  )

  return (
    <div className="spreadsheet">
      {rows.length > 0 ? (
        <>
          <ActionToolbar actions={actions} />
          <DataGrid
            columns={columns}
            defaultColumnOptions={{
              resizable: true,
            }}
            rows={rows}
            rowKeyGetter={rowKeyGetter}
            className="rdg-light"
            {...restProps}
          />
        </>
      ) : null}
    </div>
  )
}
