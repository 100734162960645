import * as React from "react"

import { twMerge } from "tailwind-merge"

type LayoutComponentProps = {
  children: React.ReactNode
  className?: string
}

function SectionFn({ children, className }: LayoutComponentProps) {
  return <section className={twMerge("w-full py-6", className)}>{children}</section>
}

function Title({ children, className }: LayoutComponentProps) {
  return (
    <h3 className={twMerge("pb-4 text-center text-base font-bold uppercase text-gray-400", className)}>{children}</h3>
  )
}

function Content({ children, className }: LayoutComponentProps) {
  return (
    <div
      className={twMerge(
        "inline-flex w-full flex-col items-center justify-start gap-4 rounded-lg bg-gray-50 p-8",
        className
      )}
    >
      {children}
    </div>
  )
}

export const Section = Object.assign(SectionFn, { Title, Content })
