import React, { forwardRef } from "react"

import { twMerge } from "tailwind-merge"

import { buttonClassName } from "./sharedClassNames"

export type ButtonLinkProps = { kind?: "primary" | "secondary" } & JSX.IntrinsicElements["a"]

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(function ButtonLink(props, ref) {
  const { className, kind = "primary", ...restProps } = props

  return <a className={twMerge(buttonClassName(kind), className)} ref={ref} {...restProps} />
})
