import * as React from "react"

import { twMerge } from "tailwind-merge"

import { Bee } from "~/src/components/BeeKit"

export type TabBarProps = {
  kind?: "primary" | "secondary"
  className?: string
  containerClassName?: string
  children: React.ReactNode
}

const primaryStylePresetClass = "gap-10"
const primaryStylePresetContainerClass = "gap-4 border-b border-b-gray-200"
const secondaryStylePresetClass = "gap-1"
const secondaryStylePresetContainerClass = "gap-4"

export function TabBar(props: TabBarProps) {
  const { className, containerClassName, children, kind = "primary" } = props
  const stylePresetClass = kind != "secondary" ? primaryStylePresetClass : secondaryStylePresetClass
  const stylePresetContainerClass =
    kind != "secondary" ? primaryStylePresetContainerClass : secondaryStylePresetContainerClass

  return (
    <Bee.CarouselNav
      className={twMerge("w-0 shrink grow", stylePresetClass, className)}
      containerClassName={twMerge(stylePresetContainerClass, containerClassName)}
    >
      {children}
    </Bee.CarouselNav>
  )
}
