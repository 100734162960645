export type CountryData = {
  alpha2: string
  alpha3: string
  isStateRequired?: boolean
  isZipRequired?: boolean
  name: string
  numeric: string
  states?: StateData[]
  zipFormat?: string
}

export type StateData = {
  alpha2: string
  name: string
}

export const countries: Record<string, CountryData> = {
  AD: { name: "Andorra", numeric: "376", alpha2: "AD", alpha3: "AND", zipFormat: "CCNNN" },
  AE: { name: "United Arab Emirates", numeric: "971", alpha2: "AE", alpha3: "ARE" },
  AF: { name: "Afghanistan", numeric: "93", alpha2: "AF", alpha3: "AFG", zipFormat: "NNNN" },
  AG: { name: "Antigua and Barbuda", numeric: "1-268", alpha2: "AG", alpha3: "ATG" },
  AI: { name: "Anguilla", numeric: "1-264", alpha2: "AI", alpha3: "AIA", zipFormat: "AI-2640" },
  AL: { name: "Albania", numeric: "355", alpha2: "AL", alpha3: "ALB", zipFormat: "NNNN" },
  AM: { name: "Armenia", numeric: "374", alpha2: "AM", alpha3: "ARM", zipFormat: "NNNN" },
  AN: { name: "Netherlands Antilles", numeric: "599", alpha2: "AN", alpha3: "ANT" },
  AO: { name: "Angola", numeric: "244", alpha2: "AO", alpha3: "AGO" },
  AQ: { name: "Antarctica", numeric: "672", alpha2: "AQ", alpha3: "ATA", zipFormat: "BIQQ 1ZZ" },
  AR: { name: "Argentina", numeric: "54", alpha2: "AR", alpha3: "ARG", zipFormat: "NNNN, ANNNN, ANNNNAAA" },
  AS: { name: "American Samoa", numeric: "1-684", alpha2: "AS", alpha3: "ASM", zipFormat: "NNNNN, NNNNN-NNNN" },
  AT: { name: "Austria", numeric: "43", alpha2: "AT", alpha3: "AUT", zipFormat: "NNNN" },
  AU: { name: "Australia", numeric: "61", alpha2: "AU", alpha3: "AUS", zipFormat: "NNNN", isStateRequired: true },
  AW: { name: "Aruba", numeric: "297", alpha2: "AW", alpha3: "ABW" },
  AZ: { name: "Azerbaijan", numeric: "994", alpha2: "AZ", alpha3: "AZE", zipFormat: "CC NNNN" },
  BA: { name: "Bosnia and Herzegovina", numeric: "387", alpha2: "BA", alpha3: "BIH", zipFormat: "NNNNN" },
  BB: { name: "Barbados", numeric: "1-246", alpha2: "BB", alpha3: "BRB", zipFormat: "CCNNNNN" },
  BD: { name: "Bangladesh", numeric: "880", alpha2: "BD", alpha3: "BGD", zipFormat: "NNNN" },
  BE: { name: "Belgium", numeric: "32", alpha2: "BE", alpha3: "BEL", zipFormat: "NNNN" },
  BF: { name: "Burkina Faso", numeric: "226", alpha2: "BF", alpha3: "BFA" },
  BG: { name: "Bulgaria", numeric: "359", alpha2: "BG", alpha3: "BGR", zipFormat: "NNNN" },
  BH: { name: "Bahrain", numeric: "973", alpha2: "BH", alpha3: "BHR", zipFormat: "NNN, NNNN" },
  BI: { name: "Burundi", numeric: "257", alpha2: "BI", alpha3: "BDI" },
  BJ: { name: "Benin", numeric: "229", alpha2: "BJ", alpha3: "BEN" },
  BL: { name: "Saint Barthelemy", numeric: "590", alpha2: "BL", alpha3: "BLM", zipFormat: "97133" },
  BM: { name: "Bermuda", numeric: "1-441", alpha2: "BM", alpha3: "BMU" },
  BN: { name: "Brunei", numeric: "673", alpha2: "BN", alpha3: "BRN", zipFormat: "AANNNN" },
  BO: { name: "Bolivia", numeric: "591", alpha2: "BO", alpha3: "BOL" },
  BR: {
    name: "Brazil",
    numeric: "55",
    alpha2: "BR",
    alpha3: "BRA",
    zipFormat: "NNNNN, NNNNN-NNN",
    isStateRequired: true,
  },
  BS: { name: "Bahamas", numeric: "1-242", alpha2: "BS", alpha3: "BHS" },
  BT: { name: "Bhutan", numeric: "975", alpha2: "BT", alpha3: "BTN", zipFormat: "NNNNN" },
  BW: { name: "Botswana", numeric: "267", alpha2: "BW", alpha3: "BWA" },
  BY: { name: "Belarus", numeric: "375", alpha2: "BY", alpha3: "BLR", zipFormat: "NNNNNN" },
  BZ: { name: "Belize", numeric: "501", alpha2: "BZ", alpha3: "BLZ" },
  CA: {
    name: "Canada",
    numeric: "1",
    alpha2: "CA",
    alpha3: "CAN",
    zipFormat: "ANA NAN",
    isStateRequired: true,
    states: [
      { name: "Alberta", alpha2: "AB" },
      { name: "British Columbia", alpha2: "BC" },
      { name: "Manitoba", alpha2: "MB" },
      { name: "New Brunswick", alpha2: "NB" },
      { name: "Newfoundland and Labrador", alpha2: "NL" },
      { name: "Nova Scotia", alpha2: "NS" },
      { name: "Northwest Territories", alpha2: "NT" },
      { name: "Nunavut", alpha2: "NU" },
      { name: "Ontario", alpha2: "ON" },
      { name: "Prince Edward Island", alpha2: "PE" },
      { name: "Québec", alpha2: "QC" },
      { name: "Saskatchewan", alpha2: "SK" },
      { name: "Yukon", alpha2: "YT" },
    ],
  },
  CC: { name: "Cocos Islands", numeric: "61", alpha2: "CC", alpha3: "CCK", zipFormat: "NNNN" },
  CD: { name: "Democratic Republic of the Congo", numeric: "243", alpha2: "CD", alpha3: "COD" },
  CF: { name: "Central African Republic", numeric: "236", alpha2: "CF", alpha3: "CAF" },
  CG: { name: "Republic of the Congo", numeric: "242", alpha2: "CG", alpha3: "COG" },
  CH: { name: "Switzerland", numeric: "41", alpha2: "CH", alpha3: "CHE", zipFormat: "NNNN" },
  CI: { name: "Ivory Coast", numeric: "225", alpha2: "CI", alpha3: "CIV" },
  CK: { name: "Cook Islands", numeric: "682", alpha2: "CK", alpha3: "COK" },
  CL: { name: "Chile", numeric: "56", alpha2: "CL", alpha3: "CHL", zipFormat: "NNNNNNN, NNN-NNNN" },
  CM: { name: "Cameroon", numeric: "237", alpha2: "CM", alpha3: "CMR" },
  CN: { name: "China", numeric: "86", alpha2: "CN", alpha3: "CHN", zipFormat: "NNNNNN" },
  CO: { name: "Colombia", numeric: "57", alpha2: "CO", alpha3: "COL", zipFormat: "NNNNNN" },
  CR: { name: "Costa Rica", numeric: "506", alpha2: "CR", alpha3: "CRI", zipFormat: "NNNNN" },
  CU: { name: "Cuba", numeric: "53", alpha2: "CU", alpha3: "CUB", zipFormat: "NNNNN" },
  CV: { name: "Cape Verde", numeric: "238", alpha2: "CV", alpha3: "CPV", zipFormat: "NNNN" },
  CW: { name: "Curacao", numeric: "599", alpha2: "CW", alpha3: "CUW" },
  CX: { name: "Christmas Island", numeric: "61", alpha2: "CX", alpha3: "CXR", zipFormat: "NNNN" },
  CY: { name: "Cyprus", numeric: "357", alpha2: "CY", alpha3: "CYP", zipFormat: "NNNN" },
  CZ: { name: "Czech Republic", numeric: "420", alpha2: "CZ", alpha3: "CZE", zipFormat: "NNN NN" },
  DE: { name: "Germany", numeric: "49", alpha2: "DE", alpha3: "DEU", zipFormat: "NNNNN" },
  DJ: { name: "Djibouti", numeric: "253", alpha2: "DJ", alpha3: "DJI" },
  DK: { name: "Denmark", numeric: "45", alpha2: "DK", alpha3: "DNK", zipFormat: "NNNN" },
  DM: { name: "Dominica", numeric: "1-767", alpha2: "DM", alpha3: "DMA" },
  DO: {
    name: "Dominican Republic",
    numeric: "1-809, 1-829, 1-849",
    alpha2: "DO",
    alpha3: "DOM",
    zipFormat: "NNNNN",
  },
  DZ: { name: "Algeria", numeric: "213", alpha2: "DZ", alpha3: "DZA", zipFormat: "NNNNN" },
  EC: { name: "Ecuador", numeric: "593", alpha2: "EC", alpha3: "ECU", zipFormat: "NNNNNN" },
  EE: { name: "Estonia", numeric: "372", alpha2: "EE", alpha3: "EST", zipFormat: "NNNNN" },
  EG: { name: "Egypt", numeric: "20", alpha2: "EG", alpha3: "EGY", zipFormat: "NNNNN" },
  EH: { name: "Western Sahara", numeric: "212", alpha2: "EH", alpha3: "ESH" },
  ER: { name: "Eritrea", numeric: "291", alpha2: "ER", alpha3: "ERI" },
  ES: { name: "Spain", numeric: "34", alpha2: "ES", alpha3: "ESP", zipFormat: "NNNNN" },
  ET: { name: "Ethiopia", numeric: "251", alpha2: "ET", alpha3: "ETH", zipFormat: "NNNN" },
  FI: { name: "Finland", numeric: "358", alpha2: "FI", alpha3: "FIN", zipFormat: "NNNNN" },
  FJ: { name: "Fiji", numeric: "679", alpha2: "FJ", alpha3: "FJI" },
  FK: { name: "Falkland Islands", numeric: "500", alpha2: "FK", alpha3: "FLK", zipFormat: "FIQQ 1ZZ" },
  FM: { name: "Micronesia", numeric: "691", alpha2: "FM", alpha3: "FSM", zipFormat: "NNNNN, NNNNN-NNNN" },
  FO: { name: "Faroe Islands", numeric: "298", alpha2: "FO", alpha3: "FRO", zipFormat: "CC-NNN" },
  FR: { name: "France", numeric: "33", alpha2: "FR", alpha3: "FRA", zipFormat: "NNNNN" },
  GA: { name: "Gabon", numeric: "241", alpha2: "GA", alpha3: "GAB" },
  GB: {
    name: "United Kingdom",
    numeric: "44",
    alpha2: "GB",
    alpha3: "GBR",
    zipFormat: "AAN NAA, AANA NAA, AANN NAA, AN NAA, ANA NAA, ANN NAA",
  },
  GD: { name: "Grenada", numeric: "1-473", alpha2: "GD", alpha3: "GRD" },
  GE: { name: "Georgia", numeric: "995", alpha2: "GE", alpha3: "GEO", zipFormat: "NNNN" },
  GG: { name: "Guernsey", numeric: "4-1481", alpha2: "GG", alpha3: "GGY", zipFormat: "AAN NAA, AANN NAA" },
  GH: { name: "Ghana", numeric: "233", alpha2: "GH", alpha3: "GHA", zipFormat: "AA-NNNN-NNNN" },
  GI: { name: "Gibraltar", numeric: "350", alpha2: "GI", alpha3: "GIB", zipFormat: "GX11 1AA" },
  GL: { name: "Greenland", numeric: "299", alpha2: "GL", alpha3: "GRL", zipFormat: "NNNN" },
  GM: { name: "Gambia", numeric: "220", alpha2: "GM", alpha3: "GMB" },
  GN: { name: "Guinea", numeric: "224", alpha2: "GN", alpha3: "GIN", zipFormat: "NNN" },
  GQ: { name: "Equatorial Guinea", numeric: "240", alpha2: "GQ", alpha3: "GNQ" },
  GR: { name: "Greece", numeric: "30", alpha2: "GR", alpha3: "GRC", zipFormat: "NNN NN" },
  GT: { name: "Guatemala", numeric: "502", alpha2: "GT", alpha3: "GTM", zipFormat: "NNNNN" },
  GU: { name: "Guam", numeric: "1-671", alpha2: "GU", alpha3: "GUM", zipFormat: "NNNNN, NNNNN-NNNN" },
  GW: { name: "Guinea-Bissau", numeric: "245", alpha2: "GW", alpha3: "GNB", zipFormat: "NNNN" },
  GY: { name: "Guyana", numeric: "592", alpha2: "GY", alpha3: "GUY" },
  HK: { name: "Hong Kong", numeric: "852", alpha2: "HK", alpha3: "HKG" },
  HN: { name: "Honduras", numeric: "504", alpha2: "HN", alpha3: "HND", zipFormat: "AANNNN, NNNNN" },
  HR: { name: "Croatia", numeric: "385", alpha2: "HR", alpha3: "HRV", zipFormat: "NNNNN" },
  HT: { name: "Haiti", numeric: "509", alpha2: "HT", alpha3: "HTI", zipFormat: "NNNN" },
  HU: { name: "Hungary", numeric: "36", alpha2: "HU", alpha3: "HUN", zipFormat: "NNNN" },
  ID: { name: "Indonesia", numeric: "62", alpha2: "ID", alpha3: "IDN", zipFormat: "NNNNN" },
  IE: {
    name: "Ireland",
    numeric: "353",
    alpha2: "IE",
    alpha3: "IRL",
    isZipRequired: false,
    zipFormat: "ANN ####, ANA ####",
  },
  IL: { name: "Israel", numeric: "972", alpha2: "IL", alpha3: "ISR", zipFormat: "NNNNNNN" },
  IM: { name: "Isle of Man", numeric: "4-1624", alpha2: "IM", alpha3: "IMN", zipFormat: "CCN NAA, CCNN NAA" },
  IN: { name: "India", numeric: "91", alpha2: "IN", alpha3: "IND", zipFormat: "NNNNNN, NNN NNN" },
  IO: {
    name: "British Indian Ocean Territory",
    numeric: "246",
    alpha2: "IO",
    alpha3: "IOT",
    zipFormat: "BBND 1ZZ",
  },
  IQ: { name: "Iraq", numeric: "964", alpha2: "IQ", alpha3: "IRQ", zipFormat: "NNNNN" },
  IR: { name: "Iran", numeric: "98", alpha2: "IR", alpha3: "IRN", zipFormat: "NNNNN, NNNNNNNNNN, NNNNN-NNNNN" },
  IS: { name: "Iceland", numeric: "354", alpha2: "IS", alpha3: "ISL", zipFormat: "NNN" },
  IT: { name: "Italy", numeric: "39", alpha2: "IT", alpha3: "ITA", zipFormat: "NNNNN" },
  JE: { name: "Jersey", numeric: "4-1534", alpha2: "JE", alpha3: "JEY", zipFormat: "CCN NAA, CCNN NAA" },
  JM: {
    name: "Jamaica",
    numeric: "1-876",
    alpha2: "JM",
    alpha3: "JAM",
    zipFormat: "CCAAANN, NN",
    isZipRequired: false,
  },
  JO: { name: "Jordan", numeric: "962", alpha2: "JO", alpha3: "JOR", zipFormat: "NNNNN" },
  JP: { name: "Japan", numeric: "81", alpha2: "JP", alpha3: "JPN", zipFormat: "NNN-NNNN", isStateRequired: true },
  KE: { name: "Kenya", numeric: "254", alpha2: "KE", alpha3: "KEN", zipFormat: "NNNNN" },
  KG: { name: "Kyrgyzstan", numeric: "996", alpha2: "KG", alpha3: "KGZ", zipFormat: "NNNNNN" },
  KH: { name: "Cambodia", numeric: "855", alpha2: "KH", alpha3: "KHM", zipFormat: "120000" },
  KI: { name: "Kiribati", numeric: "686", alpha2: "KI", alpha3: "KIR" },
  KM: { name: "Comoros", numeric: "269", alpha2: "KM", alpha3: "COM" },
  KN: { name: "Saint Kitts and Nevis", numeric: "1-869", alpha2: "KN", alpha3: "KNA" },
  KR: { name: "South Korea", numeric: "82", alpha2: "KR", alpha3: "KOR", zipFormat: "NNN-NNN, NNNNN" },
  KW: { name: "Kuwait", numeric: "965", alpha2: "KW", alpha3: "KWT", zipFormat: "NNNNN" },
  KY: { name: "Cayman Islands", numeric: "1-345", alpha2: "KY", alpha3: "CYM", zipFormat: "CCN-NNNN" },
  KZ: { name: "Kazakhstan", numeric: "7", alpha2: "KZ", alpha3: "KAZ", zipFormat: "NNNNNN" },
  LA: { name: "Laos", numeric: "856", alpha2: "LA", alpha3: "LAO", zipFormat: "NNNNN" },
  LB: { name: "Lebanon", numeric: "961", alpha2: "LB", alpha3: "LBN", zipFormat: "NNNNN, NNNN NNNN" },
  LC: { name: "Saint Lucia", numeric: "1-758", alpha2: "LC", alpha3: "LCA", zipFormat: "CCNN NNN" },
  LI: { name: "Liechtenstein", numeric: "423", alpha2: "LI", alpha3: "LIE", zipFormat: "NNNN" },
  LK: { name: "Sri Lanka", numeric: "94", alpha2: "LK", alpha3: "LKA", zipFormat: "NNNNN" },
  LR: { name: "Liberia", numeric: "231", alpha2: "LR", alpha3: "LBR", zipFormat: "NNNN" },
  LS: { name: "Lesotho", numeric: "266", alpha2: "LS", alpha3: "LSO", zipFormat: "NNN" },
  LT: { name: "Lithuania", numeric: "370", alpha2: "LT", alpha3: "LTU", zipFormat: "CC-NNNNN" },
  LU: { name: "Luxembourg", numeric: "352", alpha2: "LU", alpha3: "LUX", zipFormat: "NNNN" },
  LV: { name: "Latvia", numeric: "371", alpha2: "LV", alpha3: "LVA", zipFormat: "CC-NNNN" },
  LY: { name: "Libya", numeric: "218", alpha2: "LY", alpha3: "LBY" },
  MA: { name: "Morocco", numeric: "212", alpha2: "MA", alpha3: "MAR", zipFormat: "NNNNN" },
  MC: { name: "Monaco", numeric: "377", alpha2: "MC", alpha3: "MCO", zipFormat: "980NN" },
  MD: { name: "Moldova", numeric: "373", alpha2: "MD", alpha3: "MDA", zipFormat: "CCNNNN, CC-NNNN" },
  ME: { name: "Montenegro", numeric: "382", alpha2: "ME", alpha3: "MNE", zipFormat: "NNNNN" },
  MF: { name: "Saint Martin", numeric: "590", alpha2: "MF", alpha3: "MAF", zipFormat: "97150" },
  MG: { name: "Madagascar", numeric: "261", alpha2: "MG", alpha3: "MDG", zipFormat: "NNN" },
  MH: { name: "Marshall Islands", numeric: "692", alpha2: "MH", alpha3: "MHL", zipFormat: "NNNNN, NNNNN-NNNN" },
  MK: { name: "Macedonia", numeric: "389", alpha2: "MK", alpha3: "MKD", zipFormat: "NNNN" },
  ML: { name: "Mali", numeric: "223", alpha2: "ML", alpha3: "MLI" },
  MM: { name: "Myanmar", numeric: "95", alpha2: "MM", alpha3: "MMR", zipFormat: "NNNNN" },
  MN: { name: "Mongolia", numeric: "976", alpha2: "MN", alpha3: "MNG", zipFormat: "NNNNN" },
  MO: { name: "Macau", numeric: "853", alpha2: "MO", alpha3: "MAC" },
  MP: {
    name: "Northern Mariana Islands",
    numeric: "1-670",
    alpha2: "MP",
    alpha3: "MNP",
    zipFormat: "NNNNN, NNNNN-NNNN",
  },
  MR: { name: "Mauritania", numeric: "222", alpha2: "MR", alpha3: "MRT" },
  MS: { name: "Montserrat", numeric: "1-664", alpha2: "MS", alpha3: "MSR", zipFormat: "MSR NNNN" },
  MT: { name: "Malta", numeric: "356", alpha2: "MT", alpha3: "MLT", zipFormat: "AAA NNNN" },
  MU: { name: "Mauritius", numeric: "230", alpha2: "MU", alpha3: "MUS", zipFormat: "NNNNN" },
  MV: { name: "Maldives", numeric: "960", alpha2: "MV", alpha3: "MDV", zipFormat: "NNNNN" },
  MW: { name: "Malawi", numeric: "265", alpha2: "MW", alpha3: "MWI" },
  MX: { name: "Mexico", numeric: "52", alpha2: "MX", alpha3: "MEX", zipFormat: "NNNNN" },
  MY: { name: "Malaysia", numeric: "60", alpha2: "MY", alpha3: "MYS", zipFormat: "NNNNN" },
  MZ: { name: "Mozambique", numeric: "258", alpha2: "MZ", alpha3: "MOZ", zipFormat: "NNNN" },
  NA: { name: "Namibia", numeric: "264", alpha2: "NA", alpha3: "NAM" },
  NC: { name: "New Caledonia", numeric: "687", alpha2: "NC", alpha3: "NCL", zipFormat: "988NN" },
  NE: { name: "Niger", numeric: "227", alpha2: "NE", alpha3: "NER", zipFormat: "NNNN" },
  NG: { name: "Nigeria", numeric: "234", alpha2: "NG", alpha3: "NGA", zipFormat: "NNNNNN" },
  NI: { name: "Nicaragua", numeric: "505", alpha2: "NI", alpha3: "NIC", zipFormat: "NNNNN" },
  NL: { name: "Netherlands", numeric: "31", alpha2: "NL", alpha3: "NLD" },
  NO: { name: "Norway", numeric: "47", alpha2: "NO", alpha3: "NOR", zipFormat: "NNNN" },
  NP: { name: "Nepal", numeric: "977", alpha2: "NP", alpha3: "NPL", zipFormat: "NNNNN" },
  NR: { name: "Nauru", numeric: "674", alpha2: "NR", alpha3: "NRU" },
  NU: { name: "Niue", numeric: "683", alpha2: "NU", alpha3: "NIU" },
  NZ: { name: "New Zealand", numeric: "64", alpha2: "NZ", alpha3: "NZL", zipFormat: "NNNN" },
  OM: { name: "Oman", numeric: "968", alpha2: "OM", alpha3: "OMN", zipFormat: "NNN" },
  PA: { name: "Panama", numeric: "507", alpha2: "PA", alpha3: "PAN", zipFormat: "NNNN" },
  PE: { name: "Peru", numeric: "51", alpha2: "PE", alpha3: "PER", zipFormat: "NNNNN, CC NNNN" },
  PF: { name: "French Polynesia", numeric: "689", alpha2: "PF", alpha3: "PYF", zipFormat: "987NN" },
  PG: { name: "Papua New Guinea", numeric: "675", alpha2: "PG", alpha3: "PNG", zipFormat: "NNN" },
  PH: { name: "Philippines", numeric: "63", alpha2: "PH", alpha3: "PHL", zipFormat: "NNNN" },
  PK: { name: "Pakistan", numeric: "92", alpha2: "PK", alpha3: "PAK", zipFormat: "NNNNN" },
  PL: { name: "Poland", numeric: "48", alpha2: "PL", alpha3: "POL", zipFormat: "NN-NNN" },
  PM: { name: "Saint Pierre and Miquelon", numeric: "508", alpha2: "PM", alpha3: "SPM", zipFormat: "97500" },
  PN: { name: "Pitcairn", numeric: "64", alpha2: "PN", alpha3: "PCN", zipFormat: "PCRN 1ZZ" },
  PR: {
    name: "Puerto Rico",
    numeric: "1-787, 1-939",
    alpha2: "PR",
    alpha3: "PRI",
    zipFormat: "NNNNN, NNNNN-NNNN",
  },
  PS: { name: "Palestine", numeric: "970", alpha2: "PS", alpha3: "PSE", zipFormat: "NNN" },
  PT: { name: "Portugal", numeric: "351", alpha2: "PT", alpha3: "PRT", zipFormat: "NNNN-NNN" },
  PW: { name: "Palau", numeric: "680", alpha2: "PW", alpha3: "PLW", zipFormat: "NNNNN, NNNNN-NNNN" },
  PY: { name: "Paraguay", numeric: "595", alpha2: "PY", alpha3: "PRY", zipFormat: "NNNN" },
  QA: { name: "Qatar", numeric: "974", alpha2: "QA", alpha3: "QAT" },
  RE: { name: "Reunion", numeric: "262", alpha2: "RE", alpha3: "REU", zipFormat: "974NN" },
  RO: { name: "Romania", numeric: "40", alpha2: "RO", alpha3: "ROU", zipFormat: "NNNNNN" },
  RS: { name: "Serbia", numeric: "381", alpha2: "RS", alpha3: "SRB", zipFormat: "NNNNN" },
  RU: { name: "Russia", numeric: "7", alpha2: "RU", alpha3: "RUS", zipFormat: "NNNNNN" },
  RW: { name: "Rwanda", numeric: "250", alpha2: "RW", alpha3: "RWA" },
  SA: { name: "Saudi Arabia", numeric: "966", alpha2: "SA", alpha3: "SAU", zipFormat: "NNNNN-NNNN, NNNNN" },
  SB: { name: "Solomon Islands", numeric: "677", alpha2: "SB", alpha3: "SLB" },
  SC: { name: "Seychelles", numeric: "248", alpha2: "SC", alpha3: "SYC" },
  SD: { name: "Sudan", numeric: "249", alpha2: "SD", alpha3: "SDN", zipFormat: "NNNNN" },
  SE: { name: "Sweden", numeric: "46", alpha2: "SE", alpha3: "SWE", zipFormat: "NNN NN" },
  SG: { name: "Singapore", numeric: "65", alpha2: "SG", alpha3: "SGP", zipFormat: "NNNNNN" },
  SH: { name: "Saint Helena", numeric: "290", alpha2: "SH", alpha3: "SHN", zipFormat: "AAAA 1ZZ" },
  SI: { name: "Slovenia", numeric: "386", alpha2: "SI", alpha3: "SVN", zipFormat: "NNNN, CC-NNNN" },
  SJ: { name: "Svalbard and Jan Mayen", numeric: "47", alpha2: "SJ", alpha3: "SJM", zipFormat: "NNNN" },
  SK: { name: "Slovakia", numeric: "421", alpha2: "SK", alpha3: "SVK", zipFormat: "NNN NN" },
  SL: { name: "Sierra Leone", numeric: "232", alpha2: "SL", alpha3: "SLE" },
  SM: { name: "San Marino", numeric: "378", alpha2: "SM", alpha3: "SMR", zipFormat: "4789N" },
  SN: { name: "Senegal", numeric: "221", alpha2: "SN", alpha3: "SEN", zipFormat: "NNNNN" },
  SO: { name: "Somalia", numeric: "252", alpha2: "SO", alpha3: "SOM", zipFormat: "AA NNNNN" },
  SR: { name: "Suriname", numeric: "597", alpha2: "SR", alpha3: "SUR" },
  SS: { name: "South Sudan", numeric: "211", alpha2: "SS", alpha3: "SSD" },
  ST: { name: "Sao Tome and Principe", numeric: "239", alpha2: "ST", alpha3: "STP" },
  SV: { name: "El Salvador", numeric: "503", alpha2: "SV", alpha3: "SLV", zipFormat: "NNNN", isZipRequired: false },
  SX: { name: "Sint Maarten", numeric: "1-721", alpha2: "SX", alpha3: "SXM" },
  SY: { name: "Syria", numeric: "963", alpha2: "SY", alpha3: "SYR" },
  SZ: { name: "Swaziland", numeric: "268", alpha2: "SZ", alpha3: "SWZ", zipFormat: "ANNN" },
  TC: { name: "Turks and Caicos Islands", numeric: "1-649", alpha2: "TC", alpha3: "TCA", zipFormat: "TKCA 1ZZ" },
  TD: { name: "Chad", numeric: "235", alpha2: "TD", alpha3: "TCD" },
  TG: { name: "Togo", numeric: "228", alpha2: "TG", alpha3: "TGO" },
  TH: { name: "Thailand", numeric: "66", alpha2: "TH", alpha3: "THA", zipFormat: "NNNNN" },
  TJ: { name: "Tajikistan", numeric: "992", alpha2: "TJ", alpha3: "TJK", zipFormat: "NNNNNN" },
  TK: { name: "Tokelau", numeric: "690", alpha2: "TK", alpha3: "TKL" },
  TL: { name: "East Timor", numeric: "670", alpha2: "TL", alpha3: "TLS" },
  TM: { name: "Turkmenistan", numeric: "993", alpha2: "TM", alpha3: "TKM", zipFormat: "NNNNNN" },
  TN: { name: "Tunisia", numeric: "216", alpha2: "TN", alpha3: "TUN", zipFormat: "NNNN" },
  TO: { name: "Tonga", numeric: "676", alpha2: "TO", alpha3: "TON" },
  TR: { name: "Turkey", numeric: "90", alpha2: "TR", alpha3: "TUR", zipFormat: "NNNNN" },
  TT: { name: "Trinidad and Tobago", numeric: "1-868", alpha2: "TT", alpha3: "TTO", zipFormat: "NNNNNN" },
  TV: { name: "Tuvalu", numeric: "688", alpha2: "TV", alpha3: "TUV" },
  TW: { name: "Taiwan", numeric: "886", alpha2: "TW", alpha3: "TWN", zipFormat: "NNN, NNN-NN, NNN-NNN" },
  TZ: { name: "Tanzania", numeric: "255", alpha2: "TZ", alpha3: "TZA", zipFormat: "NNNNN" },
  UA: { name: "Ukraine", numeric: "380", alpha2: "UA", alpha3: "UKR", zipFormat: "NNNNN" },
  UG: { name: "Uganda", numeric: "256", alpha2: "UG", alpha3: "UGA" },
  US: {
    name: "United States",
    numeric: "1",
    alpha2: "US",
    alpha3: "USA",
    zipFormat: "NNNNN, NNNNN-NNNN",
    isStateRequired: true,
    states: [
      { name: "Alabama", alpha2: "AL" },
      { name: "Alaska", alpha2: "AK" },
      { name: "American Samoa", alpha2: "AS" },
      { name: "Arizona", alpha2: "AZ" },
      { name: "Arkansas", alpha2: "AR" },
      { name: "California", alpha2: "CA" },
      { name: "Colorado", alpha2: "CO" },
      { name: "Connecticut", alpha2: "CT" },
      { name: "Delaware", alpha2: "DE" },
      { name: "District Of Columbia", alpha2: "DC" },
      { name: "Federated States Of Micronesia", alpha2: "FM" },
      { name: "Florida", alpha2: "FL" },
      { name: "Georgia", alpha2: "GA" },
      { name: "Guam", alpha2: "GU" },
      { name: "Hawaii", alpha2: "HI" },
      { name: "Idaho", alpha2: "ID" },
      { name: "Illinois", alpha2: "IL" },
      { name: "Indiana", alpha2: "IN" },
      { name: "Iowa", alpha2: "IA" },
      { name: "Kansas", alpha2: "KS" },
      { name: "Kentucky", alpha2: "KY" },
      { name: "Louisiana", alpha2: "LA" },
      { name: "Maine", alpha2: "ME" },
      { name: "Marshall Islands", alpha2: "MH" },
      { name: "Maryland", alpha2: "MD" },
      { name: "Massachusetts", alpha2: "MA" },
      { name: "Michigan", alpha2: "MI" },
      { name: "Minnesota", alpha2: "MN" },
      { name: "Mississippi", alpha2: "MS" },
      { name: "Missouri", alpha2: "MO" },
      { name: "Montana", alpha2: "MT" },
      { name: "Nebraska", alpha2: "NE" },
      { name: "Nevada", alpha2: "NV" },
      { name: "New Hampshire", alpha2: "NH" },
      { name: "New Jersey", alpha2: "NJ" },
      { name: "New Mexico", alpha2: "NM" },
      { name: "New York", alpha2: "NY" },
      { name: "North Carolina", alpha2: "NC" },
      { name: "North Dakota", alpha2: "ND" },
      { name: "Northern Mariana Islands", alpha2: "MP" },
      { name: "Ohio", alpha2: "OH" },
      { name: "Oklahoma", alpha2: "OK" },
      { name: "Oregon", alpha2: "OR" },
      { name: "Palau", alpha2: "PW" },
      { name: "Pennsylvania", alpha2: "PA" },
      { name: "Puerto Rico", alpha2: "PR" },
      { name: "Rhode Island", alpha2: "RI" },
      { name: "South Carolina", alpha2: "SC" },
      { name: "South Dakota", alpha2: "SD" },
      { name: "Tennessee", alpha2: "TN" },
      { name: "Texas", alpha2: "TX" },
      { name: "Utah", alpha2: "UT" },
      { name: "Vermont", alpha2: "VT" },
      { name: "Virgin Islands", alpha2: "VI" },
      { name: "Virginia", alpha2: "VA" },
      { name: "Washington", alpha2: "WA" },
      { name: "West Virginia", alpha2: "WV" },
      { name: "Wisconsin", alpha2: "WI" },
      { name: "Wyoming", alpha2: "WY" },
      { name: "U.S. Armed Forces – Americas", alpha2: "AA" },
      { name: "U.S. Armed Forces – Europe", alpha2: "AE" },
      { name: "U.S. Armed Forces – Pacific", alpha2: "AP" },
    ],
  },
  UY: { name: "Uruguay", numeric: "598", alpha2: "UY", alpha3: "URY", zipFormat: "NNNNN" },
  UZ: { name: "Uzbekistan", numeric: "998", alpha2: "UZ", alpha3: "UZB", zipFormat: "NNNNNN" },
  VA: { name: "Vatican", numeric: "379", alpha2: "VA", alpha3: "VAT", zipFormat: "00120" },
  VC: {
    name: "Saint Vincent and the Grenadines",
    numeric: "1-784",
    alpha2: "VC",
    alpha3: "VCT",
    zipFormat: "CCNNNN",
  },
  VE: { name: "Venezuela", numeric: "58", alpha2: "VE", alpha3: "VEN", zipFormat: "NNNN, NNNN-A" },
  VG: { name: "British Virgin Islands", numeric: "1-284", alpha2: "VG", alpha3: "VGB", zipFormat: "CCNNNN" },
  VI: {
    name: "U.S. Virgin Islands",
    numeric: "1-340",
    alpha2: "VI",
    alpha3: "VIR",
    zipFormat: "NNNNN, NNNNN-NNNN",
  },
  VN: { name: "Vietnam", numeric: "84", alpha2: "VN", alpha3: "VNM", zipFormat: "NNNNNN" },
  VU: { name: "Vanuatu", numeric: "678", alpha2: "VU", alpha3: "VUT" },
  WF: { name: "Wallis and Futuna", numeric: "681", alpha2: "WF", alpha3: "WLF", zipFormat: "986NN" },
  WS: { name: "Samoa", numeric: "685", alpha2: "WS", alpha3: "WSM", zipFormat: "CCNNNN" },
  XK: { name: "Kosovo", numeric: "383", alpha2: "XK", alpha3: "XKX", zipFormat: "NNNNN" },
  YE: { name: "Yemen", numeric: "967", alpha2: "YE", alpha3: "YEM" },
  YT: { name: "Mayotte", numeric: "262", alpha2: "YT", alpha3: "MYT", zipFormat: "976NN" },
  ZA: { name: "South Africa", numeric: "27", alpha2: "ZA", alpha3: "ZAF", zipFormat: "NNNN" },
  ZM: { name: "Zambia", numeric: "260", alpha2: "ZM", alpha3: "ZMB", zipFormat: "NNNNN" },
  ZW: { name: "Zimbabwe", numeric: "263", alpha2: "ZW", alpha3: "ZWE" },
}
