import * as React from "react"

import { TrashIcon } from "@heroicons/react/24/outline"
import { DropzoneOptions, useDropzone } from "react-dropzone"
import { twMerge } from "tailwind-merge"

import { useFieldContext } from "./BeeKit/Field"

export interface FileInputProps extends Omit<DropzoneOptions, "multiple" | "maxFiles"> {
  id?: string
  label?: string
  name?: string
  fileName?: string
  placeholder?: string
  disabled?: boolean
  onClear?: () => void
}

export function FileInput(props: FileInputProps) {
  const {
    id,
    fileName,
    label = "Choose file",
    name,
    placeholder = "No file chosen",
    disabled,
    onClear,
    ...dropzoneOptions
  } = props
  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({ ...dropzoneOptions, multiple: false })
  const [currentFile, setCurrentFile] = React.useState<File | null>(null)

  const fieldContext = useFieldContext()

  React.useEffect(() => {
    if (inputRef.current && acceptedFiles[0]) {
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(acceptedFiles[0])
      inputRef.current.files = dataTransfer.files

      setCurrentFile(inputRef.current?.files?.[0])
    }
  }, [acceptedFiles])

  const onClearInput = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = ""
    }
    setCurrentFile(null)
    onClear?.()
  }, [onClear])

  let fileNameValue: string | undefined
  if (!currentFile && !fileName) {
    fileNameValue = placeholder
  } else if (currentFile) {
    fileNameValue = currentFile.name
  } else {
    fileNameValue = fileName
  }

  const contentClass = twMerge(
    "shrink grow basis-0 text-sm font-normal leading-tight",
    !currentFile ? "italic text-gray-400" : ""
  )

  return (
    <div
      className={twMerge(
        "flex w-full rounded border border-gray-200 bg-white",
        "focus-within:ring-offset-0",
        "focus-within:ring-1",
        "[&:not([data-errored])]:focus-within:border-blue-600",
        "[&:not([data-errored])]:focus-within:ring-blue-600",
        "data-[errored]:border-red-500",
        "data-[errored]:focus-within:ring-red-500"
      )}
      {...(fieldContext?.hasErrors ? { "data-errored": true } : {})}
    >
      <div
        {...getRootProps({
          className:
            "flex flex-row w-full justify-start items-start cursor-pointer divide-x focus-visible:outline-none focus:outline-none",
        })}
      >
        <div className="flex h-11 flex-initial items-center justify-center gap-2 px-5 py-2.5">
          <span className="text-sm font-medium leading-tight text-gray-900">{label}</span>
        </div>
        <div className="flex h-11 flex-auto items-start justify-start px-4 py-3">
          <input disabled={disabled} {...getInputProps({ id, name })} />

          <div className="flex h-5 items-start justify-start">
            <div className={contentClass}>{fileNameValue}</div>
          </div>
        </div>
      </div>
      {currentFile ? (
        <button type="button" onClick={onClearInput} className="-ml-8 shadow-none">
          <TrashIcon className="h-4 w-4 hover:text-blue-500" />
        </button>
      ) : null}
    </div>
  )
}
