import * as yup from "yup"
import { AnyObject, Maybe } from "yup/lib/types"

// eslint-disable-next-line no-control-regex
const ANSI_STRING_REGEX = /^[\x00-\x7F\xA0-\xFF]+$/

yup.addMethod<yup.StringSchema>(yup.string, "latin", function () {
  return this.matches(ANSI_STRING_REGEX, {
    message: "Must include only latin characters (Windows-1252)",
    excludeEmptyString: true,
  })
})

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    latin(): StringSchema<TType, TContext>
  }
}

export default yup
