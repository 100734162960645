import { createStore, StoreApi, useStore } from "zustand"

import { RequestedOrderItem } from "~/src/types/requestedOrderItem"

//region Types
export type RequestCartStore = {
  isOpen?: boolean
  isDirty?: boolean
  items: RequestedOrderItem[]
  clean: () => void
  close: () => void
  count: () => number
  dirty: () => void
  itemChanged: () => void
  open: () => void
  setItems: (items: RequestedOrderItem[]) => void
}

declare global {
  interface Window {
    requestCartStore: StoreApi<RequestCartStore>
  }
}
//endregion

/**
 * Store to remotely manage state of a request cart across Shadow DOM/React Root boundaries.
 *
 * @returns The state and actions to control the request cart.
 *
 * @property isOpen Indicates whether the request cart is open.
 * @property open Function to open the request cart.
 * @property close Function to close the request cart.
 * @property toggle Function to toggle the state of the request cart.
 */
export const requestCartStore = createStore<RequestCartStore>()((set, get) => ({
  /** State */
  isOpen: false,
  isDirty: false,
  items: [],
  /** Actions */
  clean: () => set(() => ({ isDirty: false })),
  close: () => set(() => ({ isOpen: false })),
  dirty: () => set(() => ({ isDirty: true })),
  itemChanged: () => set(() => ({ isDirty: true, isOpen: true })),
  open: () => set(() => ({ isOpen: true })),
  setItems: (items: RequestedOrderItem[]) => set(() => ({ items })),
  count: () => get().items.length,
}))

window.requestCartStore = requestCartStore

/**
 * Custom hook to access the request cart store.
 *
 * This hook utilizes the `useStore` hook to retrieve the state and actions
 * from the `requestCartStore`. It provides an easy way to interact with the
 * request cart within the application.
 *
 * @returns The state and actions from the request cart store.
 */
export const useRequestCart = () => useStore(requestCartStore)
