import { create } from "zustand"

export type AttachmentStore = {
  attachmentRequest: AttachmentRequest | null
  setAttachmentRequest: (attachmentRequest: AttachmentRequest) => void
  clearAttachmentRequest: () => void

  kitListRequest: KitListRequest | null
  setKitListRequest: (kitListRequest: KitListRequest) => void
  clearKitListRequest: () => void
}

export type AttachmentRequest = {
  fulfillmentRequest: FulfillmentRequest
  kitFieldNames: string[]
  kitFieldChoices: string[][]
  kitComboMapper: Map<string, number> | null
  resolve: (value: unknown) => void
}

export type KitListRequest = {
  kitFieldNames: string[]
  kitFieldChoices: string[][]
  resolve: (value: unknown) => void
}

export type FulfillmentRequest = {
  id: string
  batch_store_order_user_id?: string
  order_id?: string
  fulfillment_kits: FulfillmentKit[]
}

export type FulfillmentKit = {
  id: number
  name: string
  quantity: number
  fulfillment_kit_items: FulfillmentKitItem[]
}

export type FulfillmentKitItem = {
  id: number
  notes: string
  quantity: number
  variant_id: number
}

export const useStore = create<AttachmentStore>((set) => ({
  attachmentRequest: null,
  setAttachmentRequest: (attachmentRequest) => {
    set((state) => ({
      ...state,
      attachmentRequest,
    }))
  },
  clearAttachmentRequest: () => {
    set((state) => ({ ...state, attachmentRequest: null }))
  },

  kitListRequest: null,
  setKitListRequest: (kitListRequest) => {
    set((state) => ({
      ...state,
      kitListRequest,
    }))
  },
  clearKitListRequest: () => {
    set((state) => ({ ...state, kitListRequest: null }))
  },
}))
