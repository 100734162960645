/**
 * @param {*} fn a predicate function
 * @param {*} arr an array
 * @returns values from `arr` until `fn` returns `true`
 */
export function takeUntil<T>(fn: (val: T, index: keyof T[], arr: T[]) => boolean, arr: T[]) {
  const newArr: T[] = []

  for (let i = 0; i < arr.length; i += 1) {
    newArr.push(arr[i])

    if (fn(arr[i], i, arr) === true) break
  }

  return newArr
}

export function last<T>(arr: T[]): T | undefined {
  return arr[arr.length - 1]
}

export type MapperFn<T> = (val: T, index: number, arr: T[]) => T

/**
 * Insert values between elements of an array using a seperator function
 * @param sepFn takes last value, index of separator and array
 * @param arr
 * @returns array of arr.length * 2 - 1
 * @example
 *     intersperse((v, i) => v + i, [1, 2, 3])
 *     // [1, 2, 2, 5, 3]
 */
export function intersperse<T>(sepFn: MapperFn<T>, arr: T[]): T[] {
  const newArr: T[] = []

  for (let i = 0; i < arr.length; i += 1) {
    newArr.push(arr[i])

    if (i < arr.length - 1) newArr.push(sepFn(arr[i], newArr.length, arr))
  }

  return newArr
}

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
export const range = (from = 1, to = 1, step = 1): Array<number> => {
  let i = from
  const range: Array<number> = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}

/**
 * @param {*} arr an array
 * @param {*} fn a predicate function
 * @returns a tuple of two arrays; the first array with elements for which the predicate function returned true,
 * and the second with elements for which the function returned false.
 */
export function partition<T>(arr: T[], fn: (val: T, index: keyof T[], arr: T[]) => boolean): [T[], T[]] {
  return arr.reduce<[T[], T[]]>(
    (acc, val, i, arr) => {
      acc[fn(val, i, arr) ? 0 : 1].push(val)
      return acc
    },
    [[], []]
  )
}

/**
 * Wrap a value in an array if it is not already an array excluding null and undefined values
 * @param val
 */
export function arrayWrap<T>(val: T | T[]): NonNullable<T>[] {
  return (Array.isArray(val) ? val : [val]).filter((x) => x != null) as NonNullable<T>[]
}
