import { WorkdayAnniversaryGiftForm } from "./WorkdayAnniversaryGiftForm"
import { WorkdayNewHireGiftForm } from "./WorkdayNewHireGiftForm"

export const formRegistry = {
  WorkdayAnniversaryGiftForm,
  WorkdayNewHireGiftForm,
}
export const formOptions: [string, string][] = Object.keys(formRegistry).map((id) => [id, formRegistry[id].title ?? id])

export * from "./types"
