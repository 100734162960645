import React, { CSSProperties, ReactNode, useMemo } from "react"

import { ExclamationCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { toast as toast_, Toaster, ToastOptions as ToastOptions_ } from "react-hot-toast"
import { twMerge } from "tailwind-merge"

import { dataFlag } from "~/src/lib/jsx"

export type ToastProps = {
  title?: ReactNode
  className?: string
  children: ReactNode
  onClose?: () => void
  style?: CSSProperties
  visible?: boolean
  variation?: "light" | "dark"
}

export type ToastOptions = {
  title?: ReactNode
  message: ReactNode
  variation?: "light" | "dark"
} & ToastOptions_

declare global {
  interface Window {
    Bee: {
      toast: typeof toast
    }
  }
}

function Toast(props: ToastProps) {
  const { title, className: className_, style, children, visible = false, variation = "light", onClose } = props

  const className = useMemo(
    () =>
      twMerge(
        "flex rounded-lg p-4 text-sm shadow-md",
        "-translate-y-full opacity-0 transition data-[visible]:translate-y-0 data-[visible]:opacity-100",
        variation === "light" ? "bg-gray-50 text-gray-900" : "bg-gray-900 text-gray-50",
        className_
      ),
    [variation]
  )

  return (
    <div
      role="alert"
      className={twMerge("pointer-events-auto", className)}
      style={style}
      {...dataFlag(visible, "visible")}
    >
      <div>
        <hgroup className="mb-1 flex">
          <h1 className="font-semibold">{title}</h1>

          <button aria-label="Close Alert" type="button" className="ml-auto" onClick={onClose}>
            <XMarkIcon className="h-5 w-5" />
          </button>
        </hgroup>
        <p>{children}</p>
      </div>
    </div>
  )
}

function base(options: ToastOptions): string {
  const { title, message, variation = "light", ...toastOptions } = options

  return toast_.custom(
    (t) => (
      <Toast
        key={t.id}
        style={t.style}
        onClose={() => toast_.dismiss(t.id)}
        title={title}
        visible={t.visible}
        variation={variation}
      >
        {message}
      </Toast>
    ),
    toastOptions
  )
}

const toast = {
  /**
   * Displays a error toast notification with the provided options.
   *
   * @param options - The options for the toast notification.
   * @param options.title - The title of the toast notification.
   * @param options.message - The message content of the toast notification.
   * @param [options.variation="dark"] - The variation style of the toast notification.
   * @param [options.toastOptions] - Additional options for the toast notification.
   * @returns
   */
  error: (options: ToastOptions) =>
    base({
      title: <ExclamationTriangleIcon className="h-5 w-5" />,
      duration: 5 * 60 * 1000, // 5 minutes
      ...options,
      variation: "dark",
    }),
  /**
   * Displays a success toast notification with the provided options.
   *
   * @param options - The options for the toast notification.
   * @param options.title - The title of the toast notification.
   * @param options.message - The message content of the toast notification.
   * @param [options.variation="light"] - The variation style of the toast notification.
   * @param [options.toastOptions] - Additional options for the toast notification.
   * @returns
   */
  success: (options: ToastOptions) => base({ title: <ExclamationCircleIcon className="h-5 w-5" />, ...options }),
  /**
   * Displays a warning toast notification with the provided options.
   *
   * @param options - The options for the toast notification.
   * @param options.title - The title of the toast notification.
   * @param options.message - The message content of the toast notification.
   * @param [options.variation="dark"] - The variation style of the toast notification.
   * @param [options.toastOptions] - Additional options for the toast notification.
   * @returns
   */
  warning: (options: ToastOptions) =>
    base({ title: <ExclamationTriangleIcon className="h-5 w-5" />, ...options, variation: "dark" }),
}

window.Bee = { toast }

export { toast, Toast, Toaster }
