import React, { DetailedHTMLProps, FormHTMLAttributes, forwardRef } from "react"

import { useCsrfToken } from "~/src/hooks"

export type RailsFormProps = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>

/**
 * Unless this is an HTTP GET, add a hidden input CSRF token as extracted from the DOM.
 */
const csrfTokenField = (method: string) => {
  const csrfToken = useCsrfToken()

  if (method != "get") {
    return <input name="authenticity_token" type="hidden" value={csrfToken}></input>
  } else {
    return null
  }
}

const formActionField = (method: string) => {
  if (method != "get" && method != "post") {
    return <input name="_method" type="hidden" value={method}></input>
  } else {
    return null
  }
}

// coerce method to lowercase and either "get" or "post"
const formMethod = (method: string) => ({ get: "get" })[method.toLowerCase()] ?? "post"

/**
 * Bridge between React and Rails forms.
 *
 */
export const RailsForm = forwardRef<HTMLFormElement, RailsFormProps>(function RailsForm(props, ref) {
  const { children, method = "get", ...restProps } = props

  return (
    <form ref={ref} {...restProps} method={formMethod(method)}>
      <input name="utf8" type="hidden" value="✓"></input>
      {csrfTokenField(method)}
      {formActionField(method)}
      {children}
    </form>
  )
})
