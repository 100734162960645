import React, { ReactNode, useEffect } from "react"

import { Dialog, DialogPanel } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

export type ModalProps = {
  open: boolean
  children: ReactNode
  className?: string
  backgroundClassName?: string
  usePortal?: boolean
  onClose?: () => void

  showCloseButton?: boolean
}

export function Modal(props: ModalProps) {
  const {
    open,
    children,
    className = "",
    backgroundClassName = "",
    usePortal = true,
    showCloseButton = true,
    onClose,
  } = props

  // Disable body scrolling when modal is open
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }

    return () => {
      document.body.style.overflow = ""
    }
  }, [open])

  const handleOnClose = () => {
    document.body.style.overflow = ""
    onClose?.()
  }

  if (!usePortal) {
    return <NonPortalModal {...props} onClose={handleOnClose} />
  }

  return (
    <Dialog
      className={twMerge(
        "fixed left-0 right-0 top-0 z-50 flex h-screen w-screen place-content-center bg-gray-600/80 backdrop-blur-sm",
        backgroundClassName
      )}
      open={open}
      onClose={handleOnClose}
    >
      <DialogPanel className={twMerge(className, "mx-6 flex flex-col gap-1 place-self-center rounded-lg p-6")}>
        {showCloseButton ? (
          <button type="button" className="place-self-end" onClick={handleOnClose}>
            <XMarkIcon className="h-6 w-6 text-gray-600" />
          </button>
        ) : (
          <></>
        )}
        {children}
      </DialogPanel>
    </Dialog>
  )
}

function NonPortalModal(props: ModalProps) {
  const { open, onClose, children, className = "", backgroundClassName = "", showCloseButton = true } = props
  return (
    open && (
      <div
        className={twMerge(
          "fixed left-0 right-0 top-0 z-50 flex h-screen w-screen place-content-center bg-gray-600/80 backdrop-blur-sm",
          backgroundClassName
        )}
        onClick={onClose}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={twMerge(
            "mx-6 my-4 flex max-h-[calc(100vh-3rem)] flex-col gap-1 place-self-center overflow-y-auto rounded-lg p-6",
            className
          )}
        >
          {showCloseButton ? (
            <button type="button" className="place-self-end" onClick={onClose}>
              <XMarkIcon className="h-6 w-6 text-gray-600" />
            </button>
          ) : null}
          {children}
        </div>
      </div>
    )
  )
}
