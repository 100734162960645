import React, { Fragment, ReactElement, useCallback, useEffect } from "react"

import {
  Combobox,
  ComboboxButton,
  ComboboxOptions,
  ComboboxInput as HUIComboboxInput,
  ComboboxOption as HUIComboboxOption,
  Label,
  Transition,
} from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

import { useFieldContext } from "./Field"
import { isNil, Nil } from "~/src/lib/any"
import { dataFlag } from "~/src/lib/jsx"

type ComboboxValue = string | number | Nil
type ComboboxLabel = string
export type ComboboxOption = [ComboboxValue, ComboboxLabel, string?]

export type ComboboxInputProps = {
  name?: string
  emptyText?: string
  immediate?: boolean
  options: ComboboxOption[]
  defaultValue?: string | number
  value?: ComboboxValue
  onChange?: (value: ComboboxOption) => void
  className?: string
  label?: string
  disabled?: boolean
  nullable?: boolean
  placeholder?: string
  renderOption?: (option: ComboboxOption) => ReactElement
  renderSelectedOption?: (option: ComboboxOption) => ReactElement
  optionClassname?: string
  allowCustomValue?: boolean
}

const compareComboboxOptions = (a?: ComboboxOption, b?: ComboboxOption) => Object.is(a?.[0], b?.[0])

/**
 * Dropdown input with autocomplete. The `name` prop is optional, and if provided, will render a hidden input with the
 * selected value.
 */
export function ComboboxInput(props: ComboboxInputProps) {
  const {
    className,
    defaultValue,
    disabled,
    emptyText = "Nothing found...",
    immediate = true,
    name,
    nullable = false,
    onChange,
    options = [],
    value,
    placeholder,
    renderOption,
    renderSelectedOption,
    optionClassname,
    allowCustomValue = false,
  } = props
  const defaultOption = options.find(([v]) => defaultValue === v)
  const isControlled = value !== undefined
  const [query, setQuery] = React.useState<string | null>(null)

  useEffect(() => {
    if (isControlled && value == null) {
      setQuery(null)
    }
  }, [value])

  let customValueOptions: ComboboxOption[] = []
  if (allowCustomValue) {
    if (query && query.length > 0) {
      customValueOptions.push([query, query])
    }
    if (isControlled && value && !options.find(([v]) => v === value) && query != value) {
      customValueOptions.push([value, value.toString()])
    }
  }
  const allOptions: ComboboxOption[] = [...customValueOptions, ...options]

  let filteredOptions =
    query === ""
      ? allOptions
      : allOptions.filter(([, label]) => label.toLowerCase().includes(query?.toString()?.toLowerCase() ?? ""))

  // Treat an empty string value as a null value
  if (nullable) filteredOptions = [["", ""], ...filteredOptions]

  const [selectedValue, setSelectedValue] = React.useState<ComboboxOption | undefined>(() => {
    if (isControlled) {
      return allOptions.find(([v]) => v === value) || [null, ""]
    }
    return defaultOption || [null, ""]
  })

  const fieldContext = useFieldContext()
  const realSelectedValue = isControlled ? allOptions.find(([v]) => v === value) || [null, ""] : selectedValue

  const handleChange = useCallback(
    (value: ComboboxOption) => {
      if (!isControlled) setSelectedValue(value)
      if (!compareComboboxOptions(realSelectedValue, value)) {
        // Notify ancestor Field that change has occured
        fieldContext?.descendentChange?.()
        onChange?.(value)
      }
    },
    [onChange, realSelectedValue, setSelectedValue, fieldContext, isControlled]
  )

  const handleQueryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      if (value == null || value === "") handleChange([null, ""])

      setQuery(e.target.value)
    },
    [setQuery, handleChange]
  )

  // Check if a selection has been made and it's not the placeholder non-selection that can appear on initial draw
  const hasValidSelection = realSelectedValue && realSelectedValue[0] !== null && realSelectedValue[1] !== ""

  return (
    <Combobox
      immediate={immediate}
      value={realSelectedValue}
      onChange={handleChange}
      by={compareComboboxOptions}
      disabled={disabled}
    >
      {isNil(name) ? null : <input type="hidden" name={name} value={realSelectedValue?.[0] ?? ""} />}
      <div className={twMerge("relative", className, disabled && "opacity-50")}>
        {props.label ? <Label>{props.label}</Label> : null}

        <div
          className={twMerge(
            "relative",
            "w-full",
            "cursor-default",
            "overflow-hidden",
            "rounded",
            "border",
            "border-gray-300",
            "bg-white",
            "text-left",
            "transition-[border-color_box-shadow]",
            "focus-within:ring-1",
            "focus-within:ring-blue-600",
            "focus-within:border-blue-600",
            "data-[errored]:border-red-500",
            "data-[errored]:focus-within:ring-red-500",
            "flex items-center"
          )}
          {...dataFlag(fieldContext?.hasErrors, "errored")}
        >
          {hasValidSelection && renderSelectedOption && renderSelectedOption(realSelectedValue)}
          <HUIComboboxInput
            className="w-full border-none py-2 pl-3 pr-10 text-gray-900 focus:ring-0"
            displayValue={(selected) => selected?.[1] ?? ""}
            onChange={handleQueryChange}
            placeholder={placeholder}
          />

          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
        </div>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery(null)}
        >
          <ComboboxOptions className="absolute z-50 mt-1 max-h-60 w-full divide-y overflow-auto rounded bg-white py-1 ring-1 ring-black ring-opacity-5 drop-shadow-sm focus:outline-none sm:text-sm">
            {filteredOptions.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none px-4 text-gray-500">{emptyText}</div>
            ) : (
              filteredOptions.map((option) => {
                const [value, label] = option

                return (
                  <HUIComboboxOption
                    key={value}
                    className={({ focus, selected }) =>
                      twMerge(
                        "relative cursor-pointer select-none px-4 py-2",
                        optionClassname,
                        focus && "bg-blue-600 text-white",
                        selected && !focus && "bg-blue-100 font-medium"
                      )
                    }
                    value={option}
                  >
                    {renderOption ? (
                      renderOption(option)
                    ) : (
                      <span className={twMerge("block truncate")}>{label || <>&nbsp;</>}</span>
                    )}
                  </HUIComboboxOption>
                )
              })
            )}
          </ComboboxOptions>
        </Transition>
      </div>
    </Combobox>
  )
}
