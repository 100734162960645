import React, { Fragment, useMemo } from "react"

import cuid from "cuid"
import { useFieldArray, UseFormReturn } from "react-hook-form"
import { humanize } from "underscore.string"

import { ShippingCostEstimatorFormState } from "../ShippingCostEstimator"
import styles from "./PickDestinations.module.scss"
import { Button, Chaff, Input, Select } from "~/src/components"
import { Country } from "~/src/lib/country"

export type PickDestinationsProps = {
  hookForm: UseFormReturn<ShippingCostEstimatorFormState>
  supportedCountryCodes: string[]
}

const zoningOptions = ["residential", "commercial"].map((z) => ({ value: z, label: humanize(z) }))

export function PickDestinations(props: PickDestinationsProps) {
  const {
    hookForm: { control, register, trigger, formState },
    supportedCountryCodes,
  } = props
  const { fields = [], append, remove } = useFieldArray({ control, name: "destinations", keyName: "key" })

  const handleAddNewDestination = async () => {
    // Always add a country that hasn't been used by default
    // const allCodes = new Set(Country.allPriorityFirst().map((c) => c.alpha2))
    // getValues("destinations").forEach((d) => d.alpha2 && allCodes.delete(d.alpha2))
    // const alpha2 = allCodes?.values()?.next()?.value

    append({ key: cuid(), alpha2: "US", zoning: "residential", quantity: 1 })
    setTimeout(() => trigger("destinations"))
  }

  const handRemoveDestination = (index: number) => () => {
    remove(index)
    trigger("destinations")
  }

  const countryOptions = useMemo(
    () => Country.whereAlpha2(supportedCountryCodes).map((c) => ({ value: c.alpha2, label: c.name }), []),
    []
  )

  return (
    <div>
      <hgroup>
        <h2>Where are you shipping to?</h2>
      </hgroup>

      <div className={styles.whereShippingToTable}>
        <div>
          <h3>
            Destination Coun
            <Chaff />
            try
          </h3>
        </div>
        <div>
          <h3>Residential or Commercial?</h3>
        </div>
        <div>
          <h3>Number of Shipments to Location</h3>
        </div>
        <div>
          <Button style={{ visibility: "hidden" }}>Remove</Button>
        </div>

        {fields.map((field, index) => (
          <Fragment key={field.key}>
            <div>
              <Select
                error={formState?.errors?.destinations?.[index]?.alpha2}
                {...register(`destinations.${index}.alpha2`, { onChange: () => trigger("destinations") })}
                options={countryOptions}
              />
            </div>
            <div>
              <Select
                error={formState?.errors?.destinations?.[index]?.zoning}
                {...register(`destinations.${index}.zoning`, { onChange: () => trigger("destinations") })}
                options={zoningOptions}
              />
            </div>
            <div>
              <Input
                error={formState?.errors?.destinations?.[index]?.quantity}
                type="number"
                {...register(`destinations.${index}.quantity`, { valueAsNumber: true })}
              />
            </div>
            <div>
              <Button onClick={handRemoveDestination(index)}>Remove</Button>
            </div>
          </Fragment>
        ))}

        {(fields?.length ?? 0) <= 0 ? (
          <>
            <div className={styles.invalidRow}>You must supply at least one destination!</div>
          </>
        ) : (
          <></>
        )}
      </div>

      <Button onClick={handleAddNewDestination}>{`Add New Destination`}</Button>
    </div>
  )
}
