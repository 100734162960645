import React from "react"

import { HeaderValue } from "./schema"
import { isNil, isntNil } from "~/src/lib/any"
import { Country } from "~/src/lib/country"

const DEFAULT_OPTIONS = {
  address_name: { name: "Name", width: 200 },
  address_company: { name: "Company", width: 200 },
  address_1: { name: "Address Line 1", width: 200 },
  address_2: { name: "Address Line 2", width: 150 },
  address_city: { name: "City", width: 150 },
  address_state: {
    name: "State",
    options({ address_country: alpha2 }) {
      const country = Country.fromAlpha2(isntNil(alpha2) ? alpha2 : "US")

      if (country instanceof Error || isNil(country.states)) return

      return country.states.map((s) => ({ label: s.name, value: s.alpha2 }))
    },
    width: 150,
  },
  address_zip: {
    name: "Postal Code",
    validationHint: (
      <>
        <p>
          <strong>Format Legend:</strong> <em>N</em> is a number, <em>A</em> is a letter, <em>#</em> is a number or
          letter and <em>CC</em> is the country&apos;s 2-letter code. Any other character is literal.
        </p>
        <p>Make sure to double check the country if the zip appears to be correct.</p>
      </>
    ),
  },
  address_country: {
    name: "Country",
    options: Country.allPriorityFirst().map((c) => ({ label: c.name, value: c.alpha2 })),
    width: 150,
  },
  address_phone: { name: "Phone", width: 200 },
  recipient_email: { name: "Recipient Email", width: 150 },
  notification_email: { name: "Notification Email", width: 150 },
  slack: { name: "Slack" },
}

function calculateWidth(str: string, fallback: number = 150) {
  const calculatedWidth = str.length * 8 // magic font width estimation
  if (fallback > calculatedWidth) {
    return fallback
  }

  return calculatedWidth
}

export function withDefaultOptions(rawColumns: HeaderValue["columns"]): HeaderValue["columns"] {
  return rawColumns.map((column) => {
    if (DEFAULT_OPTIONS[column.key]) {
      return { ...column, ...DEFAULT_OPTIONS[column.key] }
    } else {
      return { ...column, width: calculateWidth(column.name) }
    }
  })
}
