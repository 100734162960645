import React, { KeyboardEvent } from "react"

import { FallDown, FallDownProps } from "~/src/components"

import "./Spreadsheet.scss"

// TODO: Proper type checking for Select options/onChange types
interface SelectEditorProps extends FallDownProps {
  options: any
  onChange: any
}

export function SelectEditor(props: SelectEditorProps) {
  const { value, onChange, options, rowHeight, menuPortalTarget } = props

  return (
    <FallDown
      noBorder
      autoFocus
      onKeyDown={(e: KeyboardEvent) => {
        // Don't propagate event if using arrows, because they're used by the
        // spreadsheet to navigate from cell to cell and they're needed to select
        // from an option from the dropdown
        if (/Arrow/.test(e.key)) e.stopPropagation()
      }}
      defaultMenuIsOpen
      value={options?.find((o) => o.value === value)}
      onChange={(o) => onChange(o?.value)}
      options={options}
      menuPortalTarget={menuPortalTarget}
      styles={{
        control: (given) => ({
          ...given,
          height: rowHeight - 1,
          minHeight: 30,
          lineHeight: "normal",
        }),
        dropdownIndicator: (given) => ({
          ...given,
          height: rowHeight - 1,
        }),
      }}
    />
  )
}
