import React, { useCallback, useEffect, useState } from "react"

import { Bee } from "~/src/components/BeeKit"
import { ComboboxInputProps, ComboboxOption } from "~/src/components/BeeKit/ComboboxInput"
import { useSolutionFormContext } from "~/src/components/Solutions/CustomTraySolutionFormProvider"
import { iClient } from "~/src/lib/appClients"
import { PreferenceCollectorsResponse } from "~/src/types/internalApi/PreferenceCollector"

type SelectPreferenceCollectorInputProps = {
  disabled?: boolean
  placeholder?: string
  required?: boolean
  value: number | null | undefined
  onChange: (value: number | null) => void
}

export function SelectPreferenceCollectorInput({
  disabled,
  placeholder,
  required,
  value,
  onChange,
}: SelectPreferenceCollectorInputProps) {
  const { storeId } = useSolutionFormContext()
  const [options, setOptions] = useState<ComboboxOption[]>([])
  const [loading, setLoading] = useState(true)

  const handleChange: ComboboxInputProps["onChange"] = useCallback(
    (newOption: ComboboxOption) => {
      onChange(newOption ? (newOption[0] as number) : null)
    },
    [onChange]
  )

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      if (storeId == null) return

      try {
        const storeIdParam = encodeURIComponent(storeId)
        const {
          data: { preferenceCollectors },
        } = await iClient.get<PreferenceCollectorsResponse>(`/stores/${storeIdParam}/preference_collectors`)
        setOptions(preferenceCollectors.map((pc) => [pc.id, pc.name]))
      } catch (error) {
        console.error(error)
        setOptions([])
      } finally {
        setLoading(false)
      }
    })()
  }, [storeId])

  return (
    <Bee.ComboboxInput
      disabled={disabled}
      emptyText={loading ? "Loading..." : "No preferred gifts found."}
      immediate
      nullable={!required}
      options={options}
      placeholder={placeholder}
      value={value ?? undefined}
      onChange={handleChange}
    />
  )
}
