import React, { useCallback, useMemo, useRef } from "react"

import { unparse } from "papaparse"
import { twMerge } from "tailwind-merge"

import { SidebarCTAButtonProps } from "../FulfillmentFlow/ReviewDropshipList"
import { RailsForm } from "../RailsForm"
import { ErrorData } from "./errorTracker"
import { RowFilterFn } from "./filter"
import { HeaderValue, RowValue } from "./schema"
import style from "./Sidebar.module.scss"
import { ValidationError } from "./validate"
import useConfirmation from "~/src/hooks/useConfirmation"
import { isArray, isntNil } from "~/src/lib/any"
import { permitKeys } from "~/src/lib/object"

type Column = HeaderValue["columns"][number]
interface RichColumn extends Column {
  validationHint?: string | string[]
}

type Row = RowValue

type ErrorsByKind = Record<string, { rowId: number; error: ValidationError }[]>
type ErrorsForColumnProps = {
  column: RichColumn
  errorsByKind: ErrorsByKind
  onApplyFilter: (filter: RowFilterFn, filterData: FilterData) => void
}

function ErrorsForColumn({ column, errorsByKind, onApplyFilter }: ErrorsForColumnProps) {
  const validationHint = column.validationHint
  return (
    <li aria-label={`Filter rowrecipients with "${column.key}"`}>
      <ol>
        <h2 className={style.columnNameTitle}>
          {column.name}&nbsp;
          <span className={twMerge([style.severityHint, style.severityHintRequired])}>required</span>
        </h2>
        {isntNil(validationHint) ? (
          <div className={style.validationHints}>
            {isArray(validationHint) ? validationHint.map((s) => <p key={s}>{s}</p>) : validationHint}
          </div>
        ) : (
          <></>
        )}

        {Object.entries(errorsByKind).map(([errorKind, errors]) => {
          const [{ error: firstError }] = errors
          const rowIds = new Set(errors.map(({ rowId }) => rowId))

          const filterData = {
            errorColumn: column,
            errorKind: errorKind,
            errorMessage: firstError.message,
          }

          return (
            <li key={`col-${column.key}-kind-${errorKind}`} role="button">
              <span
                className={twMerge([style.clickableMessage, style.errorMessage])}
                onClick={() => {
                  onApplyFilter((row) => {
                    return row ? rowIds.has(row.id) : false
                  }, filterData)
                }}
              >
                {firstError.message}
              </span>
              <br />
              <span className={style.rowCountIndicator}>
                {rowIds.size} {rowIds.size === 1 ? "recipient" : "recipients"}
              </span>
            </li>
          )
        })}
      </ol>
    </li>
  )
}

type ErrorsByFamilyProps = {
  columns: RichColumn[]
  errors: ErrorData
  onApplyFilter: (filter: RowFilterFn, filterData: FilterData) => void
}

function ErrorsByFamily({ columns, errors, onApplyFilter }: ErrorsByFamilyProps) {
  const errorsByColumn = useMemo(() => {
    return columns.reduce((errorsByColumn, column) => {
      const errorsByKind = errors.errorsByFamily[column.key]
      const pair: [RichColumn, ErrorsByKind] = [column, errorsByKind]
      return errorsByKind ? [...errorsByColumn, pair] : errorsByColumn
    }, [])
  }, [columns, errors])

  if (errorsByColumn.length == 0) return <></>
  return (
    <ol className={style.errorsByFamilyList}>
      {errorsByColumn.map(([column, errorsByKind]) => {
        return (
          <ErrorsForColumn
            key={`col-${column.key}`}
            column={column}
            errorsByKind={errorsByKind}
            onApplyFilter={onApplyFilter}
          />
        )
      })}
    </ol>
  )
}

export type SidebarCTAButtonComponent = (props: SidebarCTAButtonProps) => JSX.Element

export interface SidebarProps {
  draftName: string
  columns: RichColumn[]
  rows: Row[] // All csv rows regardless of active filters
  filteredRows: Row[] // Filtered rows for displaying error counts etc
  totalRowCount: number
  errors: ErrorData

  onApplyFilter: (filter: RowFilterFn) => void
  onUnapplyFilter: () => void
  submitUrl: string
  submitMethod?: "patch" | "post"
  SidebarCTAButton?: SidebarCTAButtonComponent
  fileInputHTTPName?: string
  requireUserConfirmation?: boolean
  className?: string
}

type SidebarLoadingState = "loading" | "idle"
type FilterData = { errorColumn: RichColumn; errorKind: string; errorMessage: string }

export function Sidebar(props: SidebarProps) {
  const [confirm, ConfirmationDialog] = useConfirmation()
  const {
    draftName,
    columns,
    rows,
    filteredRows,
    totalRowCount,
    errors,
    fileInputHTTPName,
    onApplyFilter: originalOnApplyFilter,
    onUnapplyFilter: originalOnUnapplyFilter,
    className,
    submitUrl,
    submitMethod,
    SidebarCTAButton,
    requireUserConfirmation,
  } = props

  // If submitMethod is not provided, use "post" as the default value.
  const formSubmitMethod = submitMethod ?? "post"
  const fileInputHTTPNameValue = fileInputHTTPName ?? "dropship_list[finalized_draft]"

  const [loadingState, setLoadingState] = React.useState<SidebarLoadingState>("idle")
  const [filterData, setFilterData] = React.useState<FilterData | null>(null)

  const draftRef = useRef<HTMLInputElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const onApplyFilter = (filter: RowFilterFn, filterData: FilterData) => {
    setFilterData(filterData)
    originalOnApplyFilter(filter)
  }

  const onUnapplyFilter = () => {
    setFilterData(null)
    originalOnUnapplyFilter()
  }

  const whenFilteredErrors = useCallback(
    ({ errorColumn: column, errorKind, errorMessage }) => {
      const validationHint = column.validationHint
      const errorsForKind = errors.errorsByFamily?.[column.key]?.[errorKind] ?? []
      return (
        <>
          <ol className={style.errorsByFamilyList}>
            <h2 className={style.columnNameTitle}>
              {column.name}&nbsp;
              <span className={twMerge([style.severityHint, style.severityHintRequired])}>required</span>
            </h2>
            {isntNil(validationHint) ? (
              <div className={style.validationHints}>
                {isArray(validationHint) ? validationHint.map((s) => <p key={s}>{s}</p>) : validationHint}
              </div>
            ) : (
              <></>
            )}
            <li key={`col-${column.key}-kind-${errorKind}`} role="button">
              <span className={style.errorMessage}>{errorMessage}</span>
              <br />
              <span className={style.rowCountIndicator}>
                {errorsForKind.length} {errorsForKind.length === 1 ? "recipient" : "recipients"}
              </span>
            </li>
          </ol>
        </>
      )
    },
    [filterData, errors]
  )

  const whenErrors = () => (
    <>
      <p>Address errors found in {`${errors.count} ${errors.count === 1 ? "row" : "rows"}`} before submitting.</p>
      <ErrorsByFamily columns={columns} errors={errors} onApplyFilter={onApplyFilter}></ErrorsByFamily>
    </>
  )

  const whenEmpty = () => (
    <>
      <p>Fill in drops with the provided columns before submitting.</p>
      <hr />
    </>
  )

  const whenOk = () => (
    <p className={style.victoryMessage}>
      We couldn&apos;t find any errors. Congratulations!
      <br />
      <br />
      <span className={style.victoryEmojis}>✨&nbsp;🥳&nbsp;🎉&nbsp;✨</span>
    </p>
  )

  const handleSubmit = async () => {
    if (requireUserConfirmation) {
      const confirmed = await confirm(
        "Finalize This List?",
        "You will not be able to make additional changes, and your contact at Brilliant will be notified that your list is complete."
      )

      if (!confirmed) {
        return
      }
    }
    setLoadingState("loading")
    const colKeys = columns.map((col) => col.key)
    const permitColumns = (row: Row) => permitKeys(row, colKeys)
    const csvData = unparse(rows.map(permitColumns))
    const csvBlob = new Blob([csvData], { type: "text/csv" })

    // Dynamically set the file contents of the form input to be this blob
    const container = new DataTransfer()
    const file = new File([csvBlob], draftName + ".csv", { type: "text/csv", lastModified: new Date().getTime() })
    container.items.add(file)
    draftRef.current!.files = container.files

    formRef.current!.submit()
  }

  return (
    <div className={twMerge(["dropship-editor-sidebar", className])}>
      <div className="sidebar-box gray">
        <h1>
          {filteredRows.length != totalRowCount ? `${filteredRows.length} of ${totalRowCount}` : filteredRows.length}
          &nbsp;
          {totalRowCount === 1 ? "recipient" : "recipients"}
        </h1>
        {filterData ? (
          <>
            <p>Only showing recipients with:</p>
            <span>{whenFilteredErrors(filterData)}</span>
            <div>
              <a href="#" className={style.clickableMessage} onClick={onUnapplyFilter}>
                Back to All Recipients
              </a>
            </div>
          </>
        ) : (
          <>{errors.count > 0 ? whenErrors() : totalRowCount <= 0 ? whenEmpty() : whenOk()}</>
        )}
      </div>
      <br />
      <div>
        {SidebarCTAButton ? (
          <SidebarCTAButton onClick={handleSubmit} disabled={errors.count > 0} loadingState={loadingState} />
        ) : (
          <button onClick={handleSubmit} className={twMerge(["submit-btn", "center", errors.count > 0 && "disabled"])}>
            {loadingState == "idle" ? "Submit List" : "Submitting..."}
          </button>
        )}
      </div>
      <ConfirmationDialog />
      <RailsForm method={formSubmitMethod} action={submitUrl} encType="multipart/form-data" ref={formRef}>
        <input name={fileInputHTTPNameValue} type="file" ref={draftRef} hidden></input>
        <input type="button" hidden></input>
      </RailsForm>
    </div>
  )
}
