import { isNil } from "./any"
import { Country } from "./country"

const DOMESTIC_LOW_BOUND = 0.02
const DOMESTIC_HIGH_BOUND = 0.07
const INTERNATIONAL_LOW_BOUND = 0.05
const INTERNATIONAL_HIGH_BOUND = 0.1

export type ShippingDestinationRecord = {
  alpha2: string
  zoning: "residential" | "commercial"
  quantity: number
  cost: number
  margin: number
  carrier: string
  service: string
}

export class ShippingDestination {
  constructor(
    public readonly alpha2: string,
    public readonly zoning: "residential" | "commercial",
    public readonly quantity: number,
    public readonly cost: number,
    public readonly margin: number,
    public readonly carrier?: string,
    public readonly service?: string
  ) {}

  static fromRecord(r: Partial<ShippingDestinationRecord>) {
    if (typeof r.alpha2 !== "string") throw new TypeError("alpha2 must be a string")
    if (typeof r.zoning !== "string") throw new TypeError("zoning must be a string")
    if (typeof r.quantity !== "number") throw new TypeError("alpha2 must be number")
    if (typeof r.cost !== "number") throw new TypeError("cost must be a number")
    if (typeof r.margin !== "number") throw new TypeError("margin must be a number")

    return new this(r.alpha2, r.zoning, r.quantity, r.cost, r.margin, r.carrier, r.service)
  }

  countryName() {
    return Country.fromAlpha2(this.alpha2).name
  }

  price() {
    return this.cost * (1 + this.margin)
  }

  priceRange(low?: number, high?: number): number[] {
    if (isNil(low) || isNil(high)) {
      return this.alpha2.toLowerCase() === "us" ? this.priceRangeDomestic() : this.priceRangeInternational()
    } else {
      return [low, high].map((v) => this.cost / (1 - (this.margin + v)))
    }
  }

  priceRangeDomestic() {
    return this.priceRange(DOMESTIC_LOW_BOUND, DOMESTIC_HIGH_BOUND)
  }

  priceRangeInternational() {
    return this.priceRange(INTERNATIONAL_LOW_BOUND, INTERNATIONAL_HIGH_BOUND)
  }

  totalPrice() {
    return this.price() * this.quantity
  }

  totalPriceRange(low?: number, high?: number): number[] {
    if (isNil(low) || isNil(high)) {
      return this.alpha2.toLowerCase() === "us" ? this.totalPriceRangeDomestic() : this.totalPriceRangeInternational()
    } else {
      return this.priceRange(low, high).map((v) => v * this.quantity)
    }
  }

  totalPriceRangeDomestic() {
    return this.totalPriceRange(DOMESTIC_LOW_BOUND, DOMESTIC_HIGH_BOUND)
  }

  totalPriceRangeInternational() {
    return this.totalPriceRange(INTERNATIONAL_LOW_BOUND, INTERNATIONAL_HIGH_BOUND)
  }
}
