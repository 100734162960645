import React from "react"

import { QuickSendKind, QuickSendSelectionKind } from "../../types/preferredGift"
import { QuickSendSummaryGroup } from "~/src/components/QuickSends/QuickSendSummaryGroup"
import { isntNil } from "~/src/lib/any"
import { Format } from "~/src/lib/format"
import type { QuickSendSelectionItem, StoreProduct, Variant } from "~/src/types/productSelection"

export type QuickSendSummaryProps = {
  quickSendKind: QuickSendKind
  chosenSelections: QuickSendSelectionItem[]
  giftedSelections: QuickSendSelectionItem[]
  estimatedTotal?: number
  onSelection?: (storeProduct: StoreProduct, selectionType: QuickSendSelectionKind, selectedVariant?: Variant) => void
  renderStoreProductDetails?: (storeProduct: StoreProduct) => React.ReactNode
}

export function QuickSendSummary(props: QuickSendSummaryProps) {
  const { quickSendKind, chosenSelections, giftedSelections, estimatedTotal, onSelection, renderStoreProductDetails } =
    props

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-6 pb-4">
        <QuickSendSummaryGroup
          quickSendKind={quickSendKind}
          selections={giftedSelections}
          onSelection={onSelection}
          selectionType={QuickSendSelectionKind.Gifted}
          renderStoreProductDetails={renderStoreProductDetails}
        />
        <QuickSendSummaryGroup
          quickSendKind={quickSendKind}
          selections={chosenSelections}
          onSelection={onSelection}
          selectionType={QuickSendSelectionKind.Chosen}
          renderStoreProductDetails={renderStoreProductDetails}
        />
      </div>
      {isntNil(estimatedTotal) ? (
        <>
          <div className="flex flex-row justify-between border-t border-gray-200 pt-4 text-sm font-medium">
            <p>Estimated cost per recipient:</p>
            <p>{formatEstimate(estimatedTotal, [...chosenSelections, ...giftedSelections])}</p>
          </div>
          <div className="text-[10px] italic leading-tight text-gray-500">
            {quickSendKind == QuickSendKind.Surprise
              ? "This is the estimated total based on the items you selected. The final amount deducted from your " +
                "budget will be adjusted to accommodate shipping cost."
              : "This is the estimated total based on the items you selected, which will be held from your Store " +
                "budget until your recipient accepts their gift. The final amount deducted from your budget will " +
                "depend on what gift your recipient selects."}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

function formatEstimate(estimatedTotal: number, selections: QuickSendSelectionItem[]) {
  const [{ selectable }] = selections
  if (isntNil(selectable) && isntNil(selectable.customCurrencyUnit)) {
    return Format.customCurrency(estimatedTotal, selectable.customCurrencyUnit)
  } else {
    return Format.usd(estimatedTotal)
  }
}
