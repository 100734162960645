import { cloneDeep, cloneDeepWith } from "lodash-es"

import { HeaderValue, RowValue } from "./schema"
import { ValidationError } from "./validate"
import { isArray, isNil, isObject, Nil } from "~/src/lib/any"
import { forEachGivenKey } from "~/src/lib/object"
import yup from "~/src/lib/yup-extended"

type RowKey = RowValue["id"]
type ColumnKey = HeaderValue["columns"][number]["key"]
type ErrorKind = string
export type ErrorMap<T> = Record<RowKey, Record<ColumnKey, T>>
export type ErrorMapFamily<T> = Record<ColumnKey, Record<ErrorKind, T>>
export type ErrorData = {
  count: number
  errors: ErrorMap<ValidationError[]>
  errorsByFamily: ErrorMapFamily<{ rowId: number; error: ValidationError }[]>
  display: ErrorMap<string>
}

function cloneDeepKeepErrors(target: unknown) {
  return cloneDeepWith(target, (value) => {
    if (value instanceof yup.ValidationError) {
      return value
    }
  })
}

export class ErrorTracker {
  columnKeys: string[]
  count: ErrorData["count"]
  errors: ErrorData["errors"]
  errorsByFamily: ErrorData["errorsByFamily"] // Error family is column + errorKind
  display: ErrorData["display"]

  constructor(columnKeys: string[], from?: ErrorData) {
    this.columnKeys = columnKeys

    if (isNil(from)) {
      this.count = 0
      this.errors = {}
      this.errorsByFamily = {}
      this.display = {}
    } else {
      this.count = from.count
      this.errors = cloneDeepKeepErrors(from.errors)
      this.errorsByFamily = cloneDeepKeepErrors(from.errorsByFamily)
      this.display = cloneDeep(from.display)
    }
  }

  addErrorRow(rowId: RowKey, rowError: Record<ColumnKey, ValidationError[]> | Nil) {
    if (isNil(rowError)) {
      return
    }

    this.count += 1
    this.errors[rowId] = rowError

    forEachGivenKey(
      (errors: ValidationError[], columnKey: string) => {
        if (isNil(errors)) {
          return
        }

        const [firstDisplay, ...restDisplay] = errors.flatMap((error) => error.errors)
        const displayMessage = restDisplay.length == 0 ? firstDisplay : `${firstDisplay} [+${restDisplay.length} more]`
        const currentDisplayRow = this.display[rowId]
        if (isNil(currentDisplayRow)) {
          this.display[rowId] = { [columnKey]: displayMessage }
        } else {
          currentDisplayRow[columnKey] = displayMessage
        }

        errors.forEach((error) => {
          const errorKind = `${columnKey}#${error.type || "base"}`
          const rowKeyedError = { rowId: rowId, error }

          const errorsForColumn = this.errorsByFamily[columnKey]
          if (isObject(errorsForColumn)) {
            const errorsForKind = errorsForColumn[errorKind]
            if (isArray(errorsForKind)) {
              errorsForKind.push(rowKeyedError)
            } else {
              errorsForColumn[errorKind] = [rowKeyedError]
            }
          } else {
            this.errorsByFamily[columnKey] = { [errorKind]: [rowKeyedError] }
          }
        })
      },
      rowError,
      this.columnKeys
    )
  }

  removeErrorRow(rowId: RowKey) {
    if (isNil(this.errors[rowId])) {
      return
    }

    this.count -= 1
    const { [rowId]: oldRow, ...newErrors } = this.errors
    const { [rowId]: oldDisplay, ...newDisplay } = this.display
    this.errors = newErrors
    this.display = newDisplay

    forEachGivenKey(
      (errors: ValidationError[], columnKey: string) => {
        if (isNil(errors)) {
          return
        }

        errors.forEach((error) => {
          const errorKind = `${columnKey}#${error.type || "base"}`
          const errorsForColumn = this.errorsByFamily[columnKey]
          if (isNil(errorsForColumn)) {
            return
          }
          const errorsForKind = errorsForColumn[errorKind]
          if (!isArray(errorsForKind)) {
            return
          }

          const newErrorsForKind = errorsForKind.filter(({ rowId: existingRowId }) => existingRowId !== rowId)
          if (newErrorsForKind.length === 0) {
            delete errorsForColumn[errorKind]
            if (Object.keys(errorsForColumn).length === 0) {
              delete this.errorsByFamily[columnKey]
            }
          } else {
            errorsForColumn[errorKind] = newErrorsForKind
          }
        })
      },
      oldRow,
      this.columnKeys
    )
  }

  state(): ErrorData {
    return { count: this.count, errors: this.errors, errorsByFamily: this.errorsByFamily, display: this.display }
  }
}
