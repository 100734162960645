import React, { Fragment, PropsWithChildren, useEffect, useMemo, useRef, useState } from "react"

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react"
import { ChevronDownIcon, ChevronUpIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid"
import { ArrowLeftIcon, TrashIcon } from "@heroicons/react/24/outline"
import { PlusIcon } from "@heroicons/react/24/solid"
import { get } from "lodash-es"
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form"
import { useLoaderData } from "react-router-dom"
import { twMerge } from "tailwind-merge"

import { iname, PathBuilder } from "../../lib/iname"
import { Keyed, WithErrorMessages } from "../../types/record"
import { Bee } from "../BeeKit"
import { HookedAddressFields } from "../HookedAddressFields"
import { QuickSendEditBaseErrorsFlash } from "./QuickSendEditBaseErrorsFlash"
import { QuickSendSummary } from "./QuickSendSummary"
import {
  FileInput,
  ProductSelection,
  RailsForm,
  StoreProductWarnings,
  WorkflowContainer,
  WorkflowHeading,
} from "~/src/components"
import { isntNil, isPresent } from "~/src/lib/any"
import { summarizeMergeFields, summarizeSelections } from "~/src/lib/quickSend"
import { OrderRecipient, QuickSend, QuickSendKind } from "~/src/types/preferredGift"
import type { StoreProduct } from "~/src/types/productSelection"

// #region Types

type DetailsFormData = Record<string, string>

enum RecipientInformationUploadType {
  MultipleRecipients = 0,
  UploadCsv = 1,
}

type ManuallyAddTabContentProps = {
  internationalAllowed: boolean
  orderRecipients: Partial<WithErrorMessages<OrderRecipient>>[]
  restrictedCountryCodes: string[]
  selected: boolean
  usingEmail: boolean
  quickSendKind: QuickSendKind
  single?: boolean
}

type RecipientAddressSubFormProps = {
  baseName: PathBuilder
  address: OrderRecipient["address"]
  internationalAllowed?: boolean
  restrictedCountryCodes?: string[]
  selected: boolean
}

type RecipientDetailsSectionProps = {
  defaultTabIndex: RecipientInformationUploadType
  internationalAllowed: boolean
  onTabChange: (index: number) => void
  orderRecipients: Partial<WithErrorMessages<OrderRecipient>>[]
  quickSend: QuickSend
  restrictedCountryCodes: string[]
  senderCompanyName: string
  usingEmail: boolean
}

type MultipleRecipientsTabContentProps = {
  quickSend: QuickSend
  selected: boolean
  hasExistingBulkUpload: boolean
  persistedOrderRecipientCount: number
}

type RecipientSubFormProps = {
  allowDelete?: boolean
  onDelete?: (recipient: RecipientSubFormProps["recipient"]) => void
  selected: boolean
  usingEmail: boolean
  internationalAllowed: boolean
  restrictedCountryCodes: string[]
  recipient: Partial<WithErrorMessages<Keyed<OrderRecipient>>>
  quickSendKind: QuickSendKind
}

export type QuickSendEditDetailsContentProps = {
  quickSend: QuickSend
  senderCompanyName: string
  availableStoreBudget?: number
  customBudgetUnit?: string
}

// #endregion

// #region Constants

const ROUGH_EMAIL_REGEXP = /.+@.+\..+/
const names = iname().quickSend
const recipientAttrs = names.orderRecipientsAttributes

// #endregion

// #region Local Functions

function defaultEmailBody(): string {
  return [
    "Hi {{ recipient_name }},",
    "",
    "{{ company_name }} needs a little info from you so we can send you something.",
    "It'll just take a minute! When you're ready, click the button below.",
    "",
    "Thanks,",
    "{{ sender_name }}",
  ].join("\n")
}

function defaultNotecardMessage(): string {
  return ["Enjoy your gift!", "", "From,", "", "{{sender_name}} at {{company_name}}"].join("\n")
}

// #endregion

// #region Local Components

function BulkUploadCsvErrors({ errors }: { errors: string[] }) {
  const firstErrors = errors.slice(0, 20)
  const remainingErrors = errors.slice(20)
  const [showRemainingErrors, setShowRemainingErrors] = useState(false)

  if (errors.length > 0) {
    return (
      <div className="flex flex-col gap-1 rounded-lg bg-red-50 p-4">
        <h2 className="font-medium text-red-600">We found a few issues with your upload</h2>
        <p className="text-sm text-red-600">
          Please review and correct errors, then upload your file again to continue. If you continue to experience
          issues with this file, reach out to customer service.
        </p>
        <Disclosure>
          {({ open }) => (
            <div className="flex flex-col gap-4">
              <DisclosurePanel className="flex flex-col pt-1">
                <ul className="list-inside list-disc pl-1 text-sm text-gray-900">
                  {firstErrors.map((error, index) => (
                    <li key={`first-${index}`}>{error}</li>
                  ))}
                  {showRemainingErrors ? (
                    remainingErrors.map((error, index) => <li key={`remaining-${index}`}>{error}</li>)
                  ) : remainingErrors.length > 0 ? (
                    <button className="text-blue-600" type="button" onClick={() => setShowRemainingErrors(true)}>
                      Load remaining {remainingErrors.length} errors
                    </button>
                  ) : null}
                </ul>
              </DisclosurePanel>
              <DisclosureButton className="flex items-center gap-1 text-xs text-gray-600">
                {open ? <span>Hide errors</span> : <span>Show errors</span>}
                {open ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
              </DisclosureButton>
            </div>
          )}
        </Disclosure>
      </div>
    )
  }
}

function DetailsSection({ title, description, children }: PropsWithChildren<{ title: string; description: string }>) {
  return (
    <div className="flex flex-col items-center gap-8">
      <br />
      <hr className="w-full" />
      <div className="flex w-full flex-col gap-8 px-8 lg:flex-row">
        <hgroup className="flex basis-1/3 flex-col items-center lg:items-start">
          <h1 className="font-bold">{title}</h1>
          <h2 className="text-sm">{description}</h2>
        </hgroup>
        <div className="flex basis-2/3 flex-col items-center gap-2 lg:items-start">{children}</div>
      </div>
    </div>
  )
}

function InventoryErrorFlash({ quickSend, hasInventory }: { quickSend: QuickSend; hasInventory: boolean }) {
  if (!hasInventory) {
    return (
      <Bee.Flash kind="error" className="mx-6 mt-4">
        <div className="flex flex-col gap-2 text-sm">
          <div className="flex flex-row items-center gap-2 font-medium">
            <ExclamationCircleIcon className="h-4 w-4" />
            <p>Insufficient inventory</p>
          </div>
          <p>
            We&apos;re sorry, one or more products in your send are no longer available. Please{" "}
            <a className="text-blue-600" href={`/quick_sends/${quickSend.id}/selections`}>
              edit your selection(s)
            </a>{" "}
            to resolve this issue and complete your send.
          </p>
        </div>
      </Bee.Flash>
    )
  }
}

function ManuallyAddTabContent(props: ManuallyAddTabContentProps) {
  const {
    selected,
    orderRecipients,
    quickSendKind,
    internationalAllowed,
    restrictedCountryCodes,
    single = false,
    usingEmail,
  } = props

  const { trigger } = useFormContext()
  const lastKey = useRef(orderRecipients.length - 1)
  const [recipients, setRecipients] = useState<Partial<Keyed<OrderRecipient>>[]>(() =>
    orderRecipients.map((x, index) => ({ key: index, ...x }))
  )
  const finalRecipients = single ? recipients.slice(0, 1) : recipients

  const handleDelete = (recipient: RecipientSubFormProps["recipient"]) => {
    setRecipients(recipients.filter((x) => x.key !== recipient.key))
    trigger([names.orderRecipientsAttributes._])
  }

  return (
    <div className="flex flex-col gap-6">
      {finalRecipients.map((recipient) => {
        return (
          <RecipientSubForm
            key={recipient.key}
            allowDelete={recipients.length > 1}
            onDelete={handleDelete}
            selected={selected}
            usingEmail={usingEmail}
            quickSendKind={quickSendKind}
            internationalAllowed={internationalAllowed}
            restrictedCountryCodes={restrictedCountryCodes}
            recipient={recipient}
          />
        )
      })}

      {single ? (
        <></>
      ) : (
        <div className="flex-start flex">
          <button
            type="button"
            onClick={() => {
              lastKey.current += 1
              setRecipients([...recipients, { key: lastKey.current }])
              requestAnimationFrame(() => {
                trigger([names.orderRecipientsAttributes[lastKey.current]._])
              })
            }}
            className="flex items-center gap-1"
          >
            <PlusIcon className="h-6 w-6" /> Add another recipient
          </button>
        </div>
      )}
    </div>
  )
}

function RecipientSubForm(props: RecipientSubFormProps) {
  const {
    allowDelete = false,
    onDelete,
    selected,
    usingEmail,
    recipient,
    quickSendKind,
    internationalAllowed,
    restrictedCountryCodes,
  } = props
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const baseName = recipientAttrs[recipient.key ?? 0]

  return (
    <>
      <fieldset className="flex flex-col rounded-lg border border-gray-200 p-6">
        <section className="flex w-full flex-col lg:flex-row lg:flex-wrap lg:gap-x-4">
          <Bee.Field
            className="grow basis-1/3"
            label="Recipient name"
            errorMessages={get(errors, baseName.name.$dots())?.message as string}
          >
            <Bee.Input
              className="w-full rounded border border-gray-300"
              disabled={!selected}
              {...register(baseName.name._, {
                shouldUnregister: true,
                required: { value: true, message: "required" },
              })}
            />
          </Bee.Field>

          <Bee.Field
            className="grow basis-1/3"
            label="Company"
            optional
            errorMessages={get(errors, baseName.company.$dots())?.message as string}
          >
            <Bee.Input
              className="w-full rounded border border-gray-300"
              disabled={!selected}
              {...register(baseName.company._, { shouldUnregister: true })}
            />
          </Bee.Field>
        </section>

        {usingEmail && (
          <section className="flex w-full flex-col">
            <Bee.Field
              className="w-full"
              label="Email"
              errorMessages={get(errors, baseName.email.$dots())?.message as string}
            >
              <Bee.Input
                className="w-full rounded border border-gray-300"
                disabled={!selected}
                {...register(baseName.email._, {
                  shouldUnregister: true,
                  required: {
                    value: true,
                    message: "required",
                  },
                  pattern: {
                    value: ROUGH_EMAIL_REGEXP,
                    message: "is not a valid format",
                  },
                })}
              />
            </Bee.Field>
          </section>
        )}
        {quickSendKind === QuickSendKind.Surprise ? (
          <RecipientAddressSubForm
            baseName={baseName}
            internationalAllowed={internationalAllowed}
            restrictedCountryCodes={restrictedCountryCodes}
            address={recipient.address}
            selected={selected}
          />
        ) : (
          <></>
        )}

        {allowDelete ? (
          <div className="flex-end flex self-end">
            <button
              type="button"
              onClick={() => {
                onDelete?.(recipient)
              }}
              className="flex items-center gap-1 text-gray-500"
            >
              <TrashIcon className="h-6 w-6" /> Delete
            </button>
          </div>
        ) : (
          <></>
        )}
      </fieldset>
    </>
  )
}

function RecipientAddressSubForm(props: RecipientAddressSubFormProps) {
  const { selected, baseName, internationalAllowed = true, restrictedCountryCodes = [] } = props

  return (
    <HookedAddressFields
      baseName={baseName.addressAttributes}
      disabled={!selected}
      includeCompanyField={false}
      includeNameField={false}
      internationalAllowed={internationalAllowed}
      supportedRegionCodes={restrictedCountryCodes}
    />
  )
}

function RecipientDetailsSection(props: RecipientDetailsSectionProps) {
  const {
    quickSend,
    usingEmail,
    orderRecipients,
    internationalAllowed,
    restrictedCountryCodes,
    onTabChange,
    defaultTabIndex,
  } = props
  const { trigger } = useFormContext()
  const persistedOrderRecipientCount = quickSend.orderRecipients.filter((recipient) => isntNil(recipient.id)).length
  const hasExistingBulkUpload = isPresent(quickSend.bulkUploadCsv) && persistedOrderRecipientCount > 0

  const handleOnChange = (index: number) => {
    requestAnimationFrame(() => {
      trigger([names.bulkUploadCsv._, names.orderRecipientsAttributes._])
    })

    onTabChange?.(index)
  }

  if (usingEmail) {
    return (
      <TabGroup defaultIndex={defaultTabIndex} onChange={handleOnChange}>
        <TabList className="mb-6 flex h-10 w-full items-center text-sm font-medium">
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                type="button"
                className={twMerge(
                  "h-full w-full rounded-l-lg rounded-r-none border",
                  selected ? "border-navy-800 bg-navy-800 text-white" : "border-gray-300 bg-white text-gray-900"
                )}
              >
                Manually Add
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                type="button"
                className={twMerge(
                  "h-full w-full rounded-l-none rounded-r-lg border",
                  selected ? "border-navy-800 bg-navy-800 text-white" : "border-gray-300 bg-white text-gray-900"
                )}
              >
                Multiple Recipients
              </button>
            )}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {({ selected }) => (
              <ManuallyAddTabContent
                orderRecipients={orderRecipients}
                selected={selected}
                quickSendKind={quickSend.kind}
                usingEmail={usingEmail}
                internationalAllowed={true}
                restrictedCountryCodes={[]}
              />
            )}
          </TabPanel>
          <TabPanel>
            {({ selected }) => (
              <MultipleRecipientsTabContent
                quickSend={quickSend}
                selected={selected}
                hasExistingBulkUpload={hasExistingBulkUpload}
                persistedOrderRecipientCount={persistedOrderRecipientCount}
              />
            )}
          </TabPanel>
        </TabPanels>
      </TabGroup>
    )
  } else {
    return (
      <ManuallyAddTabContent
        single
        selected
        quickSendKind={quickSend.kind}
        usingEmail={usingEmail}
        orderRecipients={orderRecipients}
        internationalAllowed={internationalAllowed}
        restrictedCountryCodes={restrictedCountryCodes}
      />
    )
  }
}

function MultipleRecipientsTabContent(props: MultipleRecipientsTabContentProps) {
  const { quickSend, selected, hasExistingBulkUpload, persistedOrderRecipientCount } = props
  const {
    formState: { errors },
    control,
  } = useFormContext()

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <h4 className="font-medium">Upload .csv</h4>
        <div className="text-sm">
          Bulk add your recipients by uploading a completed version of our .csv template. Need the template? You
          can&nbsp;
          <a className="text-blue-600" href={`/quick_sends/${quickSend.id}/csv_template`}>
            download it here
          </a>
          . On the next screen, you can review the recipients you&apos;ve uploaded.
        </div>
        <Disclosure>
          {({ open }) => (
            <div className="flex flex-col gap-4">
              <DisclosureButton className="flex items-center gap-1 text-xs text-gray-600">
                <span>See requirements</span>
                {open ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
              </DisclosureButton>
              <DisclosurePanel className="flex flex-col gap-4 rounded-xl bg-gray-100 p-4">
                <div className="flex flex-col gap-1">
                  <h4 className="text-[10px]/4 font-bold uppercase tracking-wider text-gray-500">Required</h4>
                  <ul className="list-inside list-disc pl-2 text-xs text-gray-900">
                    <li>recipient_name: First & last name of each gift recipient</li>
                    <li>recipient_email: Email address of each gift recipient</li>
                    {quickSend.kind == QuickSendKind.Surprise ? (
                      <>
                        <li>address_1: Street address of each gift recipient</li>
                        <li>address_city: City name of each gift recipient</li>
                        <li>address_state: State of each gift recipient</li>
                        <li>address_zip: Zip Ccde of each gift recipient</li>
                        <li>
                          address_country: Country of each gift recipient. Gifts cannot be sent to the following
                          countries: Brazil, Argentina, India, Israel, Turkey, Taiwan, Saudi Arabia, Indonesia, China,
                          Kenya, or any additional countries that have been restricted on your store.
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
                <div className="flex flex-col gap-1">
                  <h4 className="text-[10px]/4 font-bold uppercase tracking-wider text-gray-500">Optional</h4>
                  <ul className="list-inside list-disc pl-2 text-xs text-gray-900">
                    <li>company_name: Company name of each gift recipient</li>
                    {quickSend.kind == QuickSendKind.Surprise ? (
                      <>
                        <li>address_2: suite, Apartment, unit, etc. of each gift recipient</li>
                        <li>address_phone: Phone number of each gift recipient</li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </DisclosurePanel>
            </div>
          )}
        </Disclosure>
      </div>
      <Controller
        control={control}
        rules={{ required: { value: selected && !hasExistingBulkUpload, message: "required" } }}
        shouldUnregister={true}
        name={names.bulkUploadCsv._}
        render={({ field: { onChange } }) => (
          <div className="flex flex-col gap-4">
            <BulkUploadCsvErrors errors={quickSend.bulkUploadCsvErrors} />
            {hasExistingBulkUpload ? (
              <p className="text-sm font-medium">
                Existing upload: {quickSend.bulkUploadCsv} ({persistedOrderRecipientCount} recipients)
              </p>
            ) : null}

            <Bee.Field errorMessages={get(errors, names.bulkUploadCsv.$dots())?.message as string}>
              <FileInput
                name={names.bulkUploadCsv._}
                disabled={!selected}
                onDropAccepted={(acceptedFiles) => onChange(acceptedFiles[0])}
                onClear={() => onChange("")}
              />
            </Bee.Field>
          </div>
        )}
      />
    </div>
  )
}

function InvitationTypeSection(props: { defaultInvitationType?: OrderRecipient["use"] }) {
  const { defaultInvitationType = "email" } = props
  const { register, setValue } = useFormContext()

  const invitationOptions = [
    {
      value: "email",
      label: "Email",
      description: "We'll send your recipient a link to claim their gift via email",
    },
    {
      value: "link",
      label: "Link",
      description: "We'll generate a unique url that you can share directly with your recipient",
    },
  ]

  return (
    <DetailsSection title="Invitation type" description="How do you want to send the invitation to this gift?">
      <input hidden {...register(names.using._, { required: { value: true, message: "required" } })} />
      <Bee.RadioGroup
        onChange={(value) => setValue(names.using._, value, { shouldValidate: true, shouldDirty: true })}
        defaultValue={defaultInvitationType}
        options={invitationOptions}
      />
    </DetailsSection>
  )
}

// #endregion

// #region Component

export function QuickSendEditDetailsContent() {
  const data = useLoaderData() as QuickSendEditDetailsContentProps

  const { quickSend, senderCompanyName, availableStoreBudget, customBudgetUnit } = data
  const { kind, selections, preferenceCollector } = quickSend
  const { internationalAllowed = true, restrictedCountryCodes } = preferenceCollector

  const defaults = {
    emailBody: quickSend.emailBody || defaultEmailBody(),
    notecardMessage: quickSend.notecardMessage || defaultNotecardMessage(),
    senderName: quickSend.senderName || "",
    senderEmail: quickSend.senderEmail || "",
    using: quickSend.using || "email",
    addressCountry:
      internationalAllowed && restrictedCountryCodes.length > 0 && !restrictedCountryCodes.includes("US")
        ? restrictedCountryCodes[0]
        : "US",
  }

  const orderRecipients =
    quickSend.orderRecipients.length > 0 ? quickSend.orderRecipients : [{ name: "", company: "", email: "" }]

  // Prepares Hook form state default values.
  const defaultValues = useMemo(() => {
    // Set once for all recipients
    const values = {
      [names.emailBody._]: defaults.emailBody,
      [names.notecardMessage._]: defaults.notecardMessage,
      [names.senderName._]: defaults.senderName,
      [names.senderEmail._]: defaults.senderEmail,
      [names.using._]: defaults.using,
    }

    orderRecipients.forEach((recipient, index) => {
      Object.assign(values, {
        [recipientAttrs[index].name._]: recipient.name,
        [recipientAttrs[index].company._]: recipient.company,
        [recipientAttrs[index].email._]: recipient.email,
      })

      if (recipient.address != null) {
        const addressAttrs = recipientAttrs[index].addressAttributes

        Object.assign(values, {
          [addressAttrs.address1._]: recipient.address.address1,
          [addressAttrs.address2._]: recipient.address.address2,
          [addressAttrs.addressCity._]: recipient.address.addressCity,
          [addressAttrs.addressState._]: recipient.address.addressState,
          [addressAttrs.addressZip._]: recipient.address.addressZip,
          [addressAttrs.addressCountry._]: recipient.address.addressCountry,
          [addressAttrs.addressPhone._]: recipient.address.addressPhone,
        })
      }
    })

    return values
  }, [])

  const formContext = useForm<DetailsFormData>({ mode: "onChange", defaultValues })

  const {
    register,
    handleSubmit,
    watch,
    setError,
    trigger,
    formState: { errors },
  } = formContext

  // Enrich Hook form state with server errors.
  useEffect(() => {
    Object.entries(quickSend.errorMessages ?? {}).forEach(([key, messages]) => {
      // If base errors are added and they are not connected to a field, they
      // will not automatically clear on field change. Let's not add them to Hook form.
      if (key === "base") return

      const errorName = names[key].$dots()
      const message = messages.join(", ")

      setError(errorName, { type: "server", message })
    })

    orderRecipients.forEach((recipient, index) => {
      Object.entries(recipient.errorMessages ?? {}).forEach(([key, messages]) => {
        // Nested address errors appear here, but are formatted poorly. Skip them here, and handle them
        // properly below.
        if (key.startsWith("address")) return

        const errorName = recipientAttrs[index][key].$dots()
        const message = messages.join(", ")

        setError(errorName, { type: "server", message })
      })

      Object.entries(recipient?.address?.errorMessages ?? {}).forEach(([key, messages]) => {
        const errorName = recipientAttrs[index].addressAttributes[key].$dots()
        const message = messages.join(", ")

        setError(errorName, { type: "server", message })
      })
    })
  }, [orderRecipients])

  // On the off-chance that the "Sender" fields were empty, make sure user can't proceed without
  // filling them in.
  useEffect(() => {
    trigger([names.senderName._, names.senderEmail._])
  }, [])

  const usingEmail = watch(names.using._) === "email"

  const allHaveInventory = quickSend.selections.every(
    ({ selectable: { inventoryCount, inventoryRequired } }) => !inventoryRequired || inventoryCount > 0
  )
  const { chosenSelections, giftedSelections, estimatedTotal } = summarizeSelections(selections)

  const initialTab =
    quickSend.bulkUploadCsvErrors.length > 0 || isPresent(quickSend.bulkUploadCsv)
      ? RecipientInformationUploadType.UploadCsv
      : RecipientInformationUploadType.MultipleRecipients

  const [recipientInformationTabIndex, setRecipientInformationTabIndex] =
    useState<RecipientInformationUploadType>(initialTab)

  const isValid = Object.keys(errors["quick_send"] ?? {}).every((key) => {
    switch (recipientInformationTabIndex) {
      case RecipientInformationUploadType.MultipleRecipients:
        return key === "bulk_upload_csv"
      case RecipientInformationUploadType.UploadCsv:
        return key === "order_recipients_attributes"
    }
  })

  const onSubmit = async (e: React.BaseSyntheticEvent) => {
    let warningMessage = ""
    const notecardFieldText = e.target.querySelector('[name="quick_send[notecard_message]"]').value
    const [, invalidNotecardMergeFields] = summarizeMergeFields(notecardFieldText)
    let allUnsupportedMergeFields = invalidNotecardMergeFields

    if (kind === QuickSendKind.Standard) {
      const emailFieldText = e.target.querySelector('[name="quick_send[email_body]"]').value
      const [validEmailMergeFields, invalidEmailMergeFields] = summarizeMergeFields(emailFieldText)
      const emailMissingRecipientName = !validEmailMergeFields.includes("recipient_name")

      if (usingEmail && emailMissingRecipientName) {
        warningMessage +=
          "Couldn’t find {{ recipient_name }} in the email body. Are you sure you want to send recipient emails without their name?\n\n"
      }
      allUnsupportedMergeFields = allUnsupportedMergeFields.concat(invalidEmailMergeFields)
    }

    if (allUnsupportedMergeFields.length > 0) {
      warningMessage += `We found the following unsupported fields: ${allUnsupportedMergeFields.map((field) => `{{ ${field} }}`).join(", ")}.\n\n`
    }

    const isConfirmed = warningMessage.length > 0 ? confirm(`${warningMessage}Would you still like to continue?`) : true

    if (!(await trigger())) {
      e.preventDefault()
      return false
    }

    isConfirmed ? handleSubmit(() => null)() : e.preventDefault()
  }

  return (
    <FormProvider {...formContext}>
      <RailsForm
        method="patch"
        className="h-inherit"
        action={`/quick_sends/${quickSend.id}`}
        encType="multipart/form-data"
        onSubmit={onSubmit}
      >
        {/* #region Hidden Fields */}
        <input name="step" type="hidden" defaultValue="details" />
        <Bee.Input type="hidden" name={names.estimatedTotal} value={estimatedTotal} />
        {/* #endregion */}

        <div className="flex h-[calc(100vh-3.5rem)] flex-col gap-8 pb-16 md:pb-6">
          <WorkflowContainer className="grow overflow-y-scroll px-0 sm:px-0 lg:px-0">
            <WorkflowHeading title="Send details" subtitle="Review your send and add recipient information" />

            <InventoryErrorFlash quickSend={quickSend} hasInventory={allHaveInventory} />
            <QuickSendEditBaseErrorsFlash quickSend={quickSend} />

            {/* Products */}
            <DetailsSection title="Products" description="Items that will be included in this send">
              <div className="flex w-full flex-col">
                <QuickSendSummary
                  quickSendKind={kind}
                  chosenSelections={chosenSelections}
                  giftedSelections={giftedSelections}
                  estimatedTotal={estimatedTotal}
                  renderStoreProductDetails={(storeProduct: StoreProduct) => (
                    <StoreProductWarnings quickSendId={quickSend.id} storeProduct={storeProduct} />
                  )}
                />
              </div>
              <ProductSelection.BudgetStatusPill budget={availableStoreBudget} currencyUnit={customBudgetUnit} />
            </DetailsSection>

            {/*Sender*/}
            <DetailsSection title="Sender details" description="Confirm who this gift is being sent from">
              <Bee.Field
                label="Sender name"
                errorMessages={get(errors, names.senderName.$dots())?.message as string}
                className="w-full"
              >
                <Bee.Input
                  className="w-full rounded border border-gray-300"
                  {...register(names.senderName._, { required: { value: true, message: "required" } })}
                />
              </Bee.Field>

              <Bee.Field
                label="Email address"
                errorMessages={get(errors, names.senderEmail.$dots())?.message as string}
                className="w-full"
              >
                <Bee.Input
                  className="w-full rounded border border-gray-300"
                  {...register(names.senderEmail._, {
                    required: { value: true, message: "required" },
                    pattern: { value: ROUGH_EMAIL_REGEXP, message: "invalid format" },
                  })}
                />
              </Bee.Field>
            </DetailsSection>

            {/*Invitation*/}
            {kind === QuickSendKind.Standard ? <InvitationTypeSection defaultInvitationType={defaults.using} /> : <></>}

            {/*Recipient*/}
            <DetailsSection title="Recipient information" description="Tell us who you'd like to send this gift to">
              <section className="flex w-full flex-col gap-6">
                <RecipientDetailsSection
                  quickSend={quickSend}
                  internationalAllowed={internationalAllowed}
                  restrictedCountryCodes={restrictedCountryCodes}
                  orderRecipients={orderRecipients}
                  senderCompanyName={senderCompanyName}
                  usingEmail={usingEmail}
                  defaultTabIndex={recipientInformationTabIndex}
                  onTabChange={setRecipientInformationTabIndex}
                />
              </section>
            </DetailsSection>

            {/*Message*/}
            <DetailsSection title="Message" description="Add a custom message to this shipment">
              <section className="flex flex-col gap-4">
                {quickSend.kind === QuickSendKind.Standard && (
                  <div className={twMerge("flex flex-col gap-1", !usingEmail && "hidden")}>
                    <Bee.Field
                      label="Email message"
                      hint={
                        <p className="mb-1 text-sm text-gray-500">
                          This is the message that all uploaded recipients will see on their invitation email. Please
                          note that <span className="whitespace-nowrap">{"{{ recipient_name }}"}</span> and{" "}
                          <span className="whitespace-nowrap">{"{{ sender_name }}"}</span> will not show up on the
                          invitation email. This will be replaced by the actual recipients names in the csv and the
                          sender name entered on this form.
                        </p>
                      }
                      errorMessages={get(errors, names.emailBody.$dots())?.message as string}
                    >
                      <Bee.TextArea
                        rows={7}
                        disabled={!usingEmail}
                        {...register(names.emailBody._, { required: { value: usingEmail, message: "required" } })}
                      />
                    </Bee.Field>
                  </div>
                )}

                <div className="flex flex-col gap-1">
                  <Bee.Field
                    optional
                    label="Notecard"
                    hint={
                      <p className="mb-1 text-sm text-gray-500">
                        This is the message that will be printed on the physical notecard included in your shipments and
                        will be sent to all recipients. If all text is removed, a notecard will not be included in your
                        shipment. Please note that <span className="whitespace-nowrap">{"{{ sender_name }}"}</span> and{" "}
                        <span className="whitespace-nowrap">{"{{ company_name }}"}</span> will not show up on the
                        notecard. This will be replaced by the sender name that has been entered on this form and the
                        company’s name
                      </p>
                    }
                    errorMessages={get(errors, names.notecardMessage.$dots())?.message as string}
                  >
                    <Bee.TextArea rows={5} {...register(names.notecardMessage._)} />
                  </Bee.Field>
                </div>
              </section>
            </DetailsSection>
          </WorkflowContainer>

          <div className="fixed bottom-0 flex w-full justify-center gap-4 bg-gray-50 px-6 py-4 md:relative md:py-0">
            <Bee.ButtonLink
              className="flex items-center gap-1"
              kind="secondary"
              href={`/quick_sends/${quickSend.id}/selections`}
            >
              <ArrowLeftIcon className="h-4 w-4" />
              <span>Back</span>
            </Bee.ButtonLink>

            <Bee.Button type="submit" className="flex items-center gap-1" disabled={!isValid && allHaveInventory}>
              {usingEmail ? "Review send" : "Send gift!"}
            </Bee.Button>
          </div>
        </div>
      </RailsForm>
    </FormProvider>
  )
}

// #endregion
