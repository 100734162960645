import React, { Fragment, ReactElement } from "react"

import { Description, RadioGroup as HUIRadioGroup, Label, Radio } from "@headlessui/react"
import { CheckCircleIcon } from "@heroicons/react/24/solid"
import { twMerge } from "tailwind-merge"

import { Icon } from "~/src/components"

export type RadioOption = StringRadioOption | NodeRadioOption

export type RadioGroupProps = {
  name?: string
  value?: string
  defaultValue?: string
  options: RadioOption[]
  onChange?: (value: string) => void
  className?: string
}

type StringRadioOption = {
  value: string
  label?: string
  description?: string
}

type NodeRadioOption = {
  value: string
  contents: ReactElement
}

const isStringRadioOption = (option: RadioOption): option is StringRadioOption => {
  return (option as StringRadioOption).label !== undefined || (option as StringRadioOption).description !== undefined
}

function RadioOptionComponent(props: { option: RadioOption }) {
  const { option } = props

  return (
    <Radio key={option.value} value={option.value} as={Fragment}>
      {({ checked }) => (
        <div
          className={twMerge(
            "flex w-full cursor-pointer rounded-xl border p-2",
            checked ? "border-black" : "border-gray-300"
          )}
        >
          <span className="p-4">
            {checked ? (
              <CheckCircleIcon className="h-6 w-6 text-green-700" />
            ) : (
              <Icon.EmptyCircle className="h-6 w-6 text-gray-300" />
            )}
          </span>
          <div className="mr-auto flex flex-col">
            {isStringRadioOption(option) ? (
              <>
                <Label className="font-bold">{option.label}</Label>
                <Description className="text-sm">{option.description}</Description>
              </>
            ) : (
              option.contents
            )}
          </div>
        </div>
      )}
    </Radio>
  )
}

/**
 * Radio Group inputs. The `name` prop is optional, and if provided, will render a hidden input with the
 * selected value.
 *
 * @param props
 * @param props.name The name of the hidden input.
 * @param props.options The radio buttons to display. Should be an array of objects containing a value and optionally a label and description
 * @returns
 * @example
 * const flavors: RadioOptions[] = [
 *   {value: "1", label: "Vanilla", description: "A standard flavor"},
 *   {value: "2", label: "Chocolate", description: "A rich flavor"},
 *   {value: "3", label: "Strawberry", description: "A sweet flavor"},
 * ]
 * <RadioGroup name="flavor_id" options={flavors} />
 */
export function RadioGroup(props: RadioGroupProps) {
  const { name, value, defaultValue, className, onChange, options = [] } = props
  return (
    <HUIRadioGroup
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      name={name}
      className={twMerge("flex w-full flex-col gap-2", className)}
    >
      {options.map((option) => (
        <RadioOptionComponent key={option.value} option={option} />
      ))}
    </HUIRadioGroup>
  )
}
