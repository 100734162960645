import React from "react"

import { Bee } from "./BeeKit"
import { mergeURLSearch } from "~/src/lib/url"
import { Pagy } from "~/src/serializedRecords"

export type PagyNavProps = { className?: string; next?: number; previous?: number; series?: Pagy["series"] }

/**
 * A Pagy `pagy_nav` inspired component whose props are based of a serialized Pagy object.
 * @see `app/representers/pagy_representer.rb`
 */
export function PagyNav(props: PagyNavProps) {
  const { className, previous, next, series = [] } = props

  const links = series.map(({ page, current }, index) => {
    switch (page) {
      case "gap":
        return <Bee.PageGap key={index} />
      default: {
        return (
          <Bee.PageLink current={current} key={index} to={mergeURLSearch({ page })}>
            {page}
          </Bee.PageLink>
        )
      }
    }
  })

  return (
    <Bee.PaginationGroup className={className}>
      <Bee.PageLink
        previous
        disabled={previous == null}
        {...(previous ? { to: mergeURLSearch({ page: previous }) } : { to: "" })}
      />
      {links}
      <Bee.PageLink next disabled={next == null} {...(next ? { to: mergeURLSearch({ page: next }) } : { to: "" })} />
    </Bee.PaginationGroup>
  )
}
