import React from "react"

import { ExclamationCircleIcon } from "@heroicons/react/24/solid"

import { Bee } from "./BeeKit"
import { LowInventoryNofitier } from "./ProductSelection/LowInventoryNotifier"
import { formatSupportedRegions, ON_DEMAND_SHIPPED_SEPARATELY_WARNING } from "~/src/lib/shippingRestrictions"
import type { StoreProduct } from "~/src/types/productSelection"

export type StoreProductWarningsProps = {
  quickSendId: number
  storeProduct: StoreProduct
}

export function StoreProductWarnings(props: StoreProductWarningsProps) {
  const { quickSendId, storeProduct } = props
  const { inventoryCount, inventoryRequired, supportedRegions, onDemand } = storeProduct

  return (
    <div className="flex flex-col gap-2">
      {inventoryRequired ? (
        <LowInventoryNofitier
          lowInventoryThreshold={3}
          inventoryCount={inventoryCount}
          editSelectionUrl={`/quick_sends/${quickSendId}/selections`}
        />
      ) : null}
      {supportedRegions.length > 0 ? (
        <>
          {onDemand && (
            <Bee.Cue
              className="italic text-gray-500"
              tipProps={{ className: "not-italic text-black" }}
              tip={ON_DEMAND_SHIPPED_SEPARATELY_WARNING}
            >
              Ships separately
            </Bee.Cue>
          )}

          <div className="flex items-center gap-1">
            <ExclamationCircleIcon className="h-4 w-4 text-yellow-500" />
            <p className="text-nowrap text-xs">Can only be shipped to {formatSupportedRegions(supportedRegions)}</p>
          </div>
        </>
      ) : null}
    </div>
  )
}
