import { WithErrorMessages } from "./record"
import type { Address } from "~/src/types/address"
import type { QuickSendSelectionItem, StoreProduct } from "~/src/types/productSelection"

type Errors = {
  errors: { [key: string]: string }
}

export enum RecipientUsing {
  Email = "email",
  Link = "link",
}

export type QuickSendSelection = {
  id?: number
  kind: QuickSendSelectionKind
  storeProduct?: StoreProduct
}

export enum QuickSendSelectionKind {
  /** Used to denote a deletion. `None` is never persisted. */
  None = "none",
  Chosen = "added_as_option",
  Gifted = "included_gift",
}

export type QuickSend = WithErrorMessages<{
  id: number
  senderName?: string
  senderEmail?: string
  estimatedTotal?: number
  using: RecipientUsing
  kind: QuickSendKind
  emailBody: string
  notecardMessage: string
  bulkUploadCsv: string
  preferenceCollector: PreferenceCollector
  orderRecipients: WithErrorMessages<OrderRecipient>[]
  selections: QuickSendSelectionItem[]
  baseErrors: string[]
  bulkUploadCsvErrors: string[]
  errors: { [key: string]: string }
}>

export enum QuickSendKind {
  Standard = "standard",
  Surprise = "surprise",
}

export type PreferenceCollector = {
  id: number
  restrictedCountryCodes: string[]
  internationalAllowed?: boolean
}

export type OrderRecipient = {
  id: number
  name: string
  company: string
  email: string
  senderName: string
  senderEmail: string
  use: RecipientUsing
  recipientUrl?: string
  address?: WithErrorMessages<Address>
}

export type Location = {
  id: number
  name: string
  hidden: boolean
  preferenceCollectorId: number
  addressId?: number
}

export type Collectionable = {
  id: number
  name: string
  photoLargeUrl: string
  photoMediumUrl: string
  photoThumbUrl: string
  photoXlargeUrl: string
}

export type CollectionItem = {
  id: number
  collectionableType: "StoreProduct" | "StoreKit"
  collectionableId: number
  collectionable: Collectionable
}
