import React from "react"

import { GiftIcon, Square3Stack3DIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

import { QuickSendSummaryStoreProduct } from "./QuickSendSummaryStoreProduct"
import { QuickSendKind, QuickSendSelectionKind } from "~/src/types/preferredGift"
import {
  SelectableType,
  type QuickSendSelectionItem,
  type StoreProduct,
  type Variant,
} from "~/src/types/productSelection"

export type SummaryGroupProps = {
  quickSendKind: QuickSendKind
  selections: QuickSendSelectionItem[]
  selectionType: QuickSendSelectionKind
  onSelection?: (storeProduct: StoreProduct, selectionType: QuickSendSelectionKind, selectedVariant?: Variant) => void
  renderStoreProductDetails?: (storeProduct: StoreProduct) => React.ReactNode
  className?: string
}

const sectionHeader = (sectionType: QuickSendSelectionKind) => {
  if (sectionType == QuickSendSelectionKind.Gifted) {
    return (
      <h1 className="flex items-center gap-1 rounded-full bg-gray-100 px-2 py-1 text-xs text-gray-500">
        <GiftIcon className="h-4 w-4 text-gray-400" />
        Included Gift
      </h1>
    )
  } else {
    return (
      <h1 className="flex items-center gap-1 rounded-full bg-gray-100 px-2 py-1 text-xs text-gray-500">
        <Square3Stack3DIcon className="h-4 w-4 text-gray-400" />
        Gift options
      </h1>
    )
  }
}

export function QuickSendSummaryGroup(props: SummaryGroupProps) {
  const { quickSendKind, selections, selectionType, onSelection, className, renderStoreProductDetails } = props

  // Will need to handle StoreKit, but only handle StoreProduct for now
  const storeProductSelections: (QuickSendSelectionItem & { selectable: StoreProduct })[] = selections.filter(
    (selection): selection is QuickSendSelectionItem & { selectable: StoreProduct } => {
      const { selectable } = selection
      return selectable.type === SelectableType.StoreProduct
    }
  )

  if (storeProductSelections.length) {
    return (
      <div className={twMerge("flex flex-col gap-4", className)}>
        {quickSendKind === QuickSendKind.Standard ? sectionHeader(selectionType) : null}
        {storeProductSelections.map(({ selectable: storeProduct, selectedVariant }) => {
          return (
            <QuickSendSummaryStoreProduct
              key={`selected-${storeProduct.id}`}
              storeProduct={storeProduct}
              variant={selectedVariant}
              onSelection={onSelection}
            >
              {renderStoreProductDetails ? renderStoreProductDetails(storeProduct) : <></>}
            </QuickSendSummaryStoreProduct>
          )
        })}
      </div>
    )
  }
}
