import { Fragment, default as React, useEffect, useState } from "react"

import { UseFormReturn, useWatch } from "react-hook-form"
import { humanize, titleize } from "underscore.string"

import { Bee } from "../../BeeKit"
import { Destination, useShippingCostEstimatorStore } from "../hooks"
import { ShippingCostEstimatorFormState } from "../ShippingCostEstimator"
import style from "./CalculateEstimate.module.scss"
import { Elliptick, MoneyRange } from "~/src/components"
import { isNil, tuple } from "~/src/lib/any"
import { appClient } from "~/src/lib/appClients"
import { Country } from "~/src/lib/country"
import { Format } from "~/src/lib/format"
import { ShippingDestination } from "~/src/lib/shippingDestination"

export type CalculateEstimateProps = {
  customOrderId: number
  isCurrent: boolean
  hookForm: UseFormReturn<ShippingCostEstimatorFormState>
}

function sumTotalPriceRanges(destinations: Destination[], margin: number): number[] {
  return destinations
    .map((d) => ShippingDestination.fromRecord({ ...d, cost: d.cost ?? 0, margin }))
    .map((sd) => sd.totalPriceRange())
    .reduce(([sumLow, sumHigh], [low, high]) => [sumLow + low, sumHigh + high], [0, 0])
}

export function CalculateEstimate(props: CalculateEstimateProps) {
  const {
    hookForm: { control, getValues },
    isCurrent,
    customOrderId,
  } = props

  const baseMarginPercentage = useWatch({ control, name: "baseMarginPercentage" })
  const [isLoadingEstimate, setIsLoadingEstimate] = useState(true)
  const [hasTimedOut, setHasTimedOut] = useState(false)

  const [destinations, setDestinations, updateDestinationByAlpha2AndZoning, setEstimateId] =
    useShippingCostEstimatorStore((s) =>
      tuple(s.destinations, s.setDestinations, s.updateDestinationByAlpha2AndZoning, s.setEstimateId)
    )

  useEffect(() => {
    if (isCurrent) {
      ;(async () => {
        setIsLoadingEstimate(true)
        setHasTimedOut(false)
        setDestinations(getValues("destinations") ?? [])

        try {
          const {
            data: { destinations = [], id },
          } = await appClient.post<{ destinations: any[]; id: number }>(
            `/api/i/custom_orders/${customOrderId}/estimate`,
            {
              estimate: getValues(),
            }
          )

          if (typeof id === "number") {
            setEstimateId(id)
          }

          destinations?.forEach((destination) => {
            const { countryCode, zoning, selectedRate } = destination

            console.group(`Selected rate for ${countryCode} – ${zoning}`)
            console.log(selectedRate)
            console.groupEnd()

            if (isNil(selectedRate)) {
              Bee.toast.error({
                message: `Couldn't find a shipping rate for ${Country.fromAlpha2(countryCode).name}!`,
                duration: 30_000,
              })
            }

            if (typeof selectedRate?.rate === "number") {
              updateDestinationByAlpha2AndZoning(countryCode, zoning, {
                cost: selectedRate?.rate,
                carrier: selectedRate?.carrier,
                service: selectedRate?.service,
              })
            }
          })
        } catch (e) {
          if (e?.response?.status === 503) {
            Bee.toast.error({
              message: `It took too long to fetch all rates! Please try again with fewer destinations.`,
              duration: 30_000,
            })
            setHasTimedOut(true)
          } else {
            throw e
          }
        } finally {
          setIsLoadingEstimate(false)
        }
      })()
    }
  }, [isCurrent])

  return (
    <div>
      <hgroup>
        <h2>Where are you shipping to?</h2>
      </hgroup>

      {isCurrent && (
        <>
          <div className={style.estimatesTable}>
            <div>
              <h3>Destination Country</h3>
            </div>
            <div>
              <h3>Service</h3>
            </div>
            <div>
              <h3>Estimated Cost</h3>
            </div>
            <div>
              <h3>Estimated Shipping Range per Box</h3>
            </div>
            <div>
              <h3>Quantity</h3>
            </div>
            <div>
              <h3>Estimated Total Cost to Location</h3>
            </div>

            {destinations.map((destination, index) => {
              const shippingDestination = ShippingDestination.fromRecord({
                ...destination,
                cost: destination.cost ?? 0,
                margin: (baseMarginPercentage ?? 0) / 100,
              })

              if ((!isLoadingEstimate && isNil(destination.cost)) || hasTimedOut) {
                return (
                  <Fragment key={index}>
                    <div className={style.error}>
                      {shippingDestination.countryName()} – <em>{humanize(shippingDestination.zoning)}</em>
                    </div>
                    <div className={style.error}>–</div>
                    <div className={style.error}>–</div>
                    <div className={style.error}>–</div>
                    <div className={style.error}>–</div>
                  </Fragment>
                )
              }

              return (
                <Fragment key={index}>
                  <div>
                    {shippingDestination.countryName()} – <em>{humanize(shippingDestination.zoning)}</em>
                  </div>
                  <div>
                    {isLoadingEstimate ? (
                      <Elliptick />
                    ) : (
                      [
                        shippingDestination.carrier,
                        shippingDestination.service && titleize(humanize(shippingDestination.service)),
                      ]
                        .filter(Boolean)
                        .join(" / ")
                    )}
                  </div>
                  <div>
                    <span>{isLoadingEstimate ? <Elliptick /> : Format.usd(shippingDestination.cost)}</span>
                  </div>
                  <div>
                    {isLoadingEstimate ? <Elliptick /> : <MoneyRange amounts={shippingDestination.priceRange()} />}
                  </div>
                  <div>{shippingDestination.quantity}</div>
                  <div>
                    {isLoadingEstimate ? <Elliptick /> : <MoneyRange amounts={shippingDestination.totalPriceRange()} />}
                  </div>
                </Fragment>
              )
            })}

            <div style={{ gridColumn: "span 5" }} />
            <div>
              <h3>Estimated Total Shipping Cost to Client</h3>
            </div>

            <div style={{ gridColumn: "span 5" }} />
            <div>
              {isLoadingEstimate ? (
                <Elliptick />
              ) : hasTimedOut ? (
                <span className={style.error}>–</span>
              ) : (
                <MoneyRange amounts={sumTotalPriceRanges(destinations, (baseMarginPercentage ?? 0) / 100)} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
