import React, { forwardRef } from "react"

import { twMerge } from "tailwind-merge"

import { useFieldContext } from "./Field"
import { PathBuilder } from "~/src/lib/iname"
import { dataFlag } from "~/src/lib/jsx"

export type TextAreaProps = { name?: PathBuilder | string } & Omit<JSX.IntrinsicElements["textarea"], "name">

/**
 * A Beekit skin of the native textarea element that forwards its ref.
 */
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function Textarea(props, ref) {
  const { name, className, onChange, ...restProps } = props

  const fieldContext = useFieldContext()

  return (
    <textarea
      name={name?.toString()}
      onChange={(event) => {
        fieldContext?.descendentChange?.()
        onChange?.(event)
      }}
      className={twMerge(
        "border",
        "border-gray-300",
        "rounded",
        "data-[errored]:border-red-500",
        "data-[errored]:focus:ring-red-500",
        className
      )}
      ref={ref}
      {...dataFlag(fieldContext?.hasErrors, "errored")}
      {...restProps}
    />
  )
})
