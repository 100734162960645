import React from "react"

import { Bee } from "~/src/components/BeeKit"
import { isntNil } from "~/src/lib/any"
import { Format } from "~/src/lib/format"
import { Style } from "~/src/lib/style"
import { BudgetAmount } from "~/src/types/budget"

export type BudgetStatusPillProps = {
  budget?: BudgetAmount
  currencyUnit?: string
  estimatedCharges?: number
}

export function BudgetStatusPill(props: BudgetStatusPillProps) {
  const { budget, estimatedCharges = 0, currencyUnit } = props
  if (isntNil(budget)) {
    return (
      <Bee.StatusPill
        indicatorClassName={indicator(budget, estimatedCharges)}
        className="border border-gray-300 bg-white"
      >
        Available store budget:{" "}
        {isntNil(currencyUnit) ? Format.customCurrency(budget, currencyUnit) : Format.usd(budget)}
      </Bee.StatusPill>
    )
  }
}

function indicator(budget: number, estimatedCharges: number) {
  if (budget - estimatedCharges >= 0) {
    return Style.Color.Success
  } else {
    return Style.Color.Failure
  }
}
