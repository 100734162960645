import React, { useEffect } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import cuid from "cuid"
import { update } from "lodash-es"
import { Resolver, useForm } from "react-hook-form"

import { CalculateEstimate, PickDestinations, PickShippingSpeed, PickVariants } from "./components"
import { Destination, useShippingCostEstimatorStore } from "./hooks"
import style from "./ShippingCostEstimator.module.scss"
import { shippingCostEstimatorFormStateSchema } from "./validationSchemas/shippingCostEstimatorFormStateSchema"
import { Button } from "~/src/components"
import { Bee } from "~/src/components/BeeKit"
import { WizardCrumbs2 } from "~/src/components/WizardCrumbs2"
import { createWizard } from "~/src/hooks"
import { isNil, isntNil, tuple } from "~/src/lib/any"
import { appClient } from "~/src/lib/appClients"
import * as SerializedRecord from "~/src/serializedRecords"

type ShippingCostEstimatorStep = {
  id: string
  name: string
}

const steps: ShippingCostEstimatorStep[] = [
  {
    id: "pick-variants",
    name: "Pick Variants",
  },
  {
    id: "pick-destinations",
    name: "Pick Destinations",
  },
  {
    id: "pick-shipping-speed",
    name: "Pick Shipping Speed",
  },
  {
    id: "calculate-estimate",
    name: "Calculate Estimate",
  },
]

const { useWizard, WizardStep } = createWizard(steps, { hideMethod: "hide" })

export type PickVariantsFormState = {
  customPackagingWeight?: number
  packageDimension?: SerializedRecord.PackageDimension
  shippingKit: "corrugated_box" | "padded_mailer" | "no_additional_packaging" | "custom_packaging"
  variants: {
    key: string
    quantity: number
    id: number
    productId?: number
    productName?: string
    unitWeightOz?: number
  }[]
}

export type PickDestinationsFormState = {
  destinations: Omit<Destination, "cost">[]
  // newDestination?: Omit<Destination, "cost">
}

export type PickShippingSpeedFormState = {
  allowedCarriers: "exclude_economy" | "no_restrictions"
  baseMarginPercentage: number
  byDays?: number
  timeWindow: "most_economical_rate" | "by_certain_date"
}

export type ShippingCostEstimatorFormState = PickVariantsFormState &
  PickDestinationsFormState &
  PickShippingSpeedFormState

const formDefaultValues: ShippingCostEstimatorFormState = {
  baseMarginPercentage: 50,
  byDays: 5,
  allowedCarriers: "no_restrictions",
  customPackagingWeight: 0,
  destinations: [{ key: cuid(), alpha2: "US", zoning: "residential", quantity: 1 }],
  packageDimension: { weight: 0, length: 0, width: 0, height: 0 },
  shippingKit: "corrugated_box",
  variants: [],
  timeWindow: "most_economical_rate",
}

const validationResolver: Resolver<ShippingCostEstimatorFormState, object> = async (values, context, options) => {
  const validationResult = await yupResolver(shippingCostEstimatorFormStateSchema)(values, context, options)

  // Scoped uniqueness of destination.*.alpha2 and destination.*.zoning
  const { destinations = [] } = values

  const composeKey = (v: { alpha2?: string; zoning?: string }) => [v.alpha2, v.zoning].join("_")
  const composedIndex: Map<string, number[]> = new Map()

  destinations?.forEach((v, i) => composedIndex.set(composeKey(v), (composedIndex.get(composeKey(v)) ?? []).concat(i)))

  for (const [, indexes] of composedIndex.entries()) {
    if (indexes.length > 1) {
      indexes.forEach((index) => {
        update(validationResult, `errors.destinations[${index}].zoning`, () => ({
          message: `destinations[${index}].zoning must be unique per country`,
          type: "scoped uniqueness",
        }))
        update(validationResult, `errors.destinations[${index}].alpha2`, () => ({
          message: `destinations[${index}].alpha2 must be unique per zoning (e.g. residential or commercial)`,
          type: "scoped uniqueness",
        }))
      })
    }
  }

  return validationResult
}

export interface ShippingCostEstimatorProps {
  customOrder: SerializedRecord.CustomOrder
  orderItems: SerializedRecord.OrderItem[]
  packageDimensions: SerializedRecord.PackageDimension[]
  supportedCountryCodes: string[]
  redirectPanel: string
}

export function ShippingCostEstimator(props: ShippingCostEstimatorProps) {
  const {
    customOrder,
    orderItems: initialOrderItems,
    packageDimensions: [initialPackageDimension] = [],
    supportedCountryCodes,
    redirectPanel,
  } = props

  const initialVariants: PickVariantsFormState["variants"] = initialOrderItems
    .filter((oi) => isntNil(oi.variant) && !oi.product.categories?.some((c) => c.name === "Services"))
    .map((oi) => ({
      key: cuid(),
      id: oi.variant.id,
      quantity: 1,
      productId: oi.product.id,
      unitWeightOz: oi.product.unitWeightOz,
      productName: oi.product.name,
    }))

  const [estimateId] = useShippingCostEstimatorStore((s) => tuple(s.estimateId))
  const hookForm = useForm<ShippingCostEstimatorFormState>({
    mode: "onChange",
    defaultValues: {
      ...formDefaultValues,
      variants: initialVariants,
      packageDimension: { ...initialPackageDimension },
    },
    resolver: validationResolver,
  })
  const { currentStep, isLastStep, steps, next, goTo } = useWizard()

  const {
    trigger,
    formState: { errors },
  } = hookForm
  const hasErrors = Object.keys(errors).length > 0

  const handleContinue = async () => {
    // Validate fields present in current Wizard page
    let isValid = true
    switch (currentStep?.id) {
      case "pick-variants": {
        isValid = await trigger("variants")
        break
      }
      case "pick-destinations": {
        isValid = await trigger("destinations")
        break
      }
      case "pick-shipping-speed": {
        isValid = await trigger(["baseMarginPercentage", "byDays", "allowedCarriers", "timeWindow"])
        break
      }
    }

    if (!isValid) {
      Bee.toast.error({ message: "Please resolve all errors before continuing" })
      return
    }

    if (!isLastStep) return next()

    if (isNil(estimateId)) return alert("Please try again once the estimate has finished loading...")

    // Attach estimate to custom order then navigate to it
    const { data } = await appClient.patch<{ id: number }>(`/api/i/estimates/${estimateId}/attach`)
    if (data.id) window.location.href = `/admin/custom_orders/${customOrder.id}#${redirectPanel}`
  }

  useEffect(() => {
    trigger("variants")
  }, [])

  return (
    <div className={style.base}>
      <Bee.Toaster position="top-right" />
      <div className={style.wizardNavigation}>
        <WizardCrumbs2
          onClick={(step) => {
            if (step.index <= (currentStep?.index ?? 0)) goTo(step.id)
          }}
          currentStep={currentStep}
          steps={steps}
        />
        <Button error={hasErrors} onClick={handleContinue}>
          {isLastStep ? `Apply Estimates to Order` : `Continue`}
        </Button>
      </div>

      <div className={style.wizardPages}>
        <WizardStep id="pick-variants">
          <PickVariants hookForm={hookForm} />
        </WizardStep>

        <WizardStep id="pick-destinations">
          <PickDestinations hookForm={hookForm} supportedCountryCodes={supportedCountryCodes} />
        </WizardStep>

        <WizardStep id="pick-shipping-speed">
          <PickShippingSpeed hookForm={hookForm} />
        </WizardStep>

        <WizardStep id="calculate-estimate">
          {({ isCurrent }) => (
            <CalculateEstimate isCurrent={isCurrent} customOrderId={customOrder.id} hookForm={hookForm} />
          )}
        </WizardStep>
      </div>
    </div>
  )
}
