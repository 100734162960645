import React, { forwardRef } from "react"

import { twMerge } from "tailwind-merge"

import { Pill } from "./Pill"

export type StatusPillProps = {
  indicatorClassName?: string
} & JSX.IntrinsicElements["div"]

/**
 * A pure styled component implementing the BeeKit pill.
 * @params props indicatorClassName + All div props
 */

export const StatusPill = forwardRef<HTMLDivElement, StatusPillProps>(function StatusPill(props, ref) {
  const { className, indicatorClassName, children } = props

  return (
    <Pill className={className} ref={ref}>
      <div className={twMerge("h-2 w-2 rounded-full", indicatorClassName)} />
      {children}
    </Pill>
  )
})
