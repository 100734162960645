import React, { FocusEvent, forwardRef, HTMLProps } from "react"

import { twMerge } from "tailwind-merge"

export type SearchInputProps = {
  labelProps?: HTMLProps<HTMLLabelElement>
} & HTMLProps<HTMLInputElement>

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(
  props: SearchInputProps,
  ref
) {
  const { className, labelProps = {}, ...restProps } = props

  return (
    <label className={twMerge("relative block", labelProps.className)}>
      <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      </span>
      <input
        type="text"
        placeholder="Search items"
        className={twMerge("block w-full rounded-lg border border-gray-300 pl-10 placeholder:text-gray-600", className)}
        autoFocus
        onFocus={(e: FocusEvent & { target: HTMLInputElement }) => e.target.select()}
        ref={ref}
        {...restProps}
      />
    </label>
  )
})
