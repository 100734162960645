import React from "react"

import { createRoot, Root } from "react-dom/client"

import { isNil, presence } from "~/src/lib/any"
import { camelCaseKeys } from "~/src/lib/object"
import { getComponent } from "~/src/lib/reactComponentRegistry"

/**
 * Renders a React component named after value passed to the `data-name` attribute.
 * Component must have been registered with `registerComponents`. Props are gathered
 * from a script tag with an `id` matching `data-props-id`.
 *
 * This custom element is meant to be used with the `react_component` application helper, and
 * not by itself.
 */
export class ReactComponent extends HTMLElement {
  reactRoot?: { root: Root; mounted: boolean }

  constructor() {
    super()
  }

  connectedCallback() {
    this.reactRoot = { root: createRoot(this), mounted: true }
    this.renderComponent()
  }

  disconnectedCallback() {
    if (this.reactRoot) {
      this.reactRoot.mounted = false
      this.reactRoot.root.unmount()
    }
  }

  loadProps(): Record<string, any> {
    const propsScriptNode = this.propsScriptNode()
    if (propsScriptNode) {
      const propsText = presence(propsScriptNode.innerText)

      if (isNil(propsText)) return {}

      const camelCaseProps = this.getAttribute("camel-case-props")?.toLowerCase() !== "false"
      return camelCaseProps ? camelCaseKeys(JSON.parse(propsText)) : JSON.parse(propsText)
    }

    return {}
  }

  setProps(props: any) {
    const propsText = JSON.stringify(props)
    const propsScriptNode = this.propsScriptNode()
    if (propsScriptNode) {
      propsScriptNode.text = propsText
    }
  }

  renderComponent() {
    if (!this.reactRoot || !this.reactRoot.mounted) return

    const { name } = this.dataset
    if (typeof name !== "string") return

    const props = this.loadProps()
    const Component = getComponent(name)
    this.reactRoot.root.render(<Component {...props} />)
  }

  propsScriptNode(): HTMLScriptElement | undefined {
    const { propsId } = this.dataset
    if (propsId) {
      const propsScriptNode = this.parentNode?.querySelector(`#${propsId}`) ?? document.getElementById(propsId)

      if (propsScriptNode instanceof HTMLScriptElement) {
        return propsScriptNode
      }
    }
  }
}
