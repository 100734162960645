import React, { CSSProperties, memo, ReactElement, useMemo } from "react"

import { useMeasure } from "react-use"

import { EmptyResults } from "~/src/components/EmptyResults"

//region Types
export type ItemGridProps<Item> = {
  items: Item[]
  renderItem: (item: Item, index: number) => ReactElement
}
//endregion

//region Component
export const ItemGrid = memo(function ItemGrid<Item>(props: ItemGridProps<Item>) {
  const { items, renderItem } = props
  // `baseWidth` is used as the "attempted" width of each column in the grid.
  const baseWidth = 230
  const minColLength = 2
  const itemCount = items.length

  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const maxColLength = Math.min(5, Math.floor(width / baseWidth))

  const colLength = useMemo(() => Math.max(minColLength, maxColLength), [maxColLength])

  return (
    <>
      {itemCount === 0 ? (
        <div className="mx-auto flex min-h-64 justify-center">
          <EmptyResults />
        </div>
      ) : (
        <div
          className="grid w-full grid-cols-[repeat(var(--col-length),1fr)]"
          style={{ "--col-length": colLength } as CSSProperties}
          ref={ref}
        >
          {items.map(renderItem)}
        </div>
      )}
    </>
  )
})
//endregion
