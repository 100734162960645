import React from "react"

import { twMerge } from "tailwind-merge"

export type MainHeadingProps = {
  className?: string
  title: string
  actions?: React.ReactNode
  description?: string
}

export function MainHeading(props: MainHeadingProps) {
  const { className, actions, title, description } = props

  return (
    <hgroup className={twMerge("mx-6 my-8 flex flex-col gap-2", "sm:my-8 sm:items-start sm:gap-0", className)}>
      <div className={twMerge("flex w-full flex-col gap-3 sm:flex-row sm:items-center sm:gap-2")}>
        <h1 className="font-souvenir text-4xl font-medium text-gray-900">{title}</h1>
        {actions && <div className="sm:ml-auto sm:flex">{actions}</div>}
      </div>
      {description && <p className="text-lg text-gray-500">{description}</p>}
    </hgroup>
  )
}
