import { dasherize } from "underscore.string"

export function getVar(name: string): string {
  return `var(--${dasherize(name)})`
}

enum Color {
  Success = "bg-green-500",
  Failure = "bg-red-600",
}

export const Style = { Color }
