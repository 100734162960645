import React, { forwardRef } from "react"

import { twMerge } from "tailwind-merge"

import { FieldError } from "."
import style from "./Select.module.scss"

export interface SelectError {
  message?: string
}

export type SelectOption = {
  value: string
  label: string
}

export type SelectProps = { error?: SelectError | boolean; options: SelectOption[] } & JSX.IntrinsicElements["select"]

export const Select = forwardRef<HTMLSelectElement, SelectProps>(function Select(props, ref) {
  const { className, options, error, ...restProps } = props

  let errorMessage: string | undefined

  if (typeof error != "boolean") {
    errorMessage = error?.message
  }

  return (
    <div className={twMerge([style.base, error && style.hasError])}>
      <select className={twMerge([className])} ref={ref} {...restProps}>
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      {errorMessage && <FieldError className={style.fieldError} message={errorMessage} />}
    </div>
  )
})
