import React, { ReactNode, useCallback, useEffect } from "react"

import { clamp } from "lodash-es"
import { create } from "zustand"

import { isFn, isntNil, tuple } from "~/src/lib/any"

export type WizardStore = {
  currentIndex: number
  length: number
  next: () => void
  back: () => void
  goToIndex: (step: number) => void
  setLength: (length: number) => void
}

export type CreateWizardOptions = {
  hideMethod: "unmount" | "hide"
}

export interface WizardStep {
  id: string
  name?: string
}

export interface WizardIndex {
  index: number
}

export interface WizardStepProps {
  id: string
  className?: string
  children: ((opts: { isCurrent: boolean }) => ReactNode) | ReactNode
}

const useStore = create<WizardStore>((set) => ({
  currentIndex: 0,
  length: 0,
  next() {
    set((s) => ({ currentIndex: clamp(s.currentIndex + 1, 0, s.length - 1) }))
  },
  back() {
    set((s) => ({ currentIndex: clamp(s.currentIndex - 1, 0, s.length - 1) }))
  },
  goToIndex(index) {
    set((s) => ({ currentIndex: clamp(index, 0, s.length - 1) }))
  },
  setLength(length) {
    set(() => ({ length }))
  },
}))

const selector = {
  currentIndex(s: WizardStore) {
    return s.currentIndex
  },
  controls(s: WizardStore) {
    return tuple(s.next, s.back, s.goToIndex, s.setLength)
  },
}

export function createWizard<S extends WizardStep>(steps: S[], createOpts?: CreateWizardOptions) {
  function useWizard() {
    const currentIndex = useStore(selector.currentIndex)
    const [next, back, goToIndex, setLength] = useStore(selector.controls)

    useEffect(() => setLength(steps.length), [setLength, steps])
    const goTo = useCallback((stepId: string) => goToIndex(steps.findIndex((ms) => ms.id === stepId)), [])

    const currentStep: (S & WizardIndex) | undefined = isntNil(steps[currentIndex])
      ? { ...steps[currentIndex], index: currentIndex }
      : undefined

    const nextStep: (S & WizardIndex) | undefined = isntNil(steps[currentIndex + 1])
      ? { ...steps[currentIndex], index: currentIndex }
      : undefined

    const firstStep = steps[0]

    const lastStep = steps[steps.length - 1]

    return {
      currentStep,
      nextStep,
      firstStep,
      lastStep,
      isFirstStep: currentStep?.index === 0,
      isLastStep: currentStep?.index === steps.length - 1,
      steps,
      next,
      back,
      goTo,
    }
  }

  function WizardStep(props: WizardStepProps) {
    const { className, id, children: rawChildren } = props
    const { currentStep } = useWizard()

    const isHidden = currentStep?.id !== id
    const children = isFn(rawChildren) ? rawChildren({ isCurrent: !isHidden }) : rawChildren

    if (createOpts?.hideMethod === "hide") {
      return (
        <div className={className} style={{ visibility: isHidden ? "hidden" : "visible" }} hidden={isHidden}>
          {children}
        </div>
      )
    } else {
      return isHidden ? <div className={className}>{children}</div> : <></>
    }
  }

  return { useWizard, WizardStep }
}
