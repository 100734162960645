import React from "react"

/**
 * Interferes with DOM based text search
 * Ask author about this one, or read https://brilliantmade.slack.com/archives/C01PPR8LRFE/p1630016365039700
 * @param props
 * @returns
 */
export function Chaff() {
  return <span style={{ position: "absolute", left: "-99vw" }}>____</span>
}
