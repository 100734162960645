import React, { ReactChild, useEffect, useRef, useState } from "react"

import { createPortal } from "react-dom"
import { twMerge } from "tailwind-merge"

import cellTipStyle from "./CellTip.module.scss"

export interface CellTipProps {
  children: ReactChild
  content: string
  show?: boolean
}

export function CellTip(props: CellTipProps) {
  type PortalNode = HTMLElement | null
  const CELL_TIP_PORTAL_ID = "cell-tip-portal"
  const { children, content, ...restProps } = props
  const [portalNode, setPortalNode] = useState<PortalNode>(null)
  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let portalNode: PortalNode = document.getElementById(CELL_TIP_PORTAL_ID)

    if (portalNode === null) {
      portalNode = document.body.appendChild(document.createElement("div"))
      portalNode.id = CELL_TIP_PORTAL_ID
    }

    setPortalNode(portalNode)

    return () => {
      setPortalNode(null)
      if (portalNode !== null && portalNode.children.length <= 0) portalNode.remove()
    }
  }, [])

  const { x, y } = rootRef?.current?.getBoundingClientRect() ?? { x: 0, y: 0 }
  const [scrollX, scrollY] = [window.scrollX, window.scrollY]

  return (
    <div ref={rootRef} {...restProps}>
      {portalNode
        ? createPortal(
            <div
              className={twMerge(["cell-tip", cellTipStyle.base])}
              style={{
                top: "0px",
                left: "0px",
                transform: `translate3d(${x + scrollX}px, ${y + scrollY}px, 0)`,
              }}
            >
              <div className={cellTipStyle.content}>{content}</div>
            </div>,
            portalNode
          )
        : null}
      {children}
    </div>
  )
}
