import React from "react"

import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

import { Bee } from "../BeeKit"

export type FilterToggleProps = {
  className?: string
  mobileClassName?: string
  open?: boolean
  numSelectedFilters: number
  onClearAll: () => void
  onToggleFilter: () => void
}

function FilterToggleDesktop(props: FilterToggleProps) {
  const { open, className, numSelectedFilters, onClearAll, onToggleFilter } = props

  return (
    <div className={twMerge("hidden flex-row items-center gap-2 sm:flex", className)}>
      <div className="flex flex-row items-center hover:cursor-pointer" onClick={onToggleFilter}>
        <AdjustmentsHorizontalIcon className="mr-1 h-4 w-4 text-gray-800" />
        <button className="text-sm text-gray-900">
          Filter {numSelectedFilters > 0 ? `(${numSelectedFilters})` : ""}
        </button>
      </div>
      {open && (
        <button className="text-xs text-gray-500 hover:cursor-pointer" onClick={onClearAll}>
          Clear all
        </button>
      )}
    </div>
  )
}

function FilterToggleMobile(props: FilterToggleProps) {
  const { mobileClassName, numSelectedFilters, onToggleFilter } = props

  return (
    <Bee.Button
      className={twMerge("flex items-center justify-center rounded-r-none text-sm sm:hidden", mobileClassName)}
      onClick={onToggleFilter}
    >
      Filter {numSelectedFilters > 0 ? `(${numSelectedFilters})` : ""}
    </Bee.Button>
  )
}

export function FilterToggle(props: FilterToggleProps) {
  const { open = true, numSelectedFilters = 0 } = props

  const propsWithDefaults = {
    open,
    numSelectedFilters,
  }

  return (
    <>
      <FilterToggleDesktop {...props} {...propsWithDefaults} />
      {!open && <FilterToggleMobile {...props} {...propsWithDefaults} />}
    </>
  )
}
