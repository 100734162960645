import { snakeCase } from "lodash-es"

const SYM_PATH = Symbol("path")
const SYM_PROXY = Symbol("proxy")
const SYM_RENDER = Symbol("render")
const SYM_SEGMENT_MAPPER = Symbol("segmentMapper")
const SYM_SEGMENT_JOINER = Symbol("segmentJoiner")

export enum WalkMode {
  inputName = "inputName",
  dotChain = "dotChain",
}

type SegmentMapper = (x: string) => string

enum Pos {
  Before,
  First,
  Middle,
  Last,
  After,
}

class JoinerPos {
  #positions: Set<Pos>

  constructor(positions: Set<Pos>) {
    this.#positions = positions
  }

  isBefore() {
    return this.#positions.has(Pos.Before)
  }

  isFirst() {
    return this.#positions.has(Pos.First)
  }

  isMiddle() {
    return this.#positions.has(Pos.Middle)
  }

  isLast() {
    return this.#positions.has(Pos.Last)
  }

  isAfter() {
    return this.#positions.has(Pos.After)
  }
}

type SegmentJoiner = (options: { pos: JoinerPos; index: number; segment?: string }) => string

/**
 * Converts a string to snake_case, preserving leading underscores because Rails uses them to indicate special
 * parameters than can be used to trigger behaviours (.e.g. `_destroy` to delete the record).
 * @param x
 * @returns a string in snake_case
 */
export const snakeCaseMapper: SegmentMapper = (x) => {
  if (x.at(0) === "_") return "_" + snakeCase(x)

  return snakeCase(x)
}

export const identityMapper: SegmentMapper = (x) => x

export const parameterJoiner: SegmentJoiner = ({ pos }) => {
  switch (true) {
    case pos.isBefore():
      return ``
    case pos.isFirst() && !pos.isAfter():
      return `[`
    case pos.isAfter() && !pos.isFirst():
      return `]`
    case pos.isMiddle():
      return `][`
    default:
      return ``
  }
}

export const dotJoiner: SegmentJoiner = ({ pos }) => {
  switch (true) {
    case pos.isMiddle():
      return `.`
    default:
      return ``
  }
}

export class PathBuilder {
  [SYM_SEGMENT_MAPPER]: SegmentMapper;
  [SYM_SEGMENT_JOINER]: SegmentJoiner;
  [SYM_PATH]: string[] = [];

  // This type definition is necessary to support arbitrary property access with the Proxy in Typescript.
  // Unfortunately, this causes the definitions of `toString`, `valueOf`, and `_` properties to fail type checking.
  [x: string | number]: PathBuilder

  constructor(
    path: string[] = [],
    segmentMapper: SegmentMapper = snakeCaseMapper,
    segmentJoiner: SegmentJoiner = parameterJoiner
  ) {
    this[SYM_PATH] = path
    this[SYM_SEGMENT_MAPPER] = segmentMapper
    this[SYM_SEGMENT_JOINER] = segmentJoiner
  }

  /**
   * Creates a Trail from Rails-like input name.
   * @param inputName a string in the format of Rails-like params-compatible input field name
   */
  static fromInputName(inputName: string): PathBuilder {
    let trimmed = inputName.trim()

    if (trimmed.at(-1) === "]") trimmed = trimmed.slice(0, -1)

    const path = trimmed.split(/\[|\]\[/)

    return new this(path)
  }

  /**
   * A shorthand to resolve the path into its string representation.
   */
  // @ts-expect-error To allow for the Proxy to work with any property
  get _(): string {
    return this[SYM_RENDER]()
  }

  // @ts-expect-error To allow for the Proxy to work with any property
  $walk(mode: WalkMode): string {
    switch (mode) {
      case WalkMode.dotChain:
        return this[SYM_RENDER](dotJoiner, snakeCaseMapper)
      default:
        return this[SYM_RENDER]()
    }
  }

  /**
   * @returns path elements as a dot chain.
   * @example
   *  iname().orderRecipients[0].id.$dots() // "order_recipients.0.id"
   */
  // @ts-expect-error To allow for the Proxy to work with any property
  $dots(): string {
    return this.$walk(WalkMode.dotChain)
  }

  /**
   * Defines the default conversion of the object to a primitive value
   * @returns {string}
   * @example
   * `${iname().orderRecipients[0].id}` // "order_recipients[0][id]"
   * `${iname().users.name}` // "users[name]"
   * { [iname().users.firstName]: 1 } // { "users[first_name]": 1 } not supported by TypeScript
   */
  [Symbol.toPrimitive](): string {
    return this[SYM_RENDER]()
  }

  // @ts-expect-error To allow for the Proxy to work with any property
  toString(): string {
    return this[SYM_RENDER]()
  }

  // @ts-expect-error To allow for the Proxy to work with any property
  valueOf(): string {
    return this[SYM_RENDER]()
  }

  /**
   * Returns `this` wrapped in a Proxy to support chaining of arbitrary properties.
   */
  get [SYM_PROXY]() {
    return new Proxy(this, {
      get(target, prop) {
        if (prop in target || typeof prop === "symbol") {
          return Reflect.get(target, prop)
        } else {
          return new PathBuilder(target[SYM_PATH].concat(prop))[SYM_PROXY]
        }
      },
      apply(target) {
        return target[SYM_RENDER]()
      },
    })
  }

  /**
   * Supports array destructuring
   * @returns {IterableIterator<string>}
   * @example
   *  const [one, two, { name }] = iname().mailRecipients
   *
   *  one._ // "mail_recipients[0]"
   *  two.name._ // "mail_recipients[1][name]"
   *  name._ // "mail_recipients[2][name]"
   *
   *  const [...rest] = iname().users
   *  // throws a RangeError, because `...` eagerly evaluates a potentially infinite generator
   */
  *[Symbol.iterator](): IterableIterator<PathBuilder> {
    for (let index = 0; index < 1024; index += 1) {
      yield new PathBuilder(this[SYM_PATH].concat(index.toString()))[SYM_PROXY]
    }

    throw new RangeError("Maximum generation limit reached")
  }

  [SYM_RENDER](segmentJoiner = this[SYM_SEGMENT_JOINER], segmentMapper = this[SYM_SEGMENT_MAPPER]): string {
    const segments = this[SYM_PATH]
    const size = segments.length
    let path: string = ""

    for (let index = 0; index <= size; index += 1) {
      const segment = segments[index]
      const restIndex = size - index
      const poss: Set<Pos> = new Set()

      if (index === 0) {
        poss.add(Pos.Before)
      } else if (index === 1) {
        poss.add(Pos.First)
      }

      if (restIndex === 0) {
        poss.add(Pos.After)
      } else if (restIndex === 1) {
        poss.add(Pos.Last)
      }

      if (!poss.has(Pos.Before) && !poss.has(Pos.After)) {
        poss.add(Pos.Middle)
      }

      path += segmentJoiner({ pos: new JoinerPos(poss), index, segment })

      if (segment != null) path += segmentMapper(segment)
    }

    return path
  }
}

/**
 * Creates a magic builder object to create Rails-like params-compatible input field names.
 * The name, "iname", is based on the combination of "input name".
 *
 * @param {string} inputName a string in the format of Rails-like params-compatible input field name
 * @returns {PathBuilder}
 * @example
 *  iname().orderRecipients.toString() // "order_recipients"
 *  `${iname().users.addressAttributes.street}` // "users[address_attributes][street]"
 *  { [iname().orderRecipients[0].id]: 1 } // { "order_recipients[0][id]": 1 } not supported by TypeScript
 *  { [iname().orderRecipients[0].id._]: 1 } // { "order_recipients[0][id]": 1 }
 */
export function iname(inputName?: string): PathBuilder {
  if (typeof inputName === "string") {
    return PathBuilder.fromInputName(inputName)[SYM_PROXY]
  } else {
    return new PathBuilder()[SYM_PROXY]
  }
}
