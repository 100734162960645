import React from "react"

import { CubeIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

import { Bee } from "./BeeKit"
import { Pill } from "./BeeKit/Pill"
import { ImageSet } from "~/src/components/ImageSet"
import { ShippingRestrictionsCue } from "~/src/components/ShippingRestrictionsCue"
import { StockIndicator } from "~/src/components/StockIndicator"
import { Format } from "~/src/lib/format"
import { ON_DEMAND_SHIPPED_SEPARATELY_WARNING } from "~/src/lib/shippingRestrictions"
import { Orientation } from "~/src/types/orientation"
import { Photo } from "~/src/types/photo"
import { SelectableType } from "~/src/types/productSelection"

export type ProductTileProps = {
  title: string
  type: SelectableType
  onDemand: boolean
  orientation?: Orientation
  price: number
  customCurrencyUnit?: string
  inventoryCount: number
  prepaid: boolean
  inventoryRequired?: boolean
  className?: string
  subtitle?: string
  photo: Photo
  supportedRegions?: string[]
  children?: React.ReactNode
}

function ImageWithFallbackLandscape(props: { photo: Photo; className?: string }) {
  const { photo, className } = props
  return (
    <ImageSet
      srcs={[photo.largeUrl, photo.xlargeUrl]}
      className={twMerge("flex h-28 w-28 overflow-hidden rounded-lg", className)}
    />
  )
}

function ImageWithFallbackPortrait(props: { photo: Photo; className?: string }) {
  const { photo, className } = props
  return (
    <>
      <ImageSet
        srcs={[photo.largeUrl, photo.xlargeUrl]}
        className={twMerge("hidden h-auto w-auto justify-center overflow-hidden md:flex", className)}
      />

      <ImageWithFallbackLandscape photo={photo} className={twMerge(className, "md:hidden")} />
    </>
  )
}

function displayCurrency({
  amount,
  prepaid,
  customCurrencyUnit,
}: {
  amount: number
  prepaid?: boolean
  customCurrencyUnit?: string
}): string {
  if (prepaid) return "Prepaid"

  return customCurrencyUnit ? Format.customCurrency(amount, customCurrencyUnit) : Format.usd(amount)
}

/**
 * Product tile in portrait view on desktop. Landscape view on mobile.
 * See ProductTile for details..
 *
 */
function ProductTilePortrait(props: ProductTileProps) {
  const {
    title,
    price,
    customCurrencyUnit,
    type,
    onDemand,
    inventoryCount,
    children,
    className,
    photo,
    subtitle = null,
    prepaid = false,
    supportedRegions = [],
    inventoryRequired = true,
  } = props

  return (
    <>
      <div
        className={twMerge(
          "hidden w-[210px] flex-col gap-2 overflow-hidden rounded-lg border bg-white pb-4 md:flex",
          className
        )}
      >
        <ImageWithFallbackPortrait photo={photo} />
        <hgroup className="px-4 pt-2">
          <h1 className="font-medium text-gray-900">{title}</h1>
          {subtitle && <h2 className="text-sm italic text-gray-500">{subtitle}</h2>}
        </hgroup>

        {supportedRegions?.length ? <ShippingRestrictionsCue className="px-4" regions={supportedRegions} /> : null}
        {onDemand && (
          <Bee.Cue
            className="px-4"
            icon={<CubeIcon className="h-4 w-4 text-gray-400" />}
            tip={ON_DEMAND_SHIPPED_SEPARATELY_WARNING}
          >
            Ships separately
          </Bee.Cue>
        )}

        <div className="mt-auto flex justify-between px-4 py-2 text-sm">
          {displayCurrency({ amount: price, prepaid: prepaid, customCurrencyUnit: customCurrencyUnit })}
          <StockIndicator quantity={inventoryCount} required={inventoryRequired} type={type} />
        </div>

        <div className="flex w-full gap-8 px-4 md:w-auto">
          <div className="flex w-full flex-col">{children}</div>
        </div>
      </div>

      <ProductTileLandscape {...props} className={twMerge("md:hidden", className)} />
    </>
  )
}

/**
 * Product tile in landscape view on desktop and mobile.
 * See ProductTile for details..
 *
 */
function ProductTileLandscape(props: ProductTileProps) {
  const {
    title,
    price,
    customCurrencyUnit,
    type,
    onDemand,
    inventoryCount,
    children,
    className,
    photo,
    subtitle = null,
    prepaid = false,
    supportedRegions = [],
    inventoryRequired = true,
  } = props

  return (
    <div
      className={twMerge(
        "flex w-full flex-col justify-between gap-4 rounded-lg border bg-white p-4 md:flex-row md:items-center",
        className
      )}
    >
      <div className="flex gap-3 md:items-center">
        <ImageWithFallbackLandscape photo={photo} />
        <div className="flex flex-col gap-2">
          <hgroup>
            <h1 className="font-medium leading-6">{title}</h1>
            {subtitle && <h2 className="text-sm italic text-gray-500">{subtitle}</h2>}
          </hgroup>

          {supportedRegions?.length ? <ShippingRestrictionsCue regions={supportedRegions} /> : null}
          {onDemand && <Bee.Cue tip={ON_DEMAND_SHIPPED_SEPARATELY_WARNING}>Ships separately</Bee.Cue>}

          <span className="text-sm">
            {displayCurrency({ amount: price, prepaid: prepaid, customCurrencyUnit: customCurrencyUnit })}
          </span>

          <Pill className="flex items-center gap-2 md:hidden">
            <StockIndicator quantity={inventoryCount} required={inventoryRequired} type={type} />
          </Pill>
        </div>
      </div>

      <div className="flex w-full gap-8 md:w-auto">
        <StockIndicator className="hidden md:flex" quantity={inventoryCount} required={inventoryRequired} type={type} />
        <div className="flex w-full flex-col md:w-auto">{children}</div>
      </div>
    </div>
  )
}

/**
 * Product tile which displays a Product image as well as relevant details.
 * Accepts children for possible actions on the right end of the tile.
 */
export function ProductTile(props: ProductTileProps) {
  const { orientation = Orientation.Portrait, ...restProps } = props

  if (orientation == Orientation.Portrait) {
    return <ProductTilePortrait {...restProps} />
  } else {
    return <ProductTileLandscape {...restProps} />
  }
}
