import React, { forwardRef } from "react"

import { twMerge } from "tailwind-merge"

export type PillProps = JSX.IntrinsicElements["div"]

/**
 * A pure styled component implementing the BeeKit pill.
 * @params props All div props
 */
export const Pill = forwardRef<HTMLDivElement, PillProps>(function Pill(props, ref) {
  const { className, ...restProps } = props
  return (
    <div
      className={twMerge(
        "flex w-[fit-content] items-center justify-end gap-2 whitespace-nowrap rounded-full bg-gray-100 px-4 py-1 text-sm",
        className
      )}
      ref={ref}
      {...restProps}
    />
  )
})
